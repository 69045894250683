import { FormError } from '../../_types/globals'
import { DatabaseUser } from '../../_types/user'
import { commonRegex } from '../../_utilities/config'

/**
 * Validate the details of a user
 *
 * @param {DatabaseUser} user The user data to validate
 * @returns {FormError[]} The errors found
 *
 * @example
 * ```ts
 * const errors = validate(user)
 * ```
 */
const validate = (user: DatabaseUser): FormError[] => {
  const errors: FormError[] = []

  if (!user.displayName || user.displayName === '') {
    errors.push({
      message: 'Display Name cannot be blank!',
      elementId: 'displayName',
    })
  }

  if (!user.email || user.email === '') {
    errors.push({
      message: 'Email cannot be blank!',
      elementId: 'email',
    })
  } else if (!user.email.match(commonRegex.email)) {
    errors.push({
      message: 'Email is not formatted correctly!',
      elementId: 'email',
    })
  }

  return errors
}

export { validate }
