import React, { useCallback, useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import { generateBackgroundAnimation } from '../../_utilities/utils'
import { fontSize } from '../../_globals/theme'
import {
  TextH1,
  TextH2,
  TextH3,
  TextLink,
  TextParagraph,
  TextTitle,
  Container,
  Flair,
} from './styled'
import { TextProps } from './types'

/**
 * A standardized text component
 *
 * @param {TextProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <TextElement
 *  text={`A ${type} provided by ${companyName}`}
 *  theme="link"
 *  alignment="left"
 *  colour="white"
 *  sizeOverride={fontSize.small}
 *  onClick={() => handleCompanyClick()}
 * />
 * ```
 */
const TextElement = ({
  text,
  theme,
  display,
  alignment,
  colour,
  italics,
  onClick,
  width,
  flair,
  sizeOverride,
}: TextProps): JSX.Element => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick()
    }
  }, [onClick])

  const containerTheme = useMemo(() => {
    const newTheme: DefaultTheme = {
      display: display || 'inline-block',
      width: width || 'initial',
    }

    return newTheme
  }, [display, width])

  const textTheme = useMemo(() => {
    const newTheme: DefaultTheme = {
      justify: alignment || 'left',
      colour: colour || 'black',
      italics: italics || false,
      display: display || 'inline-block',
      cursor: onClick ? 'pointer' : 'inherit',
      width: flair ? 'initial' : '100%',
      fontSize: fontSize.regular,
    }

    if (flair === true && display === 'block') {
      newTheme.display = 'inline-block'
    }

    if (sizeOverride && sizeOverride !== '') {
      newTheme.fontSize = sizeOverride
    } else {
      switch (theme) {
        case 'title':
          newTheme.fontSize = '26px'
          break
        case 'h1':
          newTheme.fontSize = '24px'
          break
        case 'h2':
          newTheme.fontSize = '19px'
          break
        case 'h3':
          newTheme.fontSize = '18px'
          break
        case 'paragraph':
          newTheme.fontSize = fontSize.regular
          break
        case 'link':
          newTheme.fontSize = fontSize.regular
          break
        case 'small':
          newTheme.fontSize = fontSize.small
          break
        default:
          newTheme.fontSize = fontSize.regular
          break
      }
    }

    return newTheme
  }, [alignment, colour, italics, display, onClick, flair, sizeOverride, theme])

  const flairTheme = useMemo(() => {
    const newTheme: DefaultTheme = {
      width: textTheme.display !== 'block' ? 'calc(100% + 40px)' : '100%',
      background: generateBackgroundAnimation('colour'),
    }

    if (textTheme.display === 'block') {
      newTheme.width = '100%'
    } else {
      switch (alignment) {
        case 'center':
          newTheme.width = 'calc(100% + 80px)'
          newTheme.marginLeft = '-40px'
          break
        case 'left':
          newTheme.width = 'calc(100% + 40px)'
          break
        case 'right':
          newTheme.width = 'calc(100% + 40px)'
          newTheme.marginLeft = '-40px'
          break
        default:
          break
      }
    }

    return newTheme
  }, [textTheme, alignment])

  const sanitizedElement = useMemo(() => {
    let newElement = <TextParagraph theme={textTheme}>{text}</TextParagraph>

    switch (theme) {
      case 'title':
        newElement = (
          <TextTitle theme={textTheme} onClick={() => handleClick()}>
            {text}
            {flair ? <Flair theme={flairTheme} /> : null}
          </TextTitle>
        )
        break
      case 'h1':
        newElement = (
          <TextH1 theme={textTheme} onClick={() => handleClick()}>
            {text}
            {flair ? <Flair theme={flairTheme} /> : null}
          </TextH1>
        )
        break
      case 'h2':
        newElement = (
          <TextH2 theme={textTheme} onClick={() => handleClick()}>
            {text}
            {flair ? <Flair theme={flairTheme} /> : null}
          </TextH2>
        )
        break
      case 'h3':
        newElement = (
          <TextH3 theme={textTheme} onClick={() => handleClick()}>
            {text}
            {flair ? <Flair theme={flairTheme} /> : null}
          </TextH3>
        )
        break
      case 'paragraph':
        newElement = (
          <TextParagraph theme={textTheme} onClick={() => handleClick()}>
            {text}
            {flair ? <Flair theme={flairTheme} /> : null}
          </TextParagraph>
        )
        break
      case 'small':
        newElement = (
          <TextParagraph theme={textTheme} onClick={() => handleClick()}>
            {text}
            {flair ? <Flair theme={flairTheme} /> : null}
          </TextParagraph>
        )
        break
      case 'link':
        newElement = (
          <TextLink theme={textTheme} onClick={() => handleClick()}>
            {text}
            {flair ? <Flair theme={flairTheme} /> : null}
          </TextLink>
        )
        break
      default:
        newElement = (
          <TextParagraph theme={textTheme} onClick={() => handleClick()}>
            {text}
            {flair ? <Flair theme={flairTheme} /> : null}
          </TextParagraph>
        )
        break
    }

    return newElement
  }, [theme, text, textTheme, handleClick, flairTheme, flair])

  return <Container theme={containerTheme}>{sanitizedElement}</Container>
}

export default TextElement
