import { CameraState, DatabaseModel, Position } from '../_types/model'

const defaultPosition: Position = {
  x: 0,
  y: 0,
  z: 0,
}

const defaultCameraPosition: CameraState = {
  position: defaultPosition,
  up: defaultPosition,
  target: defaultPosition,
}

const defaultDatabaseModel: DatabaseModel = {
  id: '',
  uploadDateTimestamp: 0,
  fileName: '',
  fileType: '',
  filePath: '',
  fileSizeInBytes: 0,
  uploadedBy: '',
  caseId: '',
  availableForDownload: false,
  annotationIds: [],
  title: '',
  colour: '',
  modelId: 0,
  modelPosition: defaultPosition,
  modelRotation: defaultPosition,
  scale: 1,
  opacity: 1,
}

export { defaultDatabaseModel, defaultPosition, defaultCameraPosition }
