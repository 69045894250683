import styled, { keyframes } from 'styled-components'

import { font, fontSize, colours } from '../../_globals/theme'

const Container = styled.div`
  position: relative;
  display: ${props => props.theme.display};
  width: ${props => props.theme.width};
  vertical-align: middle;
  white-space: normal;
`
Container.defaultProps = {
  theme: {
    display: 'inline-block',
    width: 'initial',
  },
}

const TextTitle = styled.h1`
  text-align: ${props => props.theme.justify};
  color: ${props => props.theme.colour};
  font-family: ${props =>
    props.theme.italics ? font.blackItalics : font.black};
  font-size: ${props => props.theme.fontSize};
  cursor: ${props => props.theme.cursor};
  display: ${props => props.theme.display};
  width: ${props => props.theme.width};
`
TextTitle.defaultProps = {
  theme: {
    justify: 'left',
    colour: 'black',
    italics: false,
    cursor: 'default',
    display: 'inline-block',
    width: 'initial',
    fontSize: '24px',
  },
}

const TextH1 = styled.h1`
  text-align: ${props => props.theme.justify};
  color: ${props => props.theme.colour};
  font-family: ${props => (props.theme.italics ? font.boldItalics : font.bold)};
  font-size: ${props => props.theme.fontSize};
  cursor: ${props => props.theme.cursor};
  display: ${props => props.theme.display};
  width: ${props => props.theme.width};
`
TextH1.defaultProps = {
  theme: {
    justify: 'left',
    colour: 'black',
    italics: false,
    cursor: 'default',
    display: 'inline-block',
    width: 'initial',
    fontSize: '18px',
  },
}

const TextH2 = styled.h2`
  text-align: ${props => props.theme.justify};
  color: ${props => props.theme.colour};
  font-family: ${props => (props.theme.italics ? font.boldItalics : font.bold)};
  font-size: ${props => props.theme.fontSize};
  cursor: ${props => props.theme.cursor};
  display: ${props => props.theme.display};
  width: ${props => props.theme.width};
`
TextH2.defaultProps = {
  theme: {
    justify: 'left',
    colour: 'black',
    italics: false,
    cursor: 'default',
    display: 'inline-block',
    width: 'initial',
    fontSize: '17px',
  },
}

const TextH3 = styled.h3`
  text-align: ${props => props.theme.justify};
  color: ${props => props.theme.colour};
  font-family: ${props => (props.theme.italics ? font.boldItalics : font.bold)};
  font-size: ${props => props.theme.fontSize};
  cursor: ${props => props.theme.cursor};
  display: ${props => props.theme.display};
  width: ${props => props.theme.width};
`
TextH3.defaultProps = {
  theme: {
    justify: 'left',
    colour: 'black',
    italics: false,
    cursor: 'default',
    display: 'inline-block',
    width: 'initial',
    fontSize: '16px',
  },
}

const TextParagraph = styled.p`
  text-align: ${props => props.theme.justify};
  color: ${props => props.theme.colour};
  font-family: ${props =>
    props.theme.italics ? font.regularItalics : font.regular};
  font-size: ${props => props.theme.fontSize};
  cursor: ${props => props.theme.cursor};
  display: ${props => props.theme.display};
  width: ${props => props.theme.width};
`
TextParagraph.defaultProps = {
  theme: {
    justify: 'left',
    colour: 'black',
    italics: false,
    cursor: 'default',
    display: 'inline-block',
    width: 'initial',
    fontSize: fontSize.regular,
  },
}

const TextLink = styled.a`
  text-align: ${props => props.theme.justify};
  color: ${props => props.theme.colour};
  font-family: ${props =>
    props.theme.italics ? font.regularItalics : font.regular};
  font-size: ${props => props.theme.fontSize};
  text-decoration: underline;
  cursor: ${props => props.theme.cursor};
  display: ${props => props.theme.display};
  width: ${props => props.theme.width};
`
TextLink.defaultProps = {
  theme: {
    justify: 'left',
    colour: 'black',
    italics: false,
    cursor: 'default',
    display: 'inline-block',
    width: 'initial',
    fontSize: fontSize.regular,
  },
}

const backgroundGradient = keyframes`
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

const Flair = styled.div`
  width: ${props => props.theme.width};
  height: 5px;
  border-top: ${props => props.theme.borderTop};
  border-bottom: ${props => props.theme.borderBottom};
  margin-left: ${props => props.theme.marginLeft};
  z-index: 2;
  background-color: ${colours.background};
  background: ${props => `linear-gradient(${props.theme.background})`};
  background-size: 200% 100%;
  animation-name: ${backgroundGradient};
  animation-duration: 10s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
`
Flair.defaultProps = {
  theme: {
    width: '100%',
    marginLeft: '0px',
  },
}

export {
  Container,
  TextTitle,
  TextH1,
  TextH2,
  TextH3,
  TextLink,
  TextParagraph,
  Flair,
}
