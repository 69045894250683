import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import XDate from 'xdate'

import { defaultDatabaseComment } from '../../_defaults/comment'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import { useAppSelector } from '../../_globals/hooks'
import { NotificationContext } from '../../_globals/notifications/notification-context'
import { RootState } from '../../_globals/state-store'
import { DatabaseComment, DisplayComment } from '../../_types/comment'
import { getEnvironmentSettings } from '../../_utilities/config'
import { scrollToBottom } from '../../_utilities/dom'
import { deepCopy, getTextFromBackground } from '../../_utilities/utils'
import AlignmentContainer from '../alignment-container/AlignmentContainer'
import Button from '../button/Button'
import DeveloperContainer from '../developer-container/DeveloperContainer'
import Spacer from '../spacer/Spacer'
import TextElement from '../text/Text'
import ToggleInput from '../toggle-input/ToggleInput'
import {
  ChatContainer,
  CommentContainer,
  BottomControls,
  SendComment,
  VisibleToPatientContainer,
  Message,
  Author,
  CommentTimestamp,
  MessageInput,
} from './styled'
import { ChatProps, UserColourMap } from './types'

const environment = getEnvironmentSettings()
let refreshInterval: NodeJS.Timer = null
const userAppSelector = (state: RootState) => state.user

const Chat = ({
  caseId,
  comments,
  onAddComment,
  onRefreshRequest,
}: ChatProps) => {
  const { showNotification } = useContext(NotificationContext)
  const userSelector = useAppSelector(userAppSelector)
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)
  const [userColourMap, setUserColourMap] = useState<UserColourMap>({})
  const [numberOfComments, setNumberOfComments] = useState<number>(0)
  const [enableRefresh, setEnableRefresh] = useState<boolean>(
    environment.ENV_CODE === 'prod',
  )
  const [newComment, setNewComment] = useState<DatabaseComment>(
    deepCopy(defaultDatabaseComment),
  )

  const formattedComments = useMemo<DisplayComment[]>(
    () =>
      comments
        .map(comment => ({
          ...comment,
          timestamp: new XDate(comment.createdTimestamp).toString(
            "dddd MMMM dS, yyyy, 'at' h:mm tt",
          ),
          flairBackgroundColour: userColourMap[comment.authorId],
          flairTextColour: getTextFromBackground(
            userColourMap[comment.authorId] ?? '#000000',
          ),
          isSelected: false,
        }))
        .sort((a, b) => a.createdTimestamp - b.createdTimestamp),
    [comments, userColourMap],
  )

  const handleSave = useCallback(() => {
    if (isApiBusy) {
      showNotification({
        type: 'error',
        title: 'Loading, please wait',
        dismissAfter: 3000,
      })

      return null
    }

    const sanitizedComment = newComment?.comment?.trim() ?? ''

    if (!sanitizedComment || sanitizedComment.length === 0) {
      showNotification({
        type: 'error',
        title: 'Comment cannot be empty',
        dismissAfter: 3000,
      })

      return null
    }

    setIsApiBusy(true)

    doFunctionsCall('HighPriority', {
      signature: 'Comment-Create',
      caseId,
      comment: sanitizedComment,
      visibleToPatient: newComment.visibleToPatient,
    }).then(data => {
      if (data.code === 200) {
        onAddComment(sanitizedComment)
        setNewComment(() => deepCopy(defaultDatabaseComment))
        ;(
          document.getElementById('comment-input') as HTMLTextAreaElement
        ).value = ''
        scrollToBottom('comment-container')
      } else if (data.code === 500) {
        console.error(data)
        showNotification({
          type: 'error',
          title: 'Failed to save comment',
          dismissAfter: 3000,
        })
      }

      setIsApiBusy(false)
    })
  }, [
    caseId,
    isApiBusy,
    newComment.comment,
    newComment.visibleToPatient,
    onAddComment,
    showNotification,
  ])

  const handleDataChange = useCallback(
    (attribute: string, newValue: unknown) => {
      setNewComment(previous => ({ ...previous, [attribute]: newValue }))
    },
    [],
  )

  const handleRefreshToggle = useCallback(() => {
    const isRefreshEnabled = !enableRefresh

    if (isRefreshEnabled) {
      clearInterval(refreshInterval)

      refreshInterval = setInterval(() => {
        onRefreshRequest()
      }, 30_000)
    } else {
      clearInterval(refreshInterval)
    }

    setEnableRefresh(isRefreshEnabled)
  }, [enableRefresh, onRefreshRequest])

  useEffect(() => {
    if (numberOfComments !== comments.length) {
      const newMap: UserColourMap = comments.reduce(
        (previous: UserColourMap, current) => {
          if (previous[current.authorId]) {
            return previous
          }

          if (userColourMap[current.authorId]) {
            return {
              ...previous,
              [current.authorId]: userColourMap[current.authorId],
            }
          }

          return {
            ...previous,
            [current.authorId]: `#${Math.floor(
              Math.random() * 16_777_215,
            ).toString(16)}`,
          }
        },
        {},
      )

      setNumberOfComments(comments.length)
      setUserColourMap(() => ({ ...newMap }))
      scrollToBottom('comment-container')
    }
  }, [comments, numberOfComments, userColourMap])

  useEffect(() => {
    if (environment.ENV_CODE === 'prod') {
      clearInterval(refreshInterval)

      refreshInterval = setInterval(() => {
        onRefreshRequest()
      }, 30_000)
    }

    return () => {
      clearInterval(refreshInterval)
    }
  }, [onRefreshRequest])

  return (
    <ChatContainer>
      <CommentContainer id="comment-container">
        {formattedComments.length === 0 ? (
          <TextElement
            text="No comments yet"
            theme="h2"
            alignment="center"
            display="block"
          />
        ) : (
          <AlignmentContainer align="center" display="block">
            <Spacer direction="vertical" amount="10px" display="block" />
            <Button
              text="Go To Latest Comment"
              callback={() => scrollToBottom('comment-container')}
              theme="secondary"
              size="small"
            />
            <DeveloperContainer mode="environment">
              <Button
                callback={() => handleRefreshToggle()}
                text={enableRefresh ? 'Disable Refresh' : 'Enable Refresh'}
                theme="secondary"
              />
            </DeveloperContainer>
            <Spacer direction="vertical" amount="10px" display="block" />
          </AlignmentContainer>
        )}
        {formattedComments.map(comment => (
          <Message
            tabIndex={0}
            key={comment.id}
            theme={{
              background: comment.flairBackgroundColour,
            }}
            className={comment.authorId === userSelector.id ? 'mine' : ''}>
            <Author
              theme={{
                background: comment.flairBackgroundColour,
                colour: comment.flairTextColour,
              }}>
              {comment.userDisplayName}
            </Author>
            <TextElement
              text={comment.comment}
              colour={comment.authorId === userSelector.id ? 'white' : 'black'}
              theme="paragraph"
            />
            <CommentTimestamp>{comment.timestamp}</CommentTimestamp>
          </Message>
        ))}
      </CommentContainer>
      <BottomControls>
        <MessageInput
          id="comment-input"
          placeholder="Enter comment here"
          onChange={newValue =>
            handleDataChange('comment', newValue.currentTarget.value)
          }
        />
        <VisibleToPatientContainer>
          <ToggleInput
            initialValue={newComment.visibleToPatient}
            callback={newValue =>
              handleDataChange('visibleToPatient', newValue)
            }
            label="Visible to patient"
          />
        </VisibleToPatientContainer>
        <SendComment onClick={() => handleSave()}>
          Post
          <br />
          Comment
        </SendComment>
      </BottomControls>
    </ChatContainer>
  )
}

export default Chat
