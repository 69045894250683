import React, { useMemo, useCallback } from 'react'
import { DefaultTheme } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router'

import { Container, ButtonText, Icon } from './styled'
import { PageBackButtonProps } from './types'

/**
 * A standardized Back button
 *
 * @param {PageBackButtonProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <PageBackButton text="Back" colourMode="dark" urlOnClick="back" />
 * ```
 */
const PageBackButton = ({
  text,
  colourMode,
  urlOnClick,
}: PageBackButtonProps): JSX.Element => {
  const navigation = useNavigate()

  const buttonTheme = useMemo(() => {
    const theme: DefaultTheme = {
      colour: colourMode === 'dark' ? 'white' : 'black',
    }

    return theme
  }, [colourMode])

  const handleClick = useCallback(() => {
    if (urlOnClick && urlOnClick !== 'back') {
      const from = window.location.pathname.split('/')[0]
      navigation(`${urlOnClick}?from=${from}`)
    } else {
      navigation(-1)
    }
  }, [navigation, urlOnClick])

  return (
    <Container onClick={() => handleClick()}>
      <Icon>
        <FontAwesomeIcon icon={faArrowLeftLong} color={buttonTheme.colour} />
      </Icon>
      <ButtonText theme={buttonTheme}>{text}</ButtonText>
    </Container>
  )
}

export default PageBackButton
