import React, { useMemo, useEffect, useCallback, useState } from 'react'
import { DefaultTheme } from 'styled-components'
import { useNavigate } from 'react-router'

import LogoImage from '../../_assets/images/logo.png'

import { doesExist } from '../../_utilities/utils'
import Loading from '../loading/Loading'
import TextElement from '../text/Text'
import Spacer from '../spacer/Spacer'
import Button from '../button/Button'
import AlignmentContainer from '../alignment-container/AlignmentContainer'
import { RootState } from '../../_globals/state-store'
import { useAppDispatch, useAppSelector } from '../../_globals/hooks'
import { themeSlice } from '../../_globals/theme/theme-slice'
import { PageContainerProps } from './types'
import { Container, AlertContainer, Logo } from './styled'

const userAppSelector = (state: RootState) => state.user

/**
 * A standardized container for all pages
 *
 * @param {PageContainerProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <PageContainer
 *  height="100%"
 *  backgroundColour={colours.background}
 *  allowUnauthenticated={true}
 *  onPageReady={() => handlePageReady()}
 * >
 *  <p>I'm page content! Look at me!</p>
 * </PageContainer>
 * ```
 */
const PageContainer = ({
  children,
  width,
  height,
  offsetBottom,
  offsetLeft,
  offsetRight,
  offsetTop,
  offsetMode,
  backgroundColour,
  allowUnauthenticated,
  isContentAvailable,
  isLoading,
  pageError,
  allowedRoles,
  allowNotifications,
  showSidebar,
  onAuthentication,
  onPageReady,
}: PageContainerProps): JSX.Element => {
  const navigation = useNavigate()
  const userSelector = useAppSelector(userAppSelector)
  const dispatch = useAppDispatch()
  const [isApiLoading, setIsApiLoading] = useState<boolean>(true)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [showBypass, setShowBypass] = useState<boolean>(false)
  const [viewUnavailableContent, setViewUnavailableContent] =
    useState<boolean>(false)
  const [hasCalledOnAuthentication, setHasCalledOnAuthentication] =
    useState<boolean>(false)
  const [hasCalledOnPageReady, setHasCalledOnPageReady] =
    useState<boolean>(false)

  const sanitizedPageError = useMemo(
    () => (!doesExist(pageError) || pageError === '' ? '' : pageError),
    [pageError],
  )

  const sanitizedIsContentAvailable = useMemo(
    () => isContentAvailable !== false || viewUnavailableContent === true,
    [isContentAvailable, viewUnavailableContent],
  )

  const sanitizedIsLoading = useMemo(
    () => isLoading === true || isApiLoading === true,
    [isLoading, isApiLoading],
  )

  const sanitizedIsAuthenticated = useMemo(() => {
    if (allowUnauthenticated === true) {
      return true
    } else if (isAuthenticated === false) {
      return false
    } else if (
      allowedRoles &&
      allowedRoles.length > 0 &&
      allowedRoles.includes(userSelector.role) === false
    ) {
      return false
    } else if (allowUnauthenticated === false && isAuthenticated === true) {
      return true
    }

    return false
  }, [allowUnauthenticated, isAuthenticated, allowedRoles, userSelector?.role])

  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      width: width || '100%',
      height: height || '100vh',
      paddingLeft: '0px',
      paddingTop: '0px',
      paddingRight: '0px',
      paddingBottom: '0px',
      marginLeft: '0px',
      marginTop: '0px',
      marginRight: '0px',
      marginBottom: '0px',
      background: backgroundColour ?? '#ffffff',
    }

    if (offsetMode === 'padding') {
      theme.paddingBottom = offsetBottom ?? '0px'
      theme.paddingLeft = offsetLeft ?? '0px'
      theme.paddingRight = offsetRight ?? '0px'
      theme.paddingTop = offsetTop ?? '0px'
    } else if (offsetMode === 'margin') {
      theme.marginBottom = offsetBottom ?? '0px'
      theme.marginLeft = offsetLeft ?? '0px'
      theme.marginTop = offsetTop ?? '0px'
      theme.marginRight = offsetRight ?? '0px'
    }

    return theme
  }, [
    width,
    height,
    offsetBottom,
    offsetLeft,
    offsetRight,
    offsetTop,
    offsetMode,
    backgroundColour,
  ])

  useEffect(() => {
    if (
      sanitizedIsAuthenticated === true &&
      doesExist(onAuthentication) &&
      hasCalledOnAuthentication === false
    ) {
      onAuthentication()
      setHasCalledOnAuthentication(true)
    }

    if (
      sanitizedIsAuthenticated === true &&
      sanitizedIsLoading === false &&
      sanitizedPageError === '' &&
      doesExist(onPageReady) &&
      hasCalledOnPageReady === false
    ) {
      onPageReady()
      setHasCalledOnPageReady(true)
    }
  }, [
    onAuthentication,
    onPageReady,
    sanitizedPageError,
    sanitizedIsAuthenticated,
    sanitizedIsLoading,
    hasCalledOnAuthentication,
    hasCalledOnPageReady,
  ])

  const handleHomeClick = useCallback(() => {
    const from = window.location.pathname.split('/')[0]
    navigation(`/home?from=${from}`)
  }, [navigation])

  const handleLoginClick = useCallback(() => {
    const from = window.location.pathname.split('/')[0]
    navigation(`/login?redirect_to=${window.location.pathname}$from=${from}`)
  }, [navigation])

  const handleBackClick = useCallback(() => {
    navigation(-1)
  }, [navigation])

  useEffect(() => {
    setIsAuthenticated(true)
    setIsApiLoading(false)
    setShowBypass(false)
  }, [])

  useEffect(() => {
    if (sanitizedIsAuthenticated === true) {
      dispatch(themeSlice.actions.setShowSidebar(showSidebar))
      dispatch(themeSlice.actions.setAllowNotifications(allowNotifications))
    } else {
      dispatch(themeSlice.actions.setShowSidebar(false))
      dispatch(themeSlice.actions.setAllowNotifications(true))
    }
  }, [allowNotifications, dispatch, sanitizedIsAuthenticated, showSidebar])

  return (
    <Container theme={containerTheme}>
      {sanitizedIsLoading === true ? (
        <Loading type="large" alignment="center" />
      ) : null}
      {sanitizedIsLoading === false && sanitizedIsContentAvailable === false ? (
        <AlertContainer>
          <Spacer direction="vertical" amount="30px" display="block" />
          <TextElement
            text="This Content Is Currently Unavailable"
            theme="h1"
            alignment="center"
            display="block"
          />
          <Spacer direction="vertical" amount="30px" display="block" />
          <TextElement
            text="We're sorry for the inconvenience!"
            theme="paragraph"
            alignment="center"
            display="block"
          />
          <Spacer direction="vertical" amount="30px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Go Back"
              callback={() => handleBackClick()}
              theme="dark"
            />
          </AlignmentContainer>
          {showBypass ? (
            <>
              <Spacer direction="vertical" amount="100px" display="block" />
              <AlignmentContainer align="center" display="block">
                <Button
                  text="Bypass & View Content"
                  callback={() => setViewUnavailableContent(true)}
                  theme="dark"
                />
              </AlignmentContainer>
              <Spacer direction="vertical" amount="10px" display="block" />
              <TextElement
                text="You are seeing this option because your company created this content."
                theme="paragraph"
                alignment="center"
                display="block"
                italics={true}
              />
            </>
          ) : null}
        </AlertContainer>
      ) : null}
      {sanitizedIsLoading === false &&
      sanitizedIsAuthenticated === false &&
      sanitizedIsContentAvailable === true ? (
        <AlertContainer>
          <Spacer direction="vertical" amount="30px" display="block" />
          <Logo src={LogoImage} />
          <Spacer direction="vertical" amount="50px" display="block" />
          <AlignmentContainer align="center" display="block">
            <TextElement
              text="You must log in first!"
              theme="h1"
              alignment="center"
              display="inline-block"
              width="300px"
            />
            <Spacer direction="vertical" amount="30px" display="block" />
            <TextElement
              text="This page is only available to logged in users with specific permissions."
              theme="paragraph"
              alignment="center"
              display="inline-block"
            />
            <Spacer direction="vertical" amount="10px" display="block" />
            <TextElement
              text="If you logged in using a different account, please log out and log back in with the correct account."
              theme="paragraph"
              alignment="center"
              display="inline-block"
            />
            <Spacer direction="vertical" amount="10px" display="block" />
            <TextElement
              text="If you still do not have access, please contact your administrator."
              theme="paragraph"
              alignment="center"
              display="inline-block"
            />
          </AlignmentContainer>
          <Spacer direction="vertical" amount="50px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Login"
              callback={() => handleLoginClick()}
              theme="flair"
              size="large"
            />
          </AlignmentContainer>
          <Spacer direction="vertical" amount="30px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Go Back Without Logging In"
              callback={() => handleBackClick()}
              theme="dark"
            />
          </AlignmentContainer>
        </AlertContainer>
      ) : null}
      {sanitizedPageError !== '' ? (
        <AlertContainer>
          <TextElement
            text="An Error Occurred"
            theme="h1"
            alignment="center"
            display="block"
          />
          <TextElement
            text={pageError}
            theme="paragraph"
            alignment="center"
            display="block"
          />
          <Spacer direction="vertical" amount="30px" />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Lets Get You Back Home"
              callback={() => handleHomeClick()}
              theme="main"
            />
          </AlignmentContainer>
        </AlertContainer>
      ) : null}
      {sanitizedIsLoading === false &&
      sanitizedIsAuthenticated === true &&
      sanitizedPageError === '' &&
      sanitizedIsContentAvailable === true
        ? children
        : null}
    </Container>
  )
}

PageContainer.defaultProps = {
  width: '100%',
  height: '100vh',
  offsetBottom: '0px',
  offsetLeft: '0px',
  offsetRight: '0px',
  offsetTop: '0px',
  offsetMode: 'padding',
  backgroundColour: '#ffffff',
}

export default PageContainer
