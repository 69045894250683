import React, { useContext, useState, useCallback } from 'react'
import { useParams } from 'react-router'

import { NotificationContext } from '../../_globals/notifications/notification-context'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import PageContainer from '../../components/page-container/PageContainer'
import PageBackButton from '../../components/page-back-button/PageBackButton'
import TextElement from '../../components/text/Text'
import Spacer from '../../components/spacer/Spacer'
import Button from '../../components/button/Button'
import Loading from '../../components/loading/Loading'
import TextInput from '../../components/text-input/TextInput'
import PrettyFormContainer from '../../components/pretty-form-container/PrettyFormContainer'
import PageError from '../../components/page-error/PageError'
import { FormError } from '../../_types/globals'
import { scrollElementIntoView } from '../../_utilities/dom'
import { ApiResponse } from '../../_types/api'
import { DatabaseClientUser } from '../../_types/client'
import { validate } from './helpers'
import { ClientPageParams } from './types'

/**
 * Client page
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <Client />
 * ```
 */
const Client = (): JSX.Element => {
  const { id } = useParams<ClientPageParams>()
  const { showNotification } = useContext(NotificationContext)
  const [formErrors, setFormErrors] = useState<FormError[]>([])
  const [criticalError, setCriticalError] = useState<string>('')
  const [client, setClient] = useState<DatabaseClientUser>()
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)

  const handlePageReady = useCallback(() => {
    setIsApiBusy(true)

    doFunctionsCall('HighPriority', {
      signature: 'Client-Get',
      clientId: id,
    })
      .then((response: ApiResponse) => {
        if (response.code === 200) {
          const fetchedClient: DatabaseClientUser = JSON.parse(response.data)

          setClient(() => ({ ...fetchedClient }))
        } else if (response.code === 500) {
          console.error(response)

          showNotification({
            title: 'Could not fetch client',
            type: 'error',
            dismissAfter: 3500,
          })

          setCriticalError('Could not fetch client')
        }
      })
      .catch((error: Error) => {
        console.error(error)

        showNotification({
          title: 'Could not fetch client',
          type: 'error',
          dismissAfter: 3500,
        })
      })
      .finally(() => {
        setIsApiBusy(false)
      })
  }, [id, showNotification])

  const handleFormDataChange = useCallback(
    (attribute: string, newValue: unknown) => {
      setClient(previous => ({ ...previous, [attribute]: newValue }))
    },
    [],
  )

  const handleValidateData = useCallback(
    (showNotifications: boolean) => {
      const errors = validate(client)
      setFormErrors(() => [...errors])

      if (errors.length > 0) {
        if (showNotifications === true) {
          errors.forEach(error => {
            showNotification({
              title: error.message,
              type: 'error',
              dismissAfter: 3500,
              onPress: () => scrollElementIntoView(error.elementId),
            })
          })
        }

        return false
      }

      return true
    },
    [client, showNotification],
  )

  const handleSaveClick = useCallback(() => {
    if (isApiBusy === true) {
      showNotification({
        title: 'Working on it!',
        type: 'info',
        dismissAfter: 3000,
      })

      return null
    }

    if (handleValidateData(true) === false) {
      return null
    }

    setIsApiBusy(true)

    doFunctionsCall('Admin', {
      signature: 'Client-Update',
      client,
    })
      .then(data => {
        if (data.code === 200) {
          showNotification({
            title: 'Successfully updated client!',
            type: 'success',
            dismissAfter: 3500,
          })
        } else if (data.code === 500) {
          console.error(data)
          showNotification({
            title: data.message,
            type: 'error',
            dismissAfter: 5000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        showNotification({
          title: 'Could not update client',
          type: 'error',
          dismissAfter: 5000,
        })
      })
      .finally(() => {
        setIsApiBusy(false)
      })
  }, [isApiBusy, handleValidateData, client, showNotification])

  return (
    <PageContainer
      height="initial"
      width="initial"
      offsetBottom="10px"
      offsetLeft="10px"
      offsetRight="10px"
      offsetTop="10px"
      offsetMode="padding"
      allowedRoles={['super-admin', 'admin']}
      pageError={criticalError}
      allowUnauthenticated={false}
      allowNotifications={true}
      showSidebar={true}
      onPageReady={() => handlePageReady()}>
      <PageBackButton
        text="Back to Clients"
        colourMode="light"
        urlOnClick="/clients"
      />
      <TextElement
        text={client?.displayName ?? 'Loading Client...'}
        theme="h1"
        alignment="center"
        colour="black"
        display="block"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      {client ? (
        <>
          <PrettyFormContainer
            title="Base User"
            description={[
              'The base user account this client will be based off of',
            ]}
            formId="userId"
            formContent={
              <TextInput
                value={`${client?.displayName} (${client?.email})`}
                isDisabled={true}
              />
            }
          />
          <PrettyFormContainer
            title="Noun"
            description={[
              'What this client is called.',
              '',
              'Example: Doctor, Educator, Surgeon, etc',
            ]}
            formId="entityNoun"
            formContent={
              <TextInput
                initialValue={client.entityNoun}
                onTextChange={newValue =>
                  handleFormDataChange('entityNoun', newValue)
                }
              />
            }
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <PageError
            data={formErrors}
            revalidateCallback={() => handleValidateData(false)}
          />
          <AlignmentContainer align="center" display="block">
            <Button
              callback={() => handleSaveClick()}
              text="Save"
              theme="flair"
              display="inline-block"
              size="large"
              isDisabled={isApiBusy}
            />
          </AlignmentContainer>
        </>
      ) : (
        <Loading type="large" />
      )}
      <Spacer direction="vertical" amount="20px" display="block" />
    </PageContainer>
  )
}

export default Client
