import styled from 'styled-components'

const HorizontalRule = styled.div`
  width: 100%;
  border: none;
  background-color: black;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
`

const VerticalRule = styled.div`
  height: 100%;
  width: 1px;
  background-color: black;
  border: none;
  margin-right: 10px;
  margin-left: 10px;
`

export { HorizontalRule, VerticalRule }
