import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { deepCopy } from '../../_utilities/utils'
import { setLocalStorageItem } from '../../_data/storage'
import { DisplayUser } from '../../_types/user'
import { UserState, LocalUserCopy, UserStateUpdate } from './types'

const initialState: UserState = {
  id: '',
  accountCreatedTimestamp: Date.now(),
  displayName: '',
  email: '',
  firstName: '',
  lastName: '',
  role: 'user',
  isBusy: false,
}

/**
 * Save user data to local storage
 *
 * @param {UserState} state - Current user data
 * @returns {void}
 *
 * ```ts
 * saveUserLocally(state.user)
 * ```
 */
const saveUserLocally = (state: UserState) => {
  const copyOfUser: UserState = deepCopy(state)

  const saveableObject: LocalUserCopy = {
    id: copyOfUser.id,
    accountCreatedTimestamp: copyOfUser.accountCreatedTimestamp,
    displayName: copyOfUser.displayName,
    email: copyOfUser.email,
    firstName: copyOfUser.firstName,
    lastName: copyOfUser.lastName,
    role: copyOfUser.role,
    lastSave: Date.now(),
  }

  setLocalStorageItem('user', JSON.stringify(saveableObject))
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state, action: PayloadAction<DisplayUser>) => {
      state.isBusy = true

      const localUser: UserState = {
        id: action.payload.id,
        accountCreatedTimestamp: action.payload.accountCreatedTimestamp,
        displayName: action.payload.displayName,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        role: action.payload.role,
        isBusy: true,
      }

      Object.keys(state).forEach(key => {
        state[key] = localUser[key]
      })

      saveUserLocally(state)
      state.isBusy = false
    },
    getUser: state => deepCopy(state),
    reinitializeUser: (state, action: PayloadAction<LocalUserCopy>) => {
      state.isBusy = true

      state.id = action.payload.id
      state.accountCreatedTimestamp = action.payload.accountCreatedTimestamp
      state.displayName = action.payload.displayName
      state.email = action.payload.email
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.role = action.payload.role
      state.lastSave = Date.now()

      saveUserLocally(state)
      state.isBusy = false
    },
    clearUser: state => {
      state.isBusy = true

      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })

      saveUserLocally(state)
      state.isBusy = false
    },
    updateUser: (
      state,
      action: PayloadAction<UserStateUpdate | UserStateUpdate[]>,
    ) => {
      state.isBusy = true
      const payload: UserStateUpdate[] = Array.isArray(action.payload)
        ? action.payload
        : [action.payload]

      payload.forEach(updatedAttribute => {
        if (
          Object.prototype.hasOwnProperty.call(
            state,
            updatedAttribute.attribute,
          )
        ) {
          state[updatedAttribute.attribute] = updatedAttribute.value
        }
      })

      saveUserLocally(state)
      state.isBusy = false
    },
  },
})

export { userSlice }

export default userSlice.reducer
