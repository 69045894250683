import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import { DefaultTheme } from 'styled-components'
import {
  faUserFriends,
  faClipboardList,
  faUserDoctor,
  faUserCircle,
  faArrowsLeftRightToLine,
  faDownLeftAndUpRightToCenter,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Logo from '../../_assets/images/logo.png'
import Icon from '../../_assets/images/icon.png'
import { sidebarSize } from '../../_globals/theme'
import { useAppSelector } from '../../_globals/hooks'
import { RootState } from '../../_globals/state-store'
import { NotificationContext } from '../../_globals/notifications/notification-context'
import Spacer from '../spacer/Spacer'
import NavigationItem from './components/navigation-item/NavigationItem'
import {
  SideBarContainer,
  ContentContainer,
  NavigationContainer,
  ToggleSidebarButton,
  SiteLogo,
  NavigationScrollContainer,
  LogoContainer,
  NavigationItemContainer,
} from './styled'
import { SideBarProps } from './types'

const userAppSelector = (state: RootState) => state.user

/**
 * A generic slide-out side menu
 *
 * @param {SideBarProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <SideBar
 *  pagePosition="right"
 *  positionMode="fixed"
 *  sidebarContent={<CartContent />}
 * >
 *  <ContentContainer theme={contentTheme}>{children}</ContentContainer>
 * </SideBar>
 * ```
 */
const SideBar = ({ hideSidebar, children }: SideBarProps): JSX.Element => {
  const reactLocation = useLocation()
  const userSelector = useAppSelector(userAppSelector)
  const navigation = useNavigate()
  const { showNotification } = useContext(NotificationContext)
  const [activeRoute, setActiveRoute] = useState<string>('')
  const [isNavExpanded, setIsNavExpanded] = useState<boolean>(
    window.innerWidth > 1000,
  )

  const isAuthorized = useMemo(() => {
    if (!userSelector || userSelector.id === '') {
      return false
    }

    return true
  }, [userSelector])

  const sanitizedHideSidebar = useMemo(
    () => Boolean(hideSidebar === true),
    [hideSidebar],
  )

  const navigationTheme = useMemo(() => {
    const theme: DefaultTheme = {
      width: isNavExpanded ? sidebarSize.expanded : sidebarSize.collapsed,
    }

    return theme
  }, [isNavExpanded])

  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      width: isNavExpanded
        ? `calc(100% - ${sidebarSize.expanded} - 2px)`
        : `calc(100% - ${sidebarSize.collapsed} - 2px)`,
    }

    return theme
  }, [isNavExpanded])

  const handleLinkClick = useCallback(
    (url: string) => {
      navigation(url)
    },
    [navigation],
  )

  useEffect(() => {
    // if (possibleCompanies.length === 0 && userSelector?.companyId !== '') {
    //   doFunctionsCall('Admin', {
    //     signature: 'Employee-GetEmployeeCompanies',
    //   }).then(rawCompanies => {
    //     if (rawCompanies.code === 200 || rawCompanies.code === 501) {
    //       const companies: DropdownData[] = JSON.parse(rawCompanies.data)
    //       if (companies.some(company => company.id === 'northern-devs')) {
    //         setIsNorthernDevs(true)
    //       }
    //       setPossibleCompanies(companies)
    //     } else {
    //       showNotification({
    //         title: 'Could not fetch your company, please reload and try again',
    //         type: 'error',
    //         dismissAfter: 5000,
    //       })
    //     }
    //   })
    // }
  }, [showNotification, userSelector?.companyId])

  useEffect(() => {
    const path = reactLocation.pathname.toLowerCase()

    const sanitizedRoute = path.slice(1)
    setActiveRoute(sanitizedRoute)
  }, [reactLocation])

  return (
    <SideBarContainer>
      {sanitizedHideSidebar === true ? null : (
        <NavigationContainer theme={navigationTheme}>
          <NavigationScrollContainer>
            <LogoContainer
              theme={{ width: isNavExpanded ? '200px' : '55px' }}
              onClick={() => handleLinkClick('/')}>
              <SiteLogo src={isNavExpanded ? Logo : Icon} />
            </LogoContainer>
            {/* <NavigationItem
              icon={faHomeAlt}
              title="Home"
              url="/"
              isActive={activeRoute === 'home'}
            /> */}
            <NavigationItemContainer>
              {isAuthorized ? (
                <>
                  <NavigationItem
                    icon={faUserFriends}
                    title="Users"
                    url="/users"
                    isActive={activeRoute.includes('user')}
                  />
                  <NavigationItem
                    icon={faClipboardList}
                    title="Cases"
                    url="/cases"
                    isActive={
                      activeRoute.includes('case') || activeRoute === ''
                    }
                  />
                  <NavigationItem
                    icon={faUserDoctor}
                    title="Clients"
                    url="/clients"
                    isActive={activeRoute.includes('client')}
                  />
                  <Spacer direction="vertical" amount="50px" display="block" />
                  <NavigationItem
                    icon={faUserCircle}
                    title="Profile"
                    url="/profile"
                    isActive={activeRoute.includes('profile')}
                  />
                  <NavigationItem
                    icon={faQuestionCircle}
                    title="Help"
                    url="/help"
                    isActive={activeRoute.includes('help')}
                  />
                </>
              ) : null}
            </NavigationItemContainer>
            <ToggleSidebarButton
              onClick={() => setIsNavExpanded(previous => !previous)}>
              <FontAwesomeIcon
                icon={
                  isNavExpanded
                    ? faDownLeftAndUpRightToCenter
                    : faArrowsLeftRightToLine
                }
                color="black"
                size="2x"
              />
            </ToggleSidebarButton>
          </NavigationScrollContainer>
        </NavigationContainer>
      )}
      <ContentContainer theme={containerTheme}>{children}</ContentContainer>
    </SideBarContainer>
  )
}

export default SideBar
