import { keyframes } from 'styled-components'

const colours = {
  main: '#393186',
  highlight: '#a2a3e9',
  lowlight: '#afafaf',
  background: '#999999',
  lightBackground: '#ffffff',
}

const fontSize = {
  small: '12px',
  regular: '16px',
  title: '20px',
}

const font = {
  light: 'Roboto-Light',
  lightItalics: 'Roboto-LightItalic',
  regular: 'Roboto-Regular',
  regularItalics: 'Roboto-Italic',
  bold: 'Roboto-Bold',
  boldItalics: 'Roboto-BoldItalic',
  black: 'Roboto-Black',
  blackItalics: 'Roboto-BlackItalic',
}

const sidebarSize = {
  collapsed: '55px',
  expanded: '200px',
}

const viewportBreakpoints = {
  phone: [0, 600],
  tablet: [601, 800],
  laptop: [801, 1024],
  desktop: [1025, 1200],
  tv: [1201, 999_999],
}

const backgroundGradient = keyframes`
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

const shadow = {
  main: '3px 3px 10px 0px #a2a3e9',
}

export {
  colours,
  fontSize,
  font,
  viewportBreakpoints,
  sidebarSize,
  backgroundGradient,
  shadow,
}
