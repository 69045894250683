import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import XDate from 'xdate'

import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import Button from '../../components/button/Button'
import Loading from '../../components/loading/Loading'
import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import Table from '../../components/table/Table'
import { TableData } from '../../components/table/types'
import TextElement from '../../components/text/Text'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import { useAppSelector } from '../../_globals/hooks'
import { NotificationContext } from '../../_globals/notifications/notification-context'
import { RootState } from '../../_globals/state-store'
import { DisplayCase } from '../../_types/case'
import { convertStatusToTitle } from './helpers'

const userAppSelector = (state: RootState) => state.user

/**
 * Cases page
 * @returns {JSX.Element}
 *
 * ```tsx
 * <Cases />
 * ```
 */
const Cases = (): JSX.Element => {
  const navigation = useNavigate()
  const userSelector = useAppSelector(userAppSelector)
  const { showNotification } = useContext(NotificationContext)
  const [criticalError, setCriticalError] = useState<string>('')
  const [cases, setCases] = useState<DisplayCase[]>([])
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)

  const canViewArchivedCases = useMemo<boolean>(
    () => userSelector.role === 'super-admin' || userSelector.role === 'admin',
    [userSelector.role],
  )

  const canCreateNewCase = useMemo<boolean>(
    () =>
      userSelector.role === 'super-admin' ||
      userSelector.role === 'admin' ||
      userSelector.role === 'designer',
    [userSelector.role],
  )

  const handlePageReady = useCallback(() => {
    setIsApiBusy(true)

    doFunctionsCall('Admin', {
      signature: 'Case-GetAll',
    })
      .then(data => {
        if (data.code === 200) {
          const fetchedCases: DisplayCase[] = JSON.parse(data.data)

          setCases(() => [...fetchedCases])
        } else if (data.code === 500) {
          console.error(data)
          setCriticalError('Failed to fetch cases')

          showNotification({
            title: 'Failed to fetch cases!',
            type: 'error',
            dismissAfter: 3000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        setCriticalError('Failed to fetch cases')

        showNotification({
          title: 'Failed to fetch cases!',
          type: 'error',
          dismissAfter: 3000,
        })
      })
      .finally(() => {
        setIsApiBusy(false)
      })
  }, [showNotification])

  const handleViewCase = useCallback((caseId: string) => {
    window.open(`${window.location.origin}/case/${caseId}`, `_self`)
  }, [])

  const handleAddNewCase = useCallback(() => {
    navigation('/case/new')
  }, [navigation])

  const tableHeaders = useMemo<TableData[]>(
    () => [
      { content: 'Title' },
      { content: 'Internal ID' },
      { content: 'Anatomiz3D Case ID' },
      { content: 'Client Reference ID' },
      { content: 'Status' },
      { content: 'Owner' },
      { content: 'Created' },
      { content: 'Client' },
      ...(canViewArchivedCases ? [{ content: 'Archived' }] : []),
      { content: 'Actions' },
    ],
    [canViewArchivedCases],
  )

  const tableContent = useMemo<TableData[][]>(() => {
    const data: TableData[][] = []

    cases.forEach(caseData => {
      const row: TableData[] = [
        { content: caseData.title },
        { content: caseData.friendlyId },
        { content: caseData.anatomiz3dId, useHiddenElement: true },
        { content: caseData.institutionId, useHiddenElement: true },
        { content: convertStatusToTitle(caseData.status) },
        { content: caseData.anatomiz3dEngineerName },
        {
          content: new XDate(caseData.createdAtTimestamp).toString(
            'MMM dd, yyyy',
          ),
        },
        { content: caseData.clientName || 'Unassigned' },
        ...(canViewArchivedCases
          ? [{ content: caseData.isArchived ? 'Yes' : 'No' }]
          : []),
        {
          content: (
            <Button
              callback={() => handleViewCase(caseData.id)}
              text="View Case"
              theme="secondary"
              display="inline-block"
              size="small"
            />
          ),
        },
      ]

      data.push(row)
    })

    return data
  }, [canViewArchivedCases, cases, handleViewCase])

  return (
    <PageContainer
      height="initial"
      width="initial"
      offsetBottom="10px"
      offsetLeft="10px"
      offsetRight="10px"
      offsetTop="10px"
      offsetMode="padding"
      allowedRoles={['super-admin', 'admin', 'designer']}
      allowUnauthenticated={false}
      allowNotifications={true}
      showSidebar={true}
      pageError={criticalError}
      onPageReady={() => handlePageReady()}>
      <TextElement text="Cases" theme="h1" alignment="center" display="block" />
      {isApiBusy ? (
        <Loading type="large" />
      ) : (
        <>
          {canCreateNewCase ? (
            <>
              <Spacer direction="vertical" amount="10px" display="block" />
              <AlignmentContainer align="center" display="block">
                <Button
                  text="Add New Case"
                  theme="main"
                  callback={() => handleAddNewCase()}
                />
              </AlignmentContainer>
            </>
          ) : null}
          <Spacer direction="vertical" amount="20px" display="block" />
          <Table
            alignment="center"
            headerDirection="horizontal"
            size="large"
            width="1200px"
            headers={tableHeaders}
            content={tableContent}
          />
        </>
      )}
    </PageContainer>
  )
}

export default Cases
