import CryptoJS from 'crypto-js'

/**
 * Encrypts a string using AES encryption
 *
 * @param {string} content The string to encrypt
 * @param {string} nonce The key to use for encryption
 * @returns {string} The encrypted string
 *
 * @example
 * ```ts
 * const encryptedText = encryptText('Text to encrypt', 'mySecretKey')
 * ```
 */
const encryptText = (content: string, nonce: string) => {
  const cipher = CryptoJS.AES.encrypt(content, nonce)

  return cipher.toString()
}

/**
 * Decrypts a string using AES encryption
 *
 * @param {string} content The string to decrypt
 * @param {string} nonce The key to use for decryption
 * @returns {string} The decrypted string
 *
 * @example
 * ```ts
 * const decryptedText = decryptText('Text to decrypt', 'mySecretKey')
 * ```
 */
const decryptText = (content: string, nonce: string) => {
  const bytes = CryptoJS.AES.decrypt(content, nonce)
  const originalContent = bytes.toString(CryptoJS.enc.Utf8)

  return originalContent
}

export { encryptText, decryptText }
