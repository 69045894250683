/* eslint-disable @typescript-eslint/no-explicit-any */
import { log } from '../_utilities/logging'
import { doesExist, formatErrorObject } from '../_utilities/utils'
import { decryptText, encryptText } from './cryptography'

/**
 * Get an item from local storage
 *
 * @param {string} key The key of the item to get
 * @param {never} defaultValue The default value to return if the item does not exist
 * @returns {Promise<string>} The value of the item
 *
 * @example
 * ```ts
 * getLocalStorageItem('myKey', 'myDefaultValue').then(value => {
 *  console.log(value)
 * })
 * ```
 */
const getLocalStorageItem = (key: string, defaultValue: any): Promise<string> =>
  new Promise((resolve, _) => {
    try {
      const value = localStorage.getItem(key)

      if (doesExist(value) && value !== 'undefined') {
        const decryptedValue = decryptText(value, key)
        resolve(decryptedValue)
      } else {
        resolve(defaultValue)
      }
    } catch (error) {
      log(
        'storage.ts',
        'getLocalStorageItem',
        'error',
        'Error accessing AsyncStorage',
        formatErrorObject(error),
        true,
      )
      resolve(defaultValue)
    }
  })

/**
 * Set an item in local storage
 *
 * @param {string} key The key of the item to set
 * @param {string} value The value of the item to set
 * @returns {Promise<boolean>} Whether the item was set successfully
 *
 * @example
 * ```ts
 * setLocalStorageItem('myKey', 'myValue').then(success => {
 *  console.log(success)
 * })
 * ```
 */
const setLocalStorageItem = (key: string, value: string): Promise<boolean> =>
  new Promise((resolve, _) => {
    try {
      if (value !== 'undefined') {
        const encryptedValue = encryptText(value, key)
        localStorage.setItem(key, encryptedValue)

        resolve(true)
      } else {
        resolve(false)
      }
    } catch (error) {
      log(
        'storage.ts',
        'setLocalStorageItem',
        'error',
        'Error accessing AsyncStorage',
        formatErrorObject(error),
        true,
      )
      resolve(false)
    }
  })

/**
 * Check if an item exists in local storage
 *
 * @param {string} key The key of the item to check
 * @returns {Promise<boolean>} Whether the item exists
 *
 * @example
 * ```ts
 * checkLocalStorageItem('myKey').then(exists => {
 *  console.log(exists)
 * })
 * ```
 */
const checkLocalStorageItem = (key: string): Promise<boolean> =>
  new Promise((resolve, _) => {
    try {
      const value = localStorage.getItem(key)
      resolve(doesExist(value) && value !== 'undefined')
    } catch (error) {
      log(
        'storage.ts',
        'checkLocalStorageItem',
        'error',
        'Error accessing AsyncStorage',
        formatErrorObject(error),
        true,
      )
      resolve(false)
    }
  })

/**
 * Remove an item from local storage
 *
 * @param {string} key The key of the item to remove
 * @returns {Promise<boolean>} Whether the item was removed successfully
 *
 * @example
 * ```ts
 * removeLocalStorageItem('myKey').then(success => {
 *  console.log(success)
 * })
 * ```
 */
const removeLocalStorageItem = (key: string): Promise<boolean> =>
  new Promise((resolve, _) => {
    try {
      localStorage.removeItem(key)
      resolve(true)
    } catch (error) {
      log(
        'storage.ts',
        'removeLocalStorageItem',
        'error',
        'Error accessing AsyncStorage',
        formatErrorObject(error),
        true,
      )
      resolve(false)
    }
  })

export {
  checkLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
}
