import React, { useCallback, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DefaultTheme } from 'styled-components'
import { useNavigate } from 'react-router'

import TextElement from '../../../text/Text'
import { NavigationItemProps } from './types'
import {
  NavigationIcon,
  NavigationItemContainer,
  ContentContainer,
} from './styled'

/**
 * Standardized menu links
 *
 * @param {NavigationItemProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <NavigationItem
 *  icon={faPlusCircle}
 *  title="Addons"
 *  url="/addons"
 *  isActive={activeRoute === 'addons'}
 * />
 * ```
 */
const NavigationItem = ({
  icon,
  title,
  url,
  isActive,
}: NavigationItemProps): JSX.Element => {
  const navigation = useNavigate()

  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      background: isActive ? 'white' : 'none',
      colour: isActive ? 'white' : 'transparent',
    }

    return theme
  }, [isActive])

  const handleNavigationClick = useCallback(() => {
    navigation(url)
  }, [navigation, url])

  return (
    <NavigationItemContainer
      onClick={() => handleNavigationClick()}
      theme={containerTheme}>
      <ContentContainer theme={containerTheme}>
        <NavigationIcon>
          <FontAwesomeIcon icon={icon} color="black" size="2x" />
        </NavigationIcon>
        <TextElement
          text={title}
          theme="h2"
          alignment="left"
          colour="black"
          display="inline-block"
        />
      </ContentContainer>
    </NavigationItemContainer>
  )
}

export default NavigationItem
