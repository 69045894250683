import { DatabaseCase } from '../../_types/case'
import { FormError } from '../../_types/globals'

/**
 * Validate the details of a case
 *
 * @param {DatabaseCase} caseData The case data to validate
 * @returns {FormError[]} The errors found
 *
 * @example
 * ```ts
 * const errors = validateDetails(caseData)
 * ```
 */
const validate = (caseData: DatabaseCase): FormError[] => {
  const errors: FormError[] = []

  if (!caseData.title || caseData.title === '') {
    errors.push({
      message: 'Title cannot be blank!',
      elementId: 'title',
    })
  }

  if (!caseData.anatomiz3dEngineer || caseData.anatomiz3dEngineer === '') {
    errors.push({
      message: 'Must select an Anatomiz3D Engineer!',
      elementId: 'anatomiz3dEngineer',
    })
  }

  return errors
}

export { validate }
