import styled from 'styled-components'

const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 5px;
  background: none;
  padding: 5px 10px;
  border: 1px solid gray;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  z-index: 1;
`
const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  box-sizing: border-box !important;
`

export { CloseButton, ContentContainer }
