import styled from 'styled-components'

const LoginContainer = styled.div`
  max-width: 500px;
  border: 1px solid gray;
  border-radius: 10px;
  margin: 50px auto;
`

const Logo = styled.img`
  width: 90%;
  margin: 20px auto;
  display: block;
`

const LoginButton = styled.button`
  width: 70%;
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  margin: 20px auto;
`

const LoginImage = styled.img`
  width: 100%;
  transition: filter 0.3s;
  filter: ${props => (props.theme.isVisible ? 'none' : 'grayscale(100%)')};
`
LoginImage.defaultProps = {
  theme: {
    isVisible: true,
  },
}

const TermsContainer = styled.div`
  margin: 20px;
`

export { LoginContainer, Logo, LoginButton, LoginImage, TermsContainer }
