import React, { useState, useCallback, useMemo, useEffect } from 'react'
import Toggle from 'react-toggle'

import InputContainer from '../input-container/InputContainer'
import InputLabel from '../input-label/InputLabel'
import Spacer from '../spacer/Spacer'
import { ToggleInputProps } from './types'
import { ToggleLabel, ToggleContainer } from './styled'

/**
 * A needlessly fancy checkbox re-skin
 *
 * @param {ToggleInputProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <ToggleInput
 *  label="Activate Sale?"
 *  initialValue={activateSale}
 *  callback={newValue => setActivateSale(newValue)}
 * />
 * ```
 */
const ToggleInput = ({
  label,
  buttonText,
  callback,
  elementId,
  display,
  initialValue,
  offsetBottom,
  offsetLeft,
  offsetMode,
  offsetRight,
  offsetTop,
  width,
}: ToggleInputProps): JSX.Element => {
  const [value, setValue] = useState<boolean>(initialValue === true)

  const htmlId = useMemo(() => elementId ?? Date.now().toString(), [elementId])

  const handleChange = useCallback(
    (newValue: boolean) => {
      setValue(newValue)
      callback(newValue)
    },
    [callback],
  )

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <InputContainer
      display={display}
      elementId={htmlId}
      offsetBottom={offsetBottom}
      offsetLeft={offsetLeft}
      offsetRight={offsetRight}
      offsetTop={offsetTop}
      offsetMode={offsetMode}>
      {label !== '' ? (
        <>
          <InputLabel label={label} />
          <Spacer direction="vertical" amount="5px" display="block" />
        </>
      ) : null}
      <ToggleContainer>
        <Toggle
          id={htmlId}
          checked={value}
          onChange={toggleEvent => handleChange(toggleEvent.target.checked)}
        />
      </ToggleContainer>
      {buttonText ? (
        <ToggleLabel htmlFor={htmlId}>{buttonText}</ToggleLabel>
      ) : null}
    </InputContainer>
  )
}

export default ToggleInput
