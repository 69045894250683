import { createContext } from 'react'
import { toast, ToastOptions } from 'react-toastify'

import { ShowNotificationProps } from './types'

/**
 * Display a notification
 *
 * @param {ShowNotificationProps} props
 * @returns {void}
 *
 * ```ts
 * showNotification({
 *  title: 'Successfully added a hoodie to your order!',
 *  type: 'success',
 *  onPress: () => handleNotificationTap(),
 *  dismissAfter: 3500,
 * })
 * ```
 */
const showNotification = ({
  title,
  type,
  onPress,
  autoHide,
  dismissAfter,
}: ShowNotificationProps): void => {
  const notificationOptions: ToastOptions = {
    autoClose: autoHide === false ? false : dismissAfter || 1500,
    closeButton: false,
    onClick: onPress ? () => onPress() : () => null,
    closeOnClick: true,
    pauseOnHover: true,
    type,
    position: toast.POSITION.TOP_CENTER,
  }

  toast(title, notificationOptions)
}

/**
 * Hide all currently-visible notifications
 *
 * @returns {void}
 *
 * ```ts
 * hideNotification()
 * ```
 */
const hideNotification = (): void => {
  toast.dismiss()
}

const NotificationContext = createContext({
  showNotification,
  hideNotification,
})

export { NotificationContext, showNotification, hideNotification }
