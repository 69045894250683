import styled from 'styled-components'

const NavigationItemContainer = styled.button`
  display: block;
  background: ${props => props.theme.background};
  border: none;
  padding: 5px 0;
  margin: 10px 0;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
`
NavigationItemContainer.defaultProps = {
  theme: {
    background: 'none',
  },
}

const ContentContainer = styled.div`
  background-color: ${props => props.theme.colour};
  padding: 10px;
  transition: background-color 0.3s;
`
ContentContainer.defaultProps = {
  theme: {
    colour: 'white',
  },
}

const NavigationIcon = styled.div`
  vertical-align: middle;
  display: inline-block;
  padding-right: 20px;
  width: 50px;
  text-align: center;
`

export { NavigationItemContainer, NavigationIcon, ContentContainer }
