import styled from 'styled-components'

import { colours } from '../../_globals/theme'

const Dropdown = styled.select`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.colour};
  width: ${props => props.theme.width};
  border: 1px solid ${colours.lowlight};
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 5px;
  display: inline-block;
`
Dropdown.defaultProps = {
  theme: {
    background: '#ffffff',
    colour: '#000000',
    width: 'initial',
  },
}

const DropdownOption = styled.option``

export { Dropdown, DropdownOption }
