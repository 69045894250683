import React, { useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import { generateId } from '../../_utilities/utils'
import { Container } from './styled'
import { InputContainerProps } from './types'

/**
 * A container for input elements with commonly needed fields
 *
 * @param {InputContainerProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <InputContainer
 *  elementId={elementId}
 *  display={display}
 *  offsetBottom={offsetBottom}
 *  offsetLeft={offsetLeft}
 *  offsetRight={offsetRight}
 *  offsetTop={offsetTop}
 *  offsetMode={offsetMode}
 * >
 *  <Button />
 * </InputContainer>
 * ```
 */
const InputContainer = ({
  children,
  display,
  elementId,
  offsetBottom,
  offsetLeft,
  offsetRight,
  offsetTop,
  offsetMode,
}: InputContainerProps): JSX.Element => {
  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      display: display || 'block',
      paddingTop: offsetMode === 'padding' && offsetTop ? offsetTop : '0px',
      paddingRight:
        offsetMode === 'padding' && offsetRight ? offsetRight : '0px',
      paddingBottom:
        offsetMode === 'padding' && offsetBottom ? offsetBottom : '0px',
      paddingLeft: offsetMode === 'padding' && offsetLeft ? offsetLeft : '0px',
      marginTop: offsetMode === 'margin' && offsetTop ? offsetTop : '0px',
      marginRight: offsetMode === 'margin' && offsetRight ? offsetRight : '0px',
      marginBottom:
        offsetMode === 'margin' && offsetBottom ? offsetBottom : '0px',
      marginLeft: offsetMode === 'margin' && offsetLeft ? offsetLeft : '0px',
    }

    return theme
  }, [offsetBottom, offsetLeft, offsetRight, offsetTop, offsetMode, display])

  const htmlId = useMemo(() => elementId || generateId(50), [elementId])

  return (
    <Container theme={containerTheme} id={htmlId}>
      {children}
    </Container>
  )
}

export default InputContainer
