import React, { useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import Icon from '../../_assets/images/icon.png'
import SmallAnimationSource from '../../_assets/images/loading/small.svg'
import {
  LargeAnimation,
  LargeAnimationContainer,
  LoadingContainer,
  LoadingText,
  SmallAnimation,
  SmallAnimationContainer,
} from './styled'
import { LoadingProps } from './types'

/**
 * A Loading animation
 *
 * @param {LoadingProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 *  <Loading type="wide" alignment="center" />
 * ```
 */
const Loading = ({ type, alignment }: LoadingProps): JSX.Element => {
  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      height: '50px',
      justify: alignment || 'center',
    }

    switch (type) {
      case 'large':
        theme.height = '500px'
        break
      case 'wide':
        theme.height = '100px'
        break
      case 'text':
        theme.height = '50px'
        break
      default:
        theme.height = '50px'
        break
    }

    return theme
  }, [type, alignment])

  return (
    <LoadingContainer theme={containerTheme}>
      {type === 'text' ? <LoadingText>Loading...</LoadingText> : null}
      {type === 'wide' ? (
        <SmallAnimationContainer>
          <SmallAnimation src={SmallAnimationSource} />
        </SmallAnimationContainer>
      ) : null}
      {type === 'large' ? (
        <LargeAnimationContainer>
          <LargeAnimation src={Icon} />
        </LargeAnimationContainer>
      ) : null}
    </LoadingContainer>
  )
}

export default Loading
