/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import { generateId } from '../../_utilities/utils'
import { TableProps, TableData } from './types'
import {
  ContentContainer,
  RestyledTable,
  Tr,
  Th,
  Td,
  HiddenElement,
  CellData,
  TableContainer,
} from './styled'

/**
 * A generic yet extendable table component
 *
 * @param {TableProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <Table
 *  alignment="center"
 *  headerDirection="horizontal"
 *  size="large"
 *  width="calc(100% - 20px)"
 *  headers={tableHeaders}
 *  content={tableContent}
 * />
 * ```
 */
const Table = ({
  alignment,
  size,
  width,
  tableId,
  headerDirection,
  content,
  headers,
}: TableProps): JSX.Element => {
  const id = useMemo(() => tableId ?? generateId(20), [tableId])

  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      justify: alignment ?? 'left',
      width: width || '1200px',
    }

    return theme
  }, [alignment, width])

  const sanitizedContent = useMemo<TableData[][]>(
    () =>
      content.map(contentPoint =>
        contentPoint.map(dataPoint => {
          let newContent: unknown = dataPoint.content

          if (dataPoint.useHiddenElement === true) {
            newContent = (
              <HiddenElement
                value={dataPoint.content.toString()}
                readOnly={true}
                width="100%"
              />
            )
          } else if (typeof dataPoint.content === 'string') {
            newContent = <CellData>{dataPoint.content}</CellData>
          }

          return { ...dataPoint, content: newContent }
        }),
      ),
    [content],
  )

  return (
    <ContentContainer theme={containerTheme}>
      <TableContainer theme={containerTheme}>
        <RestyledTable>
          {headerDirection === 'horizontal' ? (
            <thead>
              <Tr>
                {headers.map((header, index) => (
                  <Th
                    theme={{
                      justify: header.alignment ?? 'left',
                      maxWidth: header.maxWidth ?? 'initial',
                    }}
                    key={`${id}-header-${index}`}>
                    {header.content}
                  </Th>
                ))}
              </Tr>
            </thead>
          ) : null}
          <tbody>
            {sanitizedContent.map((row, rowIndex) => (
              <Tr key={`${id}-row-${rowIndex}`}>
                {headerDirection === 'vertical' ? (
                  <Th
                    theme={{
                      justify: headers[rowIndex].alignment ?? 'left',
                      maxWidth: headers[rowIndex].maxWidth ?? 'initial',
                    }}>
                    {headers[rowIndex].content}
                  </Th>
                ) : null}
                {row.map((data, columnIndex) => (
                  <Td
                    theme={{ justify: data.alignment ?? 'left' }}
                    key={`${id}-data-${columnIndex}`}>
                    {data.content}
                  </Td>
                ))}
              </Tr>
            ))}
          </tbody>
        </RestyledTable>
      </TableContainer>
    </ContentContainer>
  )
}

export default Table
