import React from 'react'

import { HorizontalRule, VerticalRule } from './styled'
import { RuleProps } from './types'

/**
 * It rules 😎
 *
 * @param {RuleProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <Rule direction="horizontal" />
 * ```
 */
const Rule = ({ direction }: RuleProps): JSX.Element =>
  direction === 'horizontal' ? <HorizontalRule /> : <VerticalRule />

export default Rule
