/* eslint-disable no-restricted-syntax */
import chalk from 'chalk'

import { getEnvironmentSettings } from './config'

/**
 * Log an event
 * Wrapper for console.log/console.error
 *
 * @param {string} file - The file, plus extension
 * @param {string} functionName - The function name
 * @param {('info' | 'error' | 'warn')} type - The type of log
 * @param {string} message - The message to log
 * @param {unknown} [data] - Any additional relevant data
 * @param {boolean} [formatData] - Format the data in a pretty way
 * @returns {void}
 *
 * ```ts
 * log('Product.tsx', 'loadData', 'error', 'Product failed to load!', errorData, true)
 * ```
 */
const log = (
  file: string,
  functionName: string,
  type: 'info' | 'error' | 'warn',
  message: string,
  data?: unknown,
  formatData?: boolean,
): void => {
  let canLog = false
  const environment = getEnvironmentSettings()

  if (type === 'info' && environment.consoleLogLevel >= 3) {
    canLog = true
  } else if (type === 'warn' && environment.consoleLogLevel >= 2) {
    canLog = true
  } else if (type === 'error' && environment.consoleLogLevel >= 1) {
    canLog = true
  }

  if (canLog) {
    console[type](chalk.red(`[${file}: ${functionName}]`))
    console[type](message)

    if (data) {
      if (formatData) {
        const dataString = JSON.stringify(data, null, 4)
        console[type](dataString)
      } else {
        const dataString = JSON.stringify(data)
        console[type](dataString)
      }
    }

    console[type](' ')
  }
}

export { log }
