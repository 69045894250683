import styled from 'styled-components'

const Container = styled.label`
  display: block;
`

const ToggleLabel = styled.label`
  vertical-align: middle;
  display: inline-block;
  height: 25px;
  padding-left: 10px;
`

const ToggleContainer = styled.div`
  vertical-align: middle;
  display: inline-block;
`

export { Container, ToggleLabel, ToggleContainer }
