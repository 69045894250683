import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import { NotificationContext } from '../../../../_globals/notifications/notification-context'
import { useAppDispatch } from '../../../../_globals/hooks'
import { convertRoleToTitle, deepCopy } from '../../../../_utilities/utils'
import { doFunctionsCall } from '../../../../_globals/custom-firebase/custom-firebase'
import { themeSlice } from '../../../../_globals/theme/theme-slice'
import AlignmentContainer from '../../../../components/alignment-container/AlignmentContainer'
import Spacer from '../../../../components/spacer/Spacer'
import Button from '../../../../components/button/Button'
import Loading from '../../../../components/loading/Loading'
import TextInput from '../../../../components/text-input/TextInput'
import PrettyFormContainer from '../../../../components/pretty-form-container/PrettyFormContainer'
import PageError from '../../../../components/page-error/PageError'
import { FormError } from '../../../../_types/globals'
import { scrollElementIntoView } from '../../../../_utilities/dom'
import { DisplayUser } from '../../../../_types/user'
import { DropdownData } from '../../../../components/dropdown-input/types'
import { ApiResponse } from '../../../../_types/api'
import { Section } from '../../styled'
import { validateDetails } from '../../helpers'
import DropdownInput from '../../../../components/dropdown-input/DropdownInput'
import { DatabaseClientUser } from '../../../../_types/client'
import TextElement from '../../../../components/text/Text'
import { DatabaseCase } from '../../../../_types/case'
import { CaseDetailsProps } from './types'

/**
 * Case Details section
 *
 * @returns {JSX.Element}
 *
 * ```tsx
 * <CaseDetailsSection />
 * ```
 */
const CaseDetailsSection = ({
  caseData,
  isApiBusy,
  onDataChange,
  onSaveData,
  onRevertData,
  onApiBusy,
}: CaseDetailsProps): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const dispatch = useAppDispatch()
  const [formError, setFormError] = useState<FormError[]>([])
  const [hasCompletedInitialLoad, setHasCompletedInitialLoad] =
    useState<boolean>(false)
  const [possibleEngineers, setPossibleEngineers] = useState<DisplayUser[]>([])
  const [possiblePatients, setPossiblePatients] = useState<DisplayUser[]>([])
  const [possibleClients, setPossibleClients] = useState<DatabaseClientUser[]>(
    [],
  )
  const [areDescriptionsLinked, setAreDescriptionsLinked] =
    useState<boolean>(false)

  const possibleEngineersDropdownData = useMemo<DropdownData[]>(
    () =>
      possibleEngineers.map(engineer => ({
        id: engineer.id,
        displayValue: `${engineer.displayName} (${convertRoleToTitle(
          engineer.role,
        )})`,
      })),
    [possibleEngineers],
  )

  const possiblePatientsDropdownData = useMemo<DropdownData[]>(
    () =>
      possiblePatients.map(patient => ({
        id: patient.id,
        displayValue: `${patient.displayName} (${patient.email})`,
      })),
    [possiblePatients],
  )

  const possibleClientsDropdownData = useMemo<DropdownData[]>(
    () =>
      possibleClients.map(client => ({
        id: client.id,
        displayValue: `${client.displayName} (${client.email})`,
      })),
    [possibleClients],
  )

  const possibleStatusesDropdownData = useMemo<DropdownData[]>(
    () => [
      { id: 'open', displayValue: 'Open' },
      { id: 'approved', displayValue: 'Approved' },
      { id: 'completed', displayValue: 'Completed' },
    ],
    [],
  )

  const handleFormDataChange = useCallback(
    (attribute: string, newValue: unknown) => {
      const newData = deepCopy(caseData)
      newData[attribute] = newValue

      onDataChange(newData)
    },
    [caseData, onDataChange],
  )

  const handleValidateData = useCallback(
    (showNotifications: boolean) => {
      const errors = validateDetails(caseData)
      setFormError(() => [...errors])

      if (errors.length > 0) {
        if (showNotifications === true) {
          errors.forEach(error => {
            showNotification({
              title: error.message,
              type: 'error',
              dismissAfter: 3500,
              onPress: () => scrollElementIntoView(error.elementId),
            })
          })
        }

        return false
      }

      return true
    },
    [caseData, showNotification],
  )

  const handleSaveClick = useCallback(() => {
    if (isApiBusy === true) {
      showNotification({
        title: 'Working on it!',
        type: 'info',
        dismissAfter: 3000,
      })

      return null
    }

    if (handleValidateData(true) === false) {
      return null
    }

    onApiBusy(true)

    const caseDetailsCopy: DatabaseCase = deepCopy(caseData)
    if (areDescriptionsLinked) {
      caseDetailsCopy.displayCaseDetails = caseDetailsCopy.internalCaseDetails
    }

    doFunctionsCall('Admin', {
      signature: 'Case-Update',
      caseId: caseDetailsCopy.id,
      caseData: caseDetailsCopy,
    })
      .then(data => {
        if (data.code === 200) {
          onSaveData()

          showNotification({
            title: 'Successfully updated case!',
            type: 'success',
            dismissAfter: 3500,
          })
        } else if (data.code === 500) {
          console.error(data)
          showNotification({
            title: data.message,
            type: 'error',
            dismissAfter: 5000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        showNotification({
          title: error.message,
          type: 'error',
          dismissAfter: 5000,
        })
      })
      .finally(() => {
        onApiBusy(false)
      })
  }, [
    isApiBusy,
    handleValidateData,
    onApiBusy,
    caseData,
    areDescriptionsLinked,
    showNotification,
    onSaveData,
  ])

  const handleCopyLinkClick = useCallback(() => {
    const copyText = document.querySelector(
      '#share-link input',
    ) as HTMLInputElement
    copyText.select()
    copyText.setSelectionRange(0, 99_999)

    navigator.clipboard.writeText(copyText.value)

    showNotification({
      title: 'Link copied!',
      type: 'success',
      dismissAfter: 3000,
    })
  }, [showNotification])

  // const generateShareableLink = useCallback(() => {
  //   const id = `${caseData.id.slice(0, 3)}-${generateId(20)}`

  //   handleFormDataChange('shareableLink', id)
  // }, [caseData?.id, handleFormDataChange])

  const handleDescriptionLinkToggle = useCallback(() => {
    setAreDescriptionsLinked(previous => !previous)
  }, [])

  useEffect(() => {
    if (hasCompletedInitialLoad === false) {
      setHasCompletedInitialLoad(true)

      if (caseData.displayCaseDetails === caseData.internalCaseDetails) {
        setAreDescriptionsLinked(true)
      } else {
        setAreDescriptionsLinked(false)
      }

      if (possibleEngineers.length === 0) {
        doFunctionsCall('Admin', {
          signature: 'Case-GetPossibleApplicationEngineers',
        })
          .then((response: ApiResponse) => {
            if (response.code === 200) {
              const engineers: DisplayUser[] = JSON.parse(response.data)
              setPossibleEngineers(() => [...engineers])
            } else if (response.code === 500) {
              console.error(response)

              showNotification({
                title: 'Could not fetch application engineers',
                type: 'error',
                dismissAfter: 3500,
              })
            }
          })
          .catch((error: Error) => {
            console.error(error)

            showNotification({
              title: 'Could not fetch application engineers',
              type: 'error',
              dismissAfter: 3500,
            })
          })
          .finally(() => {
            onApiBusy(false)
          })
      }

      if (possibleClients.length === 0) {
        doFunctionsCall('Admin', {
          signature: 'Client-GetAll',
        })
          .then((response: ApiResponse) => {
            if (response.code === 200) {
              const clients: DatabaseClientUser[] = JSON.parse(response.data)

              setPossibleClients(() => [...clients])
            } else if (response.code === 500) {
              console.error(response)

              showNotification({
                title: 'Could not fetch clients',
                type: 'error',
                dismissAfter: 3500,
              })
            }
          })
          .catch((error: Error) => {
            console.error(error)

            showNotification({
              title: 'Could not fetch clients',
              type: 'error',
              dismissAfter: 3500,
            })
          })
          .finally(() => {
            onApiBusy(false)
          })
      }

      if (possiblePatients.length === 0) {
        doFunctionsCall('Admin', {
          signature: 'User-GetAll',
        })
          .then((response: ApiResponse) => {
            if (response.code === 200) {
              const patients: DisplayUser[] = JSON.parse(response.data)

              setPossiblePatients(() => [...patients])
            } else if (response.code === 500) {
              console.error(response)

              showNotification({
                title: 'Could not fetch patients',
                type: 'error',
                dismissAfter: 3500,
              })

              return null
            }
          })
          .catch((error: Error) => {
            console.error(error)

            showNotification({
              title: 'Could not fetch patients',
              type: 'error',
              dismissAfter: 3500,
            })
          })
          .finally(() => {
            onApiBusy(false)
          })
      }
    }
  }, [
    possibleEngineers.length,
    showNotification,
    onApiBusy,
    possibleClients.length,
    possiblePatients.length,
    hasCompletedInitialLoad,
    caseData?.displayCaseDetails,
    caseData?.internalCaseDetails,
  ])

  useEffect(() => {
    dispatch(themeSlice.actions.setShowSidebar(true))
    dispatch(themeSlice.actions.setAllowNotifications(true))
  }, [dispatch])

  return (
    <Section>
      {caseData ? (
        <>
          <PrettyFormContainer
            title="Title"
            description={[]}
            formId="title"
            formContent={
              <TextInput
                value={caseData.title}
                onTextChange={newValue =>
                  handleFormDataChange('title', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Case ID"
            description={[
              'This is an internal, read-only identifier for each case.',
              'This ID is visible for reference only and cannot be edited.',
            ]}
            formId="friendlyId"
            formContent={
              <TextInput value={caseData.friendlyId} isDisabled={true} />
            }
          />
          <PrettyFormContainer
            title="Status"
            description={[]}
            formId="status"
            formContent={
              <DropdownInput
                data={possibleStatusesDropdownData}
                initialValue={caseData.status}
                unselectedDisplayValue="Select a status"
                width="100%"
                onChange={newValue => handleFormDataChange('status', newValue)}
              />
            }
          />
          <PrettyFormContainer
            title="Anatomiz3D Application Engineer"
            description={[
              'The Anatomiz3D Application Engineer assigned to this case.',
            ]}
            formId="anatomiz3dEngineer"
            formContent={
              <DropdownInput
                data={possibleEngineersDropdownData}
                initialValue={caseData.anatomiz3dEngineer}
                unselectedDisplayValue="Select an engineer"
                width="100%"
                onChange={newValue =>
                  handleFormDataChange('anatomiz3dEngineer', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Assigned Client"
            description={[]}
            formId="clientId"
            formContent={
              <DropdownInput
                data={possibleClientsDropdownData}
                initialValue={caseData.clientId}
                unselectedDisplayValue="Select a Client"
                width="100%"
                onChange={newValue =>
                  handleFormDataChange('clientId', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Assigned Patient"
            description={['Optional']}
            formId="forPatient"
            formContent={
              <DropdownInput
                data={possiblePatientsDropdownData}
                initialValue={caseData.forPatient}
                unselectedDisplayValue="Select a Patient"
                width="100%"
                onChange={newValue =>
                  handleFormDataChange('forPatient', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Anatomiz3D Case ID"
            description={['The Anatomiz3D Case ID for this case.']}
            formId="anatomiz3dId"
            formContent={
              <TextInput
                value={caseData.anatomiz3dId}
                onTextChange={newValue =>
                  handleFormDataChange('anatomiz3dId', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Client Reference ID"
            description={[]}
            formId="institutionId"
            formContent={
              <TextInput
                value={caseData.institutionId}
                onTextChange={newValue =>
                  handleFormDataChange('institutionId', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Shareable Link"
            description={[]}
            formId="shareableLink"
            formContent={
              <>
                <TextInput
                  elementId="share-link"
                  isDisabled={true}
                  value={
                    caseData.shareableLink === ''
                      ? ''
                      : `https://anatomiz3d.web.app/case/${caseData.id}`
                  }
                  onTextChange={newValue =>
                    handleFormDataChange('shareableLink', newValue)
                  }
                />
                <Spacer direction="vertical" amount="10px" display="block" />
                <AlignmentContainer align="center" display="block">
                  <Button
                    callback={() => handleCopyLinkClick()}
                    text="Copy Link"
                    theme="secondary"
                  />
                </AlignmentContainer>
              </>
            }
          />
          {/* <PrettyFormContainer
            title="Shareable Link"
            description={[]}
            formId="shareableLink"
            formContent={
              <>
                <TextInput
                  isDisabled={true}
                  value={
                    caseData.shareableLink === ''
                      ? ''
                      : `https://anatomiz3d.com/case/${caseData.shareableLink}`
                  }
                  onTextChange={newValue =>
                    handleFormDataChange('shareableLink', newValue)
                  }
                />
                <Spacer direction="vertical" amount="10px" display="block" />
                <AlignmentContainer align="center" display="block">
                  <Button
                    callback={() => generateShareableLink()}
                    text="Generate Link"
                    theme="secondary"
                  />
                </AlignmentContainer>
              </>
            }
          /> */}
          <PrettyFormContainer
            title={
              areDescriptionsLinked ? 'Case Details' : 'Internal Case Details'
            }
            description={[]}
            formId="internalCaseDetails"
            formContent={
              <>
                <TextInput
                  value={caseData.internalCaseDetails}
                  numberOfLines={10}
                  onTextChange={newValue =>
                    handleFormDataChange('internalCaseDetails', newValue)
                  }
                />
                {areDescriptionsLinked ? (
                  <>
                    <Spacer
                      direction="vertical"
                      amount="20px"
                      display="block"
                    />
                    <TextElement
                      text={[
                        'Depending on the case, you may want to have a different description visible to the user.',
                        'To do so, click the "Separate Details" button below.',
                      ].join(' ')}
                      theme="paragraph"
                    />
                    <Spacer
                      direction="vertical"
                      amount="10px"
                      display="block"
                    />
                    <AlignmentContainer align="center" display="block">
                      <Button
                        text="Separate Details"
                        callback={() => handleDescriptionLinkToggle()}
                        theme="secondary"
                      />
                    </AlignmentContainer>
                  </>
                ) : null}
              </>
            }
          />
          {!areDescriptionsLinked ? (
            <PrettyFormContainer
              title="Display Case Details"
              description={[]}
              formId="displayCaseDetails"
              formContent={
                <>
                  {!areDescriptionsLinked ? (
                    <>
                      <TextElement
                        text={[
                          'This case currently has separate descriptions. If there is no need for this, you can link the descriptions',
                          'To do so, click the "Link Details" button below.',
                        ].join(' ')}
                        theme="paragraph"
                      />
                      <Spacer
                        direction="vertical"
                        amount="10px"
                        display="block"
                      />
                      <AlignmentContainer align="center" display="block">
                        <Button
                          text="Link Details"
                          callback={() => handleDescriptionLinkToggle()}
                          theme="secondary"
                        />
                      </AlignmentContainer>
                      <Spacer
                        direction="vertical"
                        amount="20px"
                        display="block"
                      />
                    </>
                  ) : null}
                  <TextInput
                    value={
                      areDescriptionsLinked
                        ? caseData.internalCaseDetails
                        : caseData.displayCaseDetails
                    }
                    numberOfLines={10}
                    onTextChange={newValue =>
                      handleFormDataChange('displayCaseDetails', newValue)
                    }
                  />
                </>
              }
            />
          ) : null}
          <Spacer direction="vertical" amount="20px" display="block" />
          <PageError
            data={formError}
            revalidateCallback={() => handleValidateData(false)}
          />
          <AlignmentContainer align="center" display="block">
            <Button
              callback={() => onRevertData()}
              text="Revert"
              theme="flair"
              display="inline-block"
              size="small"
              isDisabled={isApiBusy}
            />
            <Spacer
              direction="horizontal"
              amount="20px"
              display="inline-block"
            />
            <Button
              callback={() => handleSaveClick()}
              text="Save"
              theme="flair"
              display="inline-block"
              size="large"
              isDisabled={isApiBusy}
            />
          </AlignmentContainer>
        </>
      ) : (
        <Loading type="large" />
      )}
      <Spacer direction="vertical" amount="20px" display="block" />
    </Section>
  )
}

export default CaseDetailsSection
