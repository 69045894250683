import React, { useCallback, useMemo } from 'react'

import { scrollElementIntoView } from '../../_utilities/dom'
import AlignmentContainer from '../alignment-container/AlignmentContainer'
import Button from '../button/Button'
import Spacer from '../spacer/Spacer'
import Table from '../table/Table'
import { TableData } from '../table/types'
import TextElement from '../text/Text'
import { PageErrorProps } from './types'

/**
 * A user friendly (yes it is, randy) way to display errors to users in a tabular format
 *
 * @param {PageErrorProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <PageError
 *  data={pageErrors}
 *  revalidateCallback={() => handleValidateData(false)}
 * />
 * ```
 */
const PageError = ({
  data,
  customCallback,
  revalidateCallback,
}: PageErrorProps): JSX.Element => {
  const handleClick = useCallback(
    (elementId: string) => {
      if (customCallback) {
        customCallback(elementId)
      } else {
        scrollElementIntoView(elementId)
      }
    },
    [customCallback],
  )

  const tableHeaders = useMemo<TableData[]>(
    () => [
      {
        content: 'Error Message',
        alignment: 'center',
      },
      {
        content: 'Action',
        alignment: 'center',
      },
    ],
    [],
  )

  const tableData = useMemo<TableData[][]>(() => {
    const formattedData: TableData[][] = []

    data.forEach(dataPoint => {
      const row: TableData[] = [
        {
          content: (
            <TextElement
              text={dataPoint.message}
              theme="paragraph"
              alignment="left"
              colour="black"
            />
          ),
        },
        {
          content: (
            <AlignmentContainer align="center" display="block">
              <Button
                callback={() => handleClick(dataPoint.elementId)}
                text="Go To Error"
                theme="error"
              />
            </AlignmentContainer>
          ),
        },
      ]

      formattedData.push(row)
    })

    return formattedData
  }, [data, handleClick])

  return data.length > 0 ? (
    <>
      <Spacer direction="vertical" amount="20px" display="block" />
      <TextElement
        text="Errors"
        theme="h1"
        alignment="center"
        display="block"
        colour="white"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      <Table
        alignment="center"
        headers={tableHeaders}
        content={tableData}
        headerDirection="horizontal"
        width="600px"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      <AlignmentContainer align="center" display="block">
        <Button
          callback={() => revalidateCallback()}
          text="Validate Fixes"
          theme="main"
        />
      </AlignmentContainer>
      <Spacer direction="vertical" amount="50px" display="block" />
    </>
  ) : null
}

export default PageError
