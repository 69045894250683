import React, { useContext, useState, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { NotificationContext } from '../../_globals/notifications/notification-context'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import PageContainer from '../../components/page-container/PageContainer'
import PageBackButton from '../../components/page-back-button/PageBackButton'
import TextElement from '../../components/text/Text'
import Spacer from '../../components/spacer/Spacer'
import Button from '../../components/button/Button'
import Loading from '../../components/loading/Loading'
import TextInput from '../../components/text-input/TextInput'
import PrettyFormContainer from '../../components/pretty-form-container/PrettyFormContainer'
import PageError from '../../components/page-error/PageError'
import { FormError } from '../../_types/globals'
import { scrollElementIntoView } from '../../_utilities/dom'
import { DatabaseCase } from '../../_types/case'
import { defaultDatabaseCase } from '../../_defaults/case'
import { DisplayUser } from '../../_types/user'
import { ApiResponse } from '../../_types/api'
import { convertRoleToTitle } from '../../_utilities/utils'
import DropdownInput from '../../components/dropdown-input/DropdownInput'
import { DropdownData } from '../../components/dropdown-input/types'
import { validate } from './helpers'

/**
 * New Case page
 *
 * @returns {JSX.Element}
 *
 * ```tsx
 * <NewCase />
 * ```
 */
const NewCase = (): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const navigation = useNavigate()
  const [formErrors, setFormErrors] = useState<FormError[]>([])
  const [criticalError, setCriticalError] = useState<string>('')
  const [caseData, setCaseData] = useState<DatabaseCase>({
    ...defaultDatabaseCase,
  })
  const [possibleEngineers, setPossibleEngineers] = useState<DisplayUser[]>([])
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)

  const possibleEngineersDropdownData = useMemo<DropdownData[]>(
    () =>
      possibleEngineers.map(engineer => ({
        id: engineer.id,
        displayValue: `${engineer.displayName} (${convertRoleToTitle(
          engineer.role,
        )})`,
      })),
    [possibleEngineers],
  )

  const handlePageReady = useCallback(() => {
    setIsApiBusy(true)

    doFunctionsCall('Admin', {
      signature: 'Case-GetPossibleApplicationEngineers',
    })
      .then((response: ApiResponse) => {
        if (response.code === 200) {
          const engineers: DisplayUser[] = JSON.parse(response.data)
          setPossibleEngineers(() => [...engineers])
        } else if (response.code === 500) {
          console.error(response)

          showNotification({
            title: 'Could not fetch application engineers',
            type: 'error',
            dismissAfter: 3500,
          })

          setCriticalError('Could not fetch application engineers')
        }
      })
      .catch((error: Error) => {
        console.error(error)

        showNotification({
          title: 'Could not fetch application engineers',
          type: 'error',
          dismissAfter: 3500,
        })

        setCriticalError('Could not fetch application engineers')
      })
      .finally(() => {
        setIsApiBusy(false)
      })
  }, [showNotification])

  const handleFormDataChange = useCallback(
    (attribute: string, newValue: unknown) => {
      setCaseData(previous => ({ ...previous, [attribute]: newValue }))
    },
    [],
  )

  const handleValidateData = useCallback(
    (showNotifications: boolean) => {
      const errors = validate(caseData)
      setFormErrors(() => [...errors])

      if (errors.length > 0) {
        if (showNotifications === true) {
          errors.forEach(error => {
            showNotification({
              title: error.message,
              type: 'error',
              dismissAfter: 3500,
              onPress: () => scrollElementIntoView(error.elementId),
            })
          })
        }

        return false
      }

      return true
    },
    [caseData, showNotification],
  )

  const handleSaveClick = useCallback(() => {
    if (isApiBusy === true) {
      showNotification({
        title: 'Working on it!',
        type: 'info',
        dismissAfter: 3000,
      })

      return null
    }

    if (handleValidateData(true) === false) {
      return null
    }

    setIsApiBusy(true)

    doFunctionsCall('Admin', {
      signature: 'Case-Create',
      caseData,
    })
      .then(data => {
        if (data.code === 200) {
          showNotification({
            title: 'Successfully created case!',
            type: 'success',
            dismissAfter: 3500,
          })

          navigation(`/case/${data.data}`)
        } else if (data.code === 500) {
          console.error(data)
          showNotification({
            title: data.message,
            type: 'error',
            dismissAfter: 5000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        showNotification({
          title: 'Could not create case',
          type: 'error',
          dismissAfter: 5000,
        })
      })
  }, [isApiBusy, handleValidateData, caseData, showNotification, navigation])

  return (
    <PageContainer
      height="initial"
      width="initial"
      offsetBottom="10px"
      offsetLeft="10px"
      offsetRight="10px"
      offsetTop="10px"
      offsetMode="padding"
      allowedRoles={['super-admin', 'admin', 'designer']}
      allowNotifications={true}
      showSidebar={true}
      pageError={criticalError}
      allowUnauthenticated={false}
      onPageReady={() => handlePageReady()}>
      <PageBackButton
        text="Back to Cases"
        colourMode="light"
        urlOnClick="/cases"
      />
      <TextElement
        text="New Case"
        theme="h1"
        alignment="center"
        colour="black"
        display="block"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      {caseData ? (
        <>
          <PrettyFormContainer
            title="Title"
            description={[]}
            formId="title"
            formContent={
              <TextInput
                initialValue={caseData.title}
                onTextChange={newValue =>
                  handleFormDataChange('title', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Anatomiz3D Application Engineer"
            description={[
              'The Anatomiz3D Application Engineer assigned to this case.',
            ]}
            formId="anatomiz3dEngineer"
            formContent={
              <DropdownInput
                data={possibleEngineersDropdownData}
                unselectedDisplayValue="Select an engineer"
                width="100%"
                onChange={newValue =>
                  handleFormDataChange('anatomiz3dEngineer', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Anatomiz3D Internal ID"
            description={['The Anatomiz3D internal ID for this case.']}
            formId="anatomiz3dId"
            formContent={
              <TextInput
                onTextChange={newValue =>
                  handleFormDataChange('anatomiz3dId', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Institution Internal ID"
            description={[]}
            formId="institutionId"
            formContent={
              <TextInput
                onTextChange={newValue =>
                  handleFormDataChange('institutionId', newValue)
                }
              />
            }
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <PageError
            data={formErrors}
            revalidateCallback={() => handleValidateData(false)}
          />
          <AlignmentContainer align="center" display="block">
            <Button
              callback={() => handleSaveClick()}
              text="Create New Case"
              theme="flair"
              display="inline-block"
              size="large"
              isDisabled={isApiBusy}
            />
          </AlignmentContainer>
        </>
      ) : (
        <Loading type="large" />
      )}
      <Spacer direction="vertical" amount="20px" display="block" />
    </PageContainer>
  )
}

export default NewCase
