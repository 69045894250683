import styled, { keyframes } from 'styled-components'

import { font, fontSize } from '../../_globals/theme'

const fadeAnimation = keyframes`
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.3;
  }
  100%{
    opacity: 1;
  }
`

const LoadingContainer = styled.div`
  text-align: ${props => props.theme.justify};
  width: 100%;
`
LoadingContainer.defaultProps = {
  theme: {
    justify: 'center',
  },
}

const LoadingText = styled.p`
  font-family: ${font.regular};
  font-size: ${fontSize.regular};
  width: 100%;
`

const LargeAnimationContainer = styled.div`
  height: 70vh;
  width: 100%;
  min-width: 100%;
  position: relative;
`
const LargeAnimation = styled.img`
  max-width: 200px;
  max-height: 200px;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${fadeAnimation} 2s cubic-bezier(0.58, -0.15, 0.32, 1.15) infinite;
`

const SmallAnimationContainer = styled.div`
  width: 100%;
  min-width: 100%;
`
const SmallAnimation = styled.img`
  width: 100%;
  max-width: 150px;
`

export {
  LoadingContainer,
  LargeAnimationContainer,
  LoadingText,
  SmallAnimationContainer,
  LargeAnimation,
  SmallAnimation,
}
