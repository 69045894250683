import React, { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router'

import AlignmentContainer from '../../../../components/alignment-container/AlignmentContainer'
import Button from '../../../../components/button/Button'
import CheckboxInput from '../../../../components/checkbox-input/CheckboxInput'
import PrettyFormContainer from '../../../../components/pretty-form-container/PrettyFormContainer'
import Spacer from '../../../../components/spacer/Spacer'
import TextInput from '../../../../components/text-input/TextInput'
import TextElement from '../../../../components/text/Text'
import { doFunctionsCall } from '../../../../_globals/custom-firebase/custom-firebase'
import { NotificationContext } from '../../../../_globals/notifications/notification-context'
import { Section } from '../../styled'
import { CaseArchiveProps } from './types'

/**
 * The CaseArchive component of the Case page.
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <CaseArchive
 *  caseData={caseData}
 *  isApiBusy={isApiBusy}
 *  onApiBusy={onApiBusy}
 * />
 * ```
 */
const CaseArchive = ({
  caseData,
  isApiBusy,
  onApiBusy,
}: CaseArchiveProps): JSX.Element => {
  const navigation = useNavigate()
  const { showNotification } = useContext(NotificationContext)
  const [hasConfirmed, setHasConfirmed] = useState<boolean>(false)
  const [archiveReason, setArchiveReason] = useState<string>('')

  const handleArchiveClick = useCallback(() => {
    if (isApiBusy) {
      showNotification({
        title: 'Please wait for the current action to complete.',
        dismissAfter: 3000,
        type: 'error',
      })

      return null
    } else if (archiveReason === '') {
      showNotification({
        title: 'Please enter a reason for archiving this case.',
        dismissAfter: 3000,
        type: 'error',
      })

      return null
    } else if (hasConfirmed === false) {
      showNotification({
        title: 'Please confirm that you want to archive this case.',
        dismissAfter: 3000,
        type: 'error',
      })

      return null
    }

    onApiBusy(true)

    doFunctionsCall('Admin', {
      signature: 'Case-Archive',
      caseId: caseData.id,
      anatomiz3dEngineer: caseData.anatomiz3dEngineer,
      archiveReason,
    })
      .then(data => {
        if (data.code === 200) {
          showNotification({
            title: 'Case archived successfully.',
            dismissAfter: 3000,
            type: 'success',
          })

          navigation('/cases')
        } else if (data.code === 500) {
          showNotification({
            title: 'Error archiving case.',
            dismissAfter: 3000,
            type: 'error',
          })

          onApiBusy(false)
        }
      })
      .catch((error: Error) => {
        console.error(error)
        showNotification({
          title: 'Error archiving case.',
          dismissAfter: 3000,
          type: 'error',
        })

        onApiBusy(false)
      })
  }, [
    archiveReason,
    caseData.anatomiz3dEngineer,
    caseData.id,
    hasConfirmed,
    isApiBusy,
    navigation,
    onApiBusy,
    showNotification,
  ])

  return (
    <Section>
      <TextElement
        text="Archive Case"
        theme="h2"
        alignment="center"
        display="block"
      />
      <PrettyFormContainer
        title="Archive Reason"
        description={[]}
        formId="archiveReason"
        formContent={
          <TextInput
            initialValue={archiveReason}
            numberOfLines={5}
            onTextChange={newValue => setArchiveReason(newValue)}
          />
        }
      />
      <AlignmentContainer align="center" display="block">
        <CheckboxInput
          callback={() => setHasConfirmed(!hasConfirmed)}
          checkboxText="I confirm that I want to archive this case."
          checkboxIdentifier="archiveCase"
        />
        <Spacer direction="vertical" amount="20px" display="block" />
        <Button
          text="Archive Case"
          theme="flair"
          callback={() => handleArchiveClick()}
        />
      </AlignmentContainer>
    </Section>
  )
}

export default CaseArchive
