import { CaseStatus } from '../../_types/globals'

/**
 * Convert a case status to a title
 *
 * @param {CaseStatus} caseStatus The case status to convert
 * @returns {string} The converted case status
 *
 * @example
 * ```ts
 * const title = convertStatusToTitle('open')
 * ```
 */
const convertStatusToTitle = (caseStatus: CaseStatus) => {
  if (caseStatus === 'open') {
    return 'Open'
  } else if (caseStatus === 'approved') {
    return 'Approved'
  } else if (caseStatus === 'completed') {
    return 'Completed'
  }

  return 'Open'
}

export { convertStatusToTitle }
