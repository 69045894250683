import styled from 'styled-components'

import { colours, shadow } from '../../../../../../_globals/theme'

const ModelSettings = styled.div`
  margin: 10px 10px 0 5px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: ${shadow.main};
`

const LabelContainer = styled.div`
  display: flex;
  margin-top: 10px;
`

const Label = styled.label`
  flex: 1;
`

const LabelInput = styled.input`
  padding: 2px 5px;
  width: 75px;
  margin-right: 10px;
  border: 1px solid ${colours.lowlight};
  border-radius: 3px;
`

export { ModelSettings, LabelContainer, Label, LabelInput }
