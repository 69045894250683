import styled from 'styled-components'
import { colours } from '../../../../_globals/theme'

const LogContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  height: 100%;
`

const LogEntry = styled.div`
  margin: 20px 0;
  padding: 10px;
  border-left: 2px solid ${colours.main};
  word-break: break-all;

  & p {
    white-space: pre-wrap;
  }
`

const LogTimestamp = styled.p`
  margin: 0 0 10px 0;
  font-size: 0.8rem;
  color: ${colours.lowlight};
`

const LogTriggeredBy = styled.p`
  font-style: italic;
  margin-top: 15px;
  font-size: 0.8rem;
`

export { LogContainer, LogEntry, LogTimestamp, LogTriggeredBy }
