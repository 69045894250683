import { configureStore } from '@reduxjs/toolkit'

import userReducer from './user/user-slice'
import themeReducer from './theme/theme-slice'

const stateStore = configureStore({
  reducer: {
    user: userReducer,
    theme: themeReducer,
  },
})

type RootState = ReturnType<typeof stateStore.getState>
type AppDispatch = typeof stateStore.dispatch

export type { RootState, AppDispatch }

export { stateStore }
