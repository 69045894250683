/* eslint-disable unicorn/prefer-spread */

/**
 * Get the colour name based on the RGB value
 *
 * @param {string} rgb The RGB value of the colour
 * @returns {string} The name of the colour
 *
 * @example
 * ```ts
 * const colourName = tooltipFactory('rgb(239, 225, 206)')
 * ```
 */
const tooltipFactory = (rgb: string) => {
  const sanitizedRgb = rgb.replace(/[A-Z\s()]/giu, '')
  let colourName = ''

  switch (sanitizedRgb) {
    case '239,225,206':
      colourName = 'Bone'
      break
    case '192,18,18':
      colourName = 'Tumor'
      break
    case '126,0,0':
      colourName = 'Artery'
      break
    case '63,39,131':
      colourName = 'Vein'
      break
    case '103,101,106':
      colourName = 'Implant'
      break
    case '240,136,233':
      colourName = 'Screws'
      break
    case '250,216,204':
      colourName = 'Skin'
      break
    case '117,83,74':
      colourName = 'Soft Tissue'
      break
    default:
      colourName = rgb
  }

  return colourName
}

/**
 * Insert tooltips into the colour picker
 *
 * @returns {void}
 *
 * @example
 * ```ts
 * insertTooltips()
 * ```
 */
const insertTooltips = () => {
  document.querySelectorAll('.colour-tooltip').forEach(tooltip => {
    tooltip.remove()
  })

  const colourPickers = Array.from(
    document.getElementsByClassName('sketch-picker'),
  ) as HTMLDivElement[]

  for (const colourPicker of colourPickers) {
    const swatches = Array.from(
      colourPicker.querySelectorAll('.flexbox-fix:last-of-type div span div'),
    ) as HTMLDivElement[]

    for (const swatch of swatches) {
      const tooltip = document.createElement('div')
      tooltip.classList.add('colour-tooltip')
      tooltip.innerHTML = tooltipFactory(swatch.style.backgroundColor)
      swatch.append(tooltip)

      swatch.classList.add('tooltip-container')
    }
  }
}

export { insertTooltips }
