/**
 * Scroll a dom element into the viewport
 *
 * @param {string} elementId - Element ID
 * @returns {void}
 *
 * ```ts
 * scrollElementIntoView('checkout-email')
 * ```
 */
const scrollElementIntoView = (elementId: string) => {
  try {
    const element = document.getElementById(elementId)

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * Gently scroll to the top of the page
 *
 * @returns {void}
 *
 * ```ts
 * toTop()
 * ```
 */
const toTop = () => {
  try {
    window.scroll(0, 0)
  } catch (error) {
    console.error(error)
  }
}

/**
 * Set page header data
 *
 * @param {string} title - Tab title
 * @param {string} description - Meta description (SEO)
 * @param {boolean} [addLaunchpad] - Add a launchpad flair to the description
 * @param {string} [thumbnail] - Page thumbnail
 * @returns {void}
 *
 * ```ts
 * setPageData(product.name, product.description, true, product.featureImage)
 * ```
 */
const setPageData = (
  title: string,
  description: string,
  addLaunchpad?: boolean,
  thumbnail?: string,
) => {
  const titleElement: HTMLTitleElement =
    document.getElementsByTagName('title')[0]
  const descriptionElement: HTMLMetaElement = document.querySelector(
    'meta[name=description]',
  )
  const thumbnailElement: HTMLMetaElement = document.querySelector(
    'meta[name=thumbnail]',
  )

  titleElement.textContent = `${title}${
    title.toLowerCase() === 'launchpad' ? '' : ' - Launchpad'
  }`

  descriptionElement.content = `${description}${
    addLaunchpad === true
      ? ' - Available on Launchpad, a product by Northern Devs'
      : ''
  }`

  if (thumbnail) {
    thumbnailElement.content = thumbnail
  }
}

/**
 * Scroll to the bottom of a dom element
 *
 * @param {string} elementId - Element ID
 * @returns {void}
 *
 * @example
 * ```ts
 * scrollToBottom('checkout-email')
 * ```
 */
const scrollToBottom = (elementId: string) => {
  try {
    const element = document.getElementById(elementId)
    element.scrollTop = element.scrollHeight
  } catch (error) {
    console.error(error)
  }
}

export { scrollElementIntoView, toTop, setPageData, scrollToBottom }
