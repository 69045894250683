/* eslint-disable id-length */
import styled from 'styled-components'
import { colours, font, shadow } from '../../_globals/theme'

const ContentContainer = styled.div`
  display: block;
  text-align: ${props => props.theme.justify};
  width: 100%;
`
ContentContainer.defaultProps = {
  theme: {
    justify: 'left',
  },
}

const TableContainer = styled.div`
  border: 1px solid ${colours.lowlight};
  border-radius: 10px;
  max-width: ${props => props.theme.width};
  margin-left: auto;
  margin-right: auto;
  box-shadow: ${shadow.main};
  overflow-x: auto;
`
TableContainer.defaultProps = {
  theme: {
    width: '1300px',
  },
}

const RestyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
`

const Th = styled.th`
  text-align: ${props => props.theme.justify};
  font-family: ${font.bold};
  border: 2px solid ${colours.lowlight};
  border-left: none;
  border-right: none;
  padding: 15px;
`
Th.defaultProps = {
  theme: {
    justify: 'center',
  },
}

const Td = styled.td`
  text-align: ${props => props.theme.justify};
  border: 1px solid ${colours.lowlight};
  border-left: none;
  border-right: none;
  padding: 10px 15px;
`
Td.defaultProps = {
  theme: {
    justify: 'center',
  },
}

const Tr = styled.tr`
  &:first-of-type ${Th} {
    border-top: none;
  }

  &:last-of-type ${Td} {
    border-bottom: none;
  }

  & ${Td}:first-of-type {
    border-left: none;
  }
  & ${Th}:first-of-type {
    border-left: none;
  }

  & ${Td}:last-of-type {
    border-right: none;
  }
  & ${Th}:last-of-type {
    border-right: none;
  }

  &:first-of-type ${Th}:first-of-type {
    border-top-left-radius: 10px;
  }

  &:first-of-type ${Th}:last-of-type {
    border-top-right-radius: 10px;
  }

  &:last-of-type ${Td}:first-of-type {
    border-bottom-left-radius: 10px;
  }

  &:last-of-type ${Td}:last-of-type {
    border-bottom-right-radius: 10px;
  }
`

const CellData = styled.p`
  margin: 0;
  padding: 0;
`

const HiddenElement = styled.input`
  border: none;
  background: none;
  width: 100%;
  outline: none !important;
`

export {
  ContentContainer,
  RestyledTable,
  Tr,
  Th,
  Td,
  CellData,
  HiddenElement,
  TableContainer,
}
