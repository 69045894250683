import React from 'react'

import { Container } from './styled'
import { MainThemerProps } from './types'

/**
 * A container to hold the main theme of the website
 *
 * @param {MainThemerProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <MainThemer>
 *  <App />
 * </MainThemer>
 * ```
 */
const MainThemer = ({ children }: MainThemerProps): JSX.Element => (
  <Container>{children}</Container>
)

export default MainThemer
