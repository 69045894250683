import { DatabaseComment } from '../_types/comment'

const defaultDatabaseComment: DatabaseComment = {
  id: '',
  caseId: '',
  visibleToPatient: false,
  comment: '',
  replyToCommentId: '',
  photoIds: [],
  authorId: '',
  createdTimestamp: Date.now(),
  userDisplayName: '',
}

export { defaultDatabaseComment }
