import React, { useCallback, useState } from 'react'

import Button from '../button/Button'
import Modal from '../modal/Modal'
import TextElement from '../text/Text'
import Spacer from '../spacer/Spacer'
import { DeletePopUpProps } from './types'
import { ButtonContainer } from './styled'

const DeletePopUp = ({ onDelete }: DeletePopUpProps): JSX.Element => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleDeleteButtonClick = useCallback(() => {
    onDelete()
  }, [onDelete])

  return (
    <>
      <Button
        theme="main"
        text="Delete"
        display="inline-block"
        callback={() => setIsDeleteModalOpen(true)}
      />

      <Modal
        id="deletePopUp"
        title="Delete Model"
        isOpen={isDeleteModalOpen === true}
        onClose={() => setIsDeleteModalOpen(false)}
        allowBackgroundClick={true}
        showCloseButton={false}
        content={
          <>
            <TextElement
              theme="paragraph"
              colour="black"
              text={`Are you sure you want to delete the model? All of the data tied
              with the model will be removed permanently.`}
            />

            <Spacer direction="vertical" amount="30px" display="block" />
            <ButtonContainer>
              <Button
                callback={() => setIsDeleteModalOpen(false)}
                text="Cancel & Close"
                theme="main"
              />
              <Button
                callback={() => handleDeleteButtonClick()}
                text="Confirm & Delete"
                theme="main"
              />
            </ButtonContainer>
          </>
        }
      />
    </>
  )
}

export default DeletePopUp
