import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { StyledFirebaseAuth } from 'react-firebaseui'
import firebaseui from 'firebaseui'
import {
  getAuth,
  EmailAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth'

import LogoImage from '../../_assets/images/logo.png'
import PageContainer from '../../components/page-container/PageContainer'
import { NotificationContext } from '../../_globals/notifications/notification-context'
import Rule from '../../components/rule/Rule'
import TextInput from '../../components/text-input/TextInput'
import CheckboxInput from '../../components/checkbox-input/CheckboxInput'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'
import { useAppDispatch } from '../../_globals/hooks'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import { GenericObject } from '../../_types/globals'
import { userSlice } from '../../_globals/user/user-slice'
import { DatabaseUser } from '../../_types/user'
import { LoginContainer, Logo, TermsContainer } from './styled'

/**
 * The Login page.
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <Login />
 * ```
 */
const Login = (): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const navigation = useNavigate()
  const appDispatch = useAppDispatch()
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState<boolean>(false)
  const [termsOfService, setTermsOfService] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)

  const firebaseAuth = useMemo(() => {
    const localFirebaseAuth = getAuth()
    setPersistence(localFirebaseAuth, browserLocalPersistence)

    return localFirebaseAuth
  }, [])

  const handleSignInSuccess = useCallback(
    authResult => {
      if (isApiBusy === true) {
        showNotification({
          title: 'Loading, please try again in a moment',
          type: 'error',
          dismissAfter: 2500,
        })

        return false
      }

      setIsApiBusy(true)

      const user: GenericObject = {
        displayName: authResult?.user?.auth?.currentUser?.displayName ?? '',
        email: authResult?.user?.auth?.currentUser?.email ?? '',
        phone: '',
        role: 'user',
        loginProviderId: 'email',
      }

      doFunctionsCall('HighestPriority', {
        signature: 'User-Create',
        user,
        from: 'admin',
      })
        .then(result => {
          if (result.code === 200) {
            const databaseUser: DatabaseUser = JSON.parse(result.data)
            appDispatch(userSlice.actions.saveUser(databaseUser))

            showNotification({
              title: 'Welcome!',
              type: 'success',
              dismissAfter: 1500,
            })

            navigation('/')
          } else if (result.code === 500) {
            console.error(result)

            setIsApiBusy(false)

            if (result.data === 'not-added') {
              showNotification({
                title:
                  'No account. Please email support@anatomiz3d.com for assistance',
                dismissAfter: 2500,
                type: 'error',
              })
            } else {
              showNotification({
                title: 'An error has occurred, please try again',
                type: 'error',
                dismissAfter: 3000,
              })
            }
          }
        })
        .catch((error: Error) => {
          console.error(error)
          setIsApiBusy(false)
          showNotification({
            title: 'An error has occurred, please try again',
            type: 'error',
            dismissAfter: 3000,
          })
        })

      return false
    },
    [appDispatch, isApiBusy, navigation, showNotification],
  )

  const handleSignInFailure = useCallback(
    result => {
      console.error(result)

      showNotification({
        title: 'An error occurred, please try that again',
        type: 'error',
        autoHide: false,
      })

      setIsApiBusy(false)
    },
    [showNotification],
  )

  const firebaseLoginOptions = useMemo<firebaseui.auth.Config>(
    () => ({
      signInFlow: 'popup',
      autoUpgradeAnonymousUsers: true,
      siteName: 'Anatomiz3D',
      callbacks: {
        signInSuccessWithAuthResult: handleSignInSuccess,
        signInFailure: handleSignInFailure,
      },
      signInOptions: [
        // GoogleAuthProvider.PROVIDER_ID,
        // FacebookAuthProvider.PROVIDER_ID,
        // GithubAuthProvider.PROVIDER_ID,
        // TwitterAuthProvider.PROVIDER_ID,
        // 'apple.com',
        // 'microsoft.com',
        // 'yahoo.com',
        EmailAuthProvider.PROVIDER_ID,
      ],
    }),
    [handleSignInFailure, handleSignInSuccess],
  )

  const fetchData = useCallback(() => {
    doFunctionsCall('HighPriority', {
      signature: 'Utility-GetTermsOfUse',
    })
      .then(data => {
        if (data.code === 200) {
          setTermsOfService(data.data)
        } else if (data.code === 500) {
          console.error(data)

          showNotification({
            title: 'An error occurred, please try that again',
            type: 'error',
            autoHide: false,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)

        showNotification({
          title: 'An error occurred, please try that again',
          type: 'error',
          autoHide: false,
        })
      })
  }, [showNotification])

  return (
    <PageContainer
      height="100%"
      allowNotifications={true}
      showSidebar={false}
      allowUnauthenticated={true}
      onPageReady={() => fetchData()}>
      <LoginContainer>
        <Logo src={LogoImage} />
        <TextElement
          theme="title"
          alignment="center"
          display="block"
          text="Sign In to Anatomiz3D"
        />
        <Rule direction="horizontal" />
        {hasAgreedToTerms ? (
          <>
            <Spacer direction="vertical" amount="40px" display="block" />
            <StyledFirebaseAuth
              firebaseAuth={firebaseAuth}
              uiConfig={firebaseLoginOptions}
            />
            <Spacer direction="vertical" amount="30px" display="block" />
            <TextElement
              theme="link"
              alignment="center"
              italics={true}
              display="block"
              text="View Terms of Use"
              onClick={() => setHasAgreedToTerms(false)}
            />
            <Spacer direction="vertical" amount="20px" display="block" />
          </>
        ) : (
          <>
            <TextElement
              theme="paragraph"
              alignment="center"
              display="block"
              text="You must agree to the Terms of Use to sign in!"
            />
            <TermsContainer>
              <TextInput
                value={termsOfService === '' ? 'Loading...' : termsOfService}
                numberOfLines={10}
                label="Terms of Use"
              />
              <CheckboxInput
                offsetTop="10px"
                offsetMode="padding"
                callback={newValue => setHasAgreedToTerms(newValue)}
                checkboxText="Click to Agree"
                value={hasAgreedToTerms}
                checkboxIdentifier="terms"
              />
            </TermsContainer>
          </>
        )}
      </LoginContainer>
    </PageContainer>
  )
}

export default Login
