import React, { useCallback, useEffect, useState } from 'react'
import { Tab, TabContainer, TabContent, TabRow } from './styled'

import { TabProps } from './types'

const Tabs = ({ structure }: TabProps) => {
  const [activeTab, setActiveTab] = useState<string>('')

  useEffect(() => {
    if (activeTab === '') {
      setActiveTab(structure[0].id)
    }
  }, [structure, activeTab])

  const handleTabClick = useCallback((tabId: string) => {
    setActiveTab(tabId)
  }, [])

  return (
    <TabContainer>
      <TabRow>
        {structure.map(tab => (
          <Tab
            onClick={() => handleTabClick(tab.id)}
            key={`tab-${tab.id}`}
            className={activeTab === tab.id ? 'active' : ''}>
            {tab.title}
          </Tab>
        ))}
      </TabRow>
      {structure.map(tab => (
        <TabContent
          className={activeTab === tab.id ? 'active' : ''}
          key={`content-${tab.id}`}>
          {tab.content}
        </TabContent>
      ))}
    </TabContainer>
  )
}

export default Tabs
