import React, { useContext, useState, useCallback } from 'react'
import { useNavigate } from 'react-router'
import generator from 'generate-password'

import { NotificationContext } from '../../_globals/notifications/notification-context'
import { useAppSelector } from '../../_globals/hooks'
import { DatabaseUser } from '../../_types/user'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import { RootState } from '../../_globals/state-store'
import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import PageContainer from '../../components/page-container/PageContainer'
import PageBackButton from '../../components/page-back-button/PageBackButton'
import TextElement from '../../components/text/Text'
import Spacer from '../../components/spacer/Spacer'
import Button from '../../components/button/Button'
import Loading from '../../components/loading/Loading'
import TextInput from '../../components/text-input/TextInput'
import PrettyFormContainer from '../../components/pretty-form-container/PrettyFormContainer'
import PageError from '../../components/page-error/PageError'
import { FormError } from '../../_types/globals'
import { scrollElementIntoView } from '../../_utilities/dom'
import { defaultDatabaseUser } from '../../_defaults/user'
import { validate } from './helpers'

const userAppSelector = (state: RootState) => state.user

/**
 * User page
 *
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <NewUser />
 * ```
 */
const NewUser = (): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const navigation = useNavigate()
  const userSelector = useAppSelector(userAppSelector)
  const [formErrors, setFormErrors] = useState<FormError[]>([])
  const [user, setUser] = useState<DatabaseUser>({ ...defaultDatabaseUser })
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')

  const generatePassword = useCallback(() => {
    const generatedPassword = generator.generate({
      exclude: '/\\[]{}()@#$%^&*`~,.;:\'"<>',
      length: 25,
      numbers: true,
      symbols: true,
    })

    setPassword(generatedPassword)
  }, [])

  const handleFormDataChange = useCallback(
    (attribute: string, newValue: unknown) => {
      setUser(previous => ({ ...previous, [attribute]: newValue }))
    },
    [],
  )

  const handleValidateData = useCallback(
    (showNotifications: boolean) => {
      const errors = validate(user)
      setFormErrors(() => [...errors])

      if (errors.length > 0) {
        if (showNotifications === true) {
          errors.forEach(error => {
            showNotification({
              title: error.message,
              type: 'error',
              dismissAfter: 3500,
              onPress: () => scrollElementIntoView(error.elementId),
            })
          })
        }

        return false
      }

      return true
    },
    [showNotification, user],
  )

  const handleSaveClick = useCallback(() => {
    if (isApiBusy === true) {
      showNotification({
        title: 'Working on it!',
        type: 'info',
        dismissAfter: 3000,
      })

      return null
    }

    if (handleValidateData(true) === false) {
      return null
    }

    setIsApiBusy(true)

    doFunctionsCall('Admin', {
      signature: 'User-Generate',
      displayName: user.displayName,
      email: user.email,
      password,
      generatedById: userSelector?.id,
    })
      .then(data => {
        if (data.code === 200) {
          showNotification({
            title: 'Successfully generated user!',
            type: 'success',
            dismissAfter: 2500,
          })

          navigation(`/user/${data.data}`)
        } else if (data.code === 500) {
          console.error(data)
          showNotification({
            title: data.message,
            type: 'error',
            dismissAfter: 5000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        showNotification({
          title: 'Something went wrong!',
          type: 'error',
          dismissAfter: 5000,
        })
      })
  }, [
    isApiBusy,
    handleValidateData,
    user?.displayName,
    user?.email,
    password,
    userSelector?.id,
    showNotification,
    navigation,
  ])

  return (
    <PageContainer
      height="initial"
      width="initial"
      offsetBottom="10px"
      offsetLeft="10px"
      offsetRight="10px"
      offsetTop="10px"
      offsetMode="padding"
      allowedRoles={['admin', 'super-admin']}
      allowNotifications={true}
      showSidebar={true}
      allowUnauthenticated={false}>
      <PageBackButton
        text="Back to Users"
        colourMode="light"
        urlOnClick="/users"
      />
      <TextElement
        text="New User"
        theme="h1"
        alignment="center"
        colour="black"
        display="block"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      {user ? (
        <>
          <PrettyFormContainer
            title="User Full Name"
            description={[]}
            formId="displayName"
            formContent={
              <TextInput
                onTextChange={newValue =>
                  handleFormDataChange('displayName', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Email"
            description={[]}
            formId="email"
            formContent={
              <TextInput
                onTextChange={newValue =>
                  handleFormDataChange('email', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Password"
            description={[
              'You will ONLY be able to view this password at this stage.',
              'Once you click "Save", you will no longer be able to view this password.',
              'Ensure that you send the email and password to this user before saving!',
            ]}
            formId="password"
            formContent={
              <>
                <TextInput
                  value={password}
                  onTextChange={newValue => setPassword(newValue)}
                />
                <Spacer direction="vertical" amount="20px" display="block" />
                <AlignmentContainer align="center" display="block">
                  <Button
                    text="Generate Password"
                    theme="secondary"
                    callback={() => generatePassword()}
                  />
                </AlignmentContainer>
              </>
            }
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <PageError
            data={formErrors}
            revalidateCallback={() => handleValidateData(false)}
          />
          <AlignmentContainer align="center" display="block">
            <Button
              callback={() => handleSaveClick()}
              text="Create New User"
              theme="flair"
              display="inline-block"
              size="large"
              isDisabled={isApiBusy}
            />
          </AlignmentContainer>
        </>
      ) : (
        <Loading type="large" />
      )}
      <Spacer direction="vertical" amount="20px" display="block" />
    </PageContainer>
  )
}

export default NewUser
