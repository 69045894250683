import { DatabaseCase } from '../../_types/case'
import { FormError } from '../../_types/globals'

/**
 * Validate the details of a case
 *
 * @param {DatabaseCase} caseData The case data to validate
 * @returns {FormError[]} The errors found
 *
 * @example
 * ```ts
 * const errors = validateDetails(caseData)
 * ```
 */
const validateDetails = (caseData: DatabaseCase): FormError[] => {
  const errors: FormError[] = []

  if (!caseData.title || caseData.title === '') {
    errors.push({
      message: 'Title cannot be blank!',
      elementId: 'title',
    })
  }

  if (!caseData.status) {
    errors.push({
      message: 'Status cannot be blank!',
      elementId: 'status',
    })
  }

  if (!caseData.clientId || caseData.clientId === '') {
    errors.push({
      message: 'Client cannot be blank!',
      elementId: 'clientId',
    })
  }

  if (!caseData.internalCaseDetails || caseData.internalCaseDetails === '') {
    errors.push({
      message: 'Internal Case Details cannot be blank!',
      elementId: 'internalCaseDetails',
    })
  }

  return errors
}

/**
 * Gather the details of a case for saving to the database
 *
 * @param {DatabaseCase} caseData The case data to gather
 * @returns {DatabaseCase} The gathered case data
 *
 * @example
 * ```ts
 * const caseData = gatherDetails(caseData)
 * ```
 */
const gatherDetails = (caseData: DatabaseCase) => ({
  title: caseData.title,
  shareableLink: caseData.shareableLink,
  shareableLinkCreatedBy: caseData.shareableLinkCreatedBy,
  shareableLinkCreateTimestamp: caseData.shareableLinkCreateTimestamp,
  anatomiz3dEngineer: caseData.anatomiz3dEngineer,
  anatomiz3dId: caseData.anatomiz3dId,
  clientId: caseData.clientId,
  displayCaseDetails: caseData.displayCaseDetails,
  forPatient: caseData.forPatient,
  friendlyId: caseData.friendlyId,
  institutionId: caseData.institutionId,
  internalCaseDetails: caseData.internalCaseDetails,
  status: caseData.status,
})

export { validateDetails, gatherDetails }
