import styled from 'styled-components'

const ModelSidebar = styled.div`
  max-width: 300px;
  min-width: 200px;
  width: 100%;
  height: calc(100vh - 175px);
  overflow-y: auto;

  @media (max-width: 800px) {
    width: 100%;
    max-width: unset;
    margin-top: 50px;
    height: unset;
    overflow-y: unset;
  }
`

const StlContainer = styled.div`
  flex: 1;
`

const StlViewerTarget = styled.div`
  height: calc(100% - 50px);
  width: 100%;
  border: 1px solid black;

  & canvas {
    width: 100% !important;
    height: 100% !important;
  }
`

const FileUploadTarget = styled.input`
  display: none;
`

const CameraControls = styled.div`
  text-align: center;
  padding: 10px 10px 0 10px;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
`

const CameraStateControls = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
  flex-wrap: wrap;
  align-content: center;

  @media (max-width: 800px) {
    flex-wrap: nowrap;
    justify-content: center;
  }
`

const CameraPositionsContainer = styled.div`
  display: block;
`

const CameraPositions = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    justify-content: center;
  }
`

const CameraPositionsLabel = styled.label`
  width: 100%;
  flex: 1;
  font-size: 90%;
`

export {
  StlContainer,
  StlViewerTarget,
  ModelSidebar,
  FileUploadTarget,
  CameraControls,
  CameraStateControls,
  CameraPositionsContainer,
  CameraPositions,
  CameraPositionsLabel,
}
