import React, { useCallback, useContext, useEffect, useState } from 'react'

import Chat from '../../../../components/chat/Chat'
import { doFunctionsCall } from '../../../../_globals/custom-firebase/custom-firebase'
import { useAppSelector } from '../../../../_globals/hooks'
import { NotificationContext } from '../../../../_globals/notifications/notification-context'
import { RootState } from '../../../../_globals/state-store'
import { DatabaseComment } from '../../../../_types/comment'
import { generateId } from '../../../../_utilities/utils'
import { Section } from '../../styled'
import { CaseCommentsProps } from './types'

const userAppSelector = (state: RootState) => state.user

/**
 * The CaseComments component of the Case page.
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <CaseComments
 *  caseData={caseData}
 * />
 * ```
 */
const CaseComments = ({ caseData }: CaseCommentsProps) => {
  const { showNotification } = useContext(NotificationContext)
  const userSelector = useAppSelector(userAppSelector)
  const [lastFetch, setLastFetch] = useState<number>(-1)
  const [comments, setComments] = useState<DatabaseComment[]>([])
  const [hasInitiallyFetchedComments, setHasInitiallyFetchedComments] =
    useState<boolean>(false)

  const handleAddComment = useCallback(
    (comment: string) => {
      setComments(previous => [
        ...previous,
        {
          authorId: userSelector.id,
          caseId: caseData.id,
          comment,
          createdTimestamp: Date.now(),
          id: `temp-${generateId(20)}`,
          photoIds: [],
          visibleToPatient: false,
          replyToCommentId: '',
          userDisplayName: userSelector.displayName,
        },
      ])
    },
    [caseData?.id, userSelector?.displayName, userSelector?.id],
  )

  const handleRefreshRequest = useCallback(() => {
    const since = hasInitiallyFetchedComments ? lastFetch : null

    doFunctionsCall('HighestPriority', {
      signature: 'Comment-GetCommentsForCase',
      caseId: caseData.id,
      since,
    })
      .then(data => {
        if (data.code === 200) {
          const fetchedComments: DatabaseComment[] = JSON.parse(data.data)

          if (hasInitiallyFetchedComments === false) {
            setComments(() => [...fetchedComments])
          } else {
            setComments(previous => [
              ...previous.filter(
                previousMessage =>
                  !fetchedComments.some(
                    existingMessage =>
                      existingMessage.authorId === previousMessage.authorId &&
                      existingMessage.comment === previousMessage.comment,
                  ),
              ),
              ...fetchedComments,
            ])
          }

          if (fetchedComments.length > 0) {
            setLastFetch(
              () =>
                fetchedComments[fetchedComments.length - 1].createdTimestamp,
            )
          }

          setHasInitiallyFetchedComments(true)
        } else if (data.code === 500) {
          console.error(data)
          showNotification({
            title: 'Failed to fetch comments',
            type: 'error',
            dismissAfter: 3000,
          })
        }
      })
      .catch(error => {
        console.error(error)

        showNotification({
          title: 'Failed to fetch comments',
          type: 'error',
          dismissAfter: 3000,
        })
      })
  }, [caseData?.id, hasInitiallyFetchedComments, lastFetch, showNotification])

  useEffect(() => {
    if (hasInitiallyFetchedComments === false) {
      handleRefreshRequest()
    }
  }, [handleRefreshRequest, hasInitiallyFetchedComments])

  return (
    <Section>
      <Chat
        comments={comments}
        caseId={caseData.id}
        onAddComment={comment => handleAddComment(comment)}
        onRefreshRequest={() => handleRefreshRequest()}
      />
    </Section>
  )
}

export default CaseComments
