import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import ReactSlider from 'react-slider'

import AlignmentContainer from '../../../../../../components/alignment-container/AlignmentContainer'
import Button from '../../../../../../components/button/Button'
import Spacer from '../../../../../../components/spacer/Spacer'
import TextInput from '../../../../../../components/text-input/TextInput'
import TextElement from '../../../../../../components/text/Text'
import { DatabaseModel, Position } from '../../../../../../_types/model'
import { generateId } from '../../../../../../_utilities/utils'
import DeletePopUp from '../../../../../../components/delete-popup/DeletePopUp'
import { NotificationContext } from '../../../../../../_globals/notifications/notification-context'
import { Label, LabelContainer, LabelInput, ModelSettings } from './styled'
import {
  FormattedRotationControl,
  ModelListingProps,
  RotationControl,
} from './types'
import { insertTooltips } from './helpers'

const rotations: RotationControl = {}
const formattedRotations: FormattedRotationControl = {}

/**
 * The ModelListing component of the Models section.
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <ModelListing
 *  existingModels={existingModels}
 *  newModels={newModels}
 *  onDataChange={onDataChange}
 *  onModelPreview={onModelPreview}
 *  onSaveModel={onSaveModel}
 *  onDeleteModel={onDeleteModel}
 * />
 * ```
 */
const ModelListing = ({
  existingModels,
  newModels,
  hasCompletedFirstRender,
  onDataChange,
  onModelPreview,
  onSaveModel,
  onDeleteModel,
}: ModelListingProps): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const [hasInitialized, setHasInitialized] = useState<boolean>(false)
  const [rawPositions, setRawPositions] = useState<FormattedRotationControl>({})

  const forceDigest = useCallback(() => {
    const referenceModels = [...existingModels, ...newModels]

    onDataChange(
      referenceModels[0].id,
      'caseId',
      (referenceModels[0].caseId = generateId(10)),
    )
  }, [existingModels, newModels, onDataChange])

  const handleModelPreview = useCallback(
    (modelId: number, attribute: string, value: string | Position) => {
      onModelPreview(modelId, attribute, value)
    },
    [onModelPreview],
  )

  const handleDataChange = useCallback(
    (id: string, attribute: string, newValue: unknown) => {
      if (attribute === 'modelRotation') {
        const modelId =
          [...existingModels, ...newModels].find(model => model.id === id)
            ?.modelId ?? 0

        handleModelPreview(modelId, attribute, newValue as Position)
        onDataChange(id, attribute, rotations[id])
      } else {
        onDataChange(id, attribute, newValue)
      }
    },
    [existingModels, handleModelPreview, newModels, onDataChange],
  )

  const initializeSettings = useCallback(
    (allModels?: DatabaseModel[]) => {
      const referenceModels = allModels ?? [...existingModels, ...newModels]

      referenceModels.forEach(model => {
        if (!rotations[model.id]) {
          rotations[model.id] = {
            x: model.modelRotation.x,
            y: model.modelRotation.y,
            z: model.modelRotation.z,
          }
        }

        if (!formattedRotations[model.id]) {
          formattedRotations[model.id] = {
            x: model.modelRotation.x,
            y: model.modelRotation.y,
            z: model.modelRotation.z,
          }
        }

        if (!rawPositions[model.id]) {
          setRawPositions(previous => ({
            ...previous,
            [model.id]: {
              x: model.modelPosition.x,
              y: model.modelPosition.y,
              z: model.modelPosition.z,
            },
          }))
        }
      })

      insertTooltips()

      if (hasInitialized === false && referenceModels.length > 0) {
        setHasInitialized(true)
        forceDigest()
      }
    },
    [existingModels, forceDigest, hasInitialized, newModels, rawPositions],
  )

  const handleSaveClick = useCallback(
    (id: string) => {
      onSaveModel(id)
    },
    [onSaveModel],
  )

  const handleDeleteClick = useCallback(
    (id: string) => {
      onDeleteModel(id)

      showNotification({
        title: 'Model Deleted',
        type: 'success',
        dismissAfter: 1000,
      })
    },
    [onDeleteModel, showNotification],
  )

  useEffect(() => {
    initializeSettings([...existingModels, ...newModels])
  }, [existingModels, initializeSettings, newModels])

  return (
    <>
      {[...existingModels, ...newModels].map(model => (
        <ModelSettings key={model.id} id={`model-${model.modelId}`}>
          <TextElement
            text={model.fileName}
            theme="paragraph"
            alignment="center"
            display="block"
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <TextInput
            label="Model Title"
            initialValue={model.title}
            onTextChange={value => handleDataChange(model.id, 'title', value)}
          />
          <Spacer direction="vertical" amount="10px" display="block" />
          <Label>Model Colour</Label>
          <SketchPicker
            color={model.colour}
            disableAlpha={true}
            presetColors={[
              'rgb(239, 225, 206)',
              'rgb(192, 18, 18)',
              'rgb(126, 0, 0)',
              'rgb(63, 39, 131)',
              'rgb(103, 101, 106)',
              'rgb(240, 136, 233)',
              'rgb(250, 216, 204)',
              'rgb(117, 83, 74)',
            ]}
            onChange={value => {
              handleModelPreview(model.modelId, 'colour', value.hex)
            }}
            onChangeComplete={value => {
              handleDataChange(model.id, 'colour', value.hex)
            }}
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <LabelContainer>
            <Label>Position - X</Label>
            <LabelInput
              type="text"
              value={rawPositions[model.id]?.x ?? 0}
              onChange={textEvent => {
                const textValue = textEvent.target.value ?? '0'
                setRawPositions(previous => ({
                  ...previous,
                  [model.id]: {
                    ...previous[model.id],
                    x: textValue,
                  },
                }))

                if (textValue.match(/^-?\d+(\.\d+)?$/giu)) {
                  const newValue: Position = {
                    ...model.modelPosition,
                    x: Number.parseFloat(textEvent.target.value ?? '0'),
                  }

                  handleDataChange(model.id, 'modelPosition', newValue)
                }
              }}
            />
          </LabelContainer>
          <ReactSlider
            className="default-slider"
            min={-1000}
            max={1000}
            step={1}
            value={model.modelPosition?.x ?? 0}
            onChange={value => {
              const newValue: Position = {
                ...model.modelPosition,
                x: value,
              }

              handleDataChange(model.id, 'modelPosition', newValue)
            }}
          />
          <Spacer direction="vertical" amount="10px" display="block" />
          <LabelContainer>
            <Label>Position - Y</Label>
            <LabelInput
              type="text"
              value={rawPositions[model.id]?.y ?? '0'}
              onChange={textEvent => {
                const textValue = textEvent.target.value ?? '0'
                setRawPositions(previous => ({
                  ...previous,
                  [model.id]: {
                    ...previous[model.id],
                    y: textValue,
                  },
                }))

                if (textValue.match(/^-?\d+(\.\d+)?$/giu)) {
                  const newValue: Position = {
                    ...model.modelPosition,
                    y: Number.parseFloat(textEvent.target.value ?? '0'),
                  }

                  handleDataChange(model.id, 'modelPosition', newValue)
                }
              }}
            />
          </LabelContainer>
          <ReactSlider
            className="default-slider"
            min={-1000}
            max={1000}
            step={1}
            value={model.modelPosition?.y ?? 0}
            onChange={value => {
              const newValue: Position = {
                ...model.modelPosition,
                y: value,
              }

              handleDataChange(model.id, 'modelPosition', newValue)
            }}
          />
          <Spacer direction="vertical" amount="10px" display="block" />
          <LabelContainer>
            <Label>Position - Z</Label>
            <LabelInput
              type="text"
              value={rawPositions[model.id]?.z ?? '0'}
              onChange={textEvent => {
                const textValue = textEvent.target.value ?? '0'
                setRawPositions(previous => ({
                  ...previous,
                  [model.id]: {
                    ...previous[model.id],
                    z: textValue,
                  },
                }))

                if (textValue.match(/^-?\d+(\.\d+)?$/giu)) {
                  const newValue: Position = {
                    ...model.modelPosition,
                    z: Number.parseFloat(textEvent.target.value ?? '0'),
                  }

                  handleDataChange(model.id, 'modelPosition', newValue)
                }
              }}
            />
          </LabelContainer>
          <ReactSlider
            className="default-slider"
            min={-1000}
            max={1000}
            step={1}
            value={model.modelPosition?.z ?? 0}
            onChange={value => {
              const newValue: Position = {
                ...model.modelPosition,
                z: value,
              }

              handleDataChange(model.id, 'modelPosition', newValue)
            }}
          />
          <Spacer direction="vertical" amount="10px" display="block" />
          <LabelContainer>
            <Label>Rotation - X</Label>
            <LabelInput
              type="number"
              value={formattedRotations[model.id]?.x ?? 0}
              step={0.1}
              onChange={textEvent => {
                const rawValue = textEvent.target.value
                let sanitizedValue = Number.parseFloat(rawValue ?? '0')
                sanitizedValue = Number.isNaN(sanitizedValue)
                  ? 0
                  : sanitizedValue

                if (rotations[model.id]?.x?.toString() === rawValue) {
                  if (rawValue === '0') {
                    formattedRotations[model.id].x = '0'
                    forceDigest()
                  }

                  return null
                }

                const newValue: Position = {
                  x: Number.parseFloat(
                    (sanitizedValue - rotations[model.id].x).toFixed(1),
                  ),
                  y: 0,
                  z: 0,
                }

                if (rawValue === '') {
                  formattedRotations[model.id].x = ''
                } else if (rawValue === '0') {
                  formattedRotations[model.id].x = '0'
                } else {
                  formattedRotations[model.id].x = sanitizedValue
                }

                rotations[model.id].x = sanitizedValue
                handleDataChange(model.id, 'modelRotation', newValue)
              }}
            />
          </LabelContainer>
          <ReactSlider
            className="default-slider"
            min={0}
            max={10}
            step={0.1}
            value={rotations[model.id]?.x ?? 0}
            onChange={value => {
              const newValue: Position = {
                x: value < rotations[model.id].x ? -0.1 : 0.1,
                y: 0,
                z: 0,
              }

              formattedRotations[model.id].x = value
              rotations[model.id].x = value
              handleDataChange(model.id, 'modelRotation', newValue)
            }}
          />
          <Spacer direction="vertical" amount="10px" display="block" />
          <LabelContainer>
            <Label>Rotation - Y</Label>
            <LabelInput
              type="number"
              value={formattedRotations[model.id]?.y ?? 0}
              step={0.1}
              onChange={textEvent => {
                const rawValue = textEvent.target.value
                let sanitizedValue = Number.parseFloat(rawValue ?? '0')
                sanitizedValue = Number.isNaN(sanitizedValue)
                  ? 0
                  : sanitizedValue

                if (rotations[model.id]?.y?.toString() === rawValue) {
                  if (rawValue === '0') {
                    formattedRotations[model.id].y = '0'
                    forceDigest()
                  }

                  return null
                }

                const newValue: Position = {
                  x: 0,
                  y: Number.parseFloat(
                    (sanitizedValue - rotations[model.id].y).toFixed(1),
                  ),
                  z: 0,
                }

                if (rawValue === '') {
                  formattedRotations[model.id].y = ''
                } else if (rawValue === '0') {
                  formattedRotations[model.id].y = '0'
                } else {
                  formattedRotations[model.id].y = sanitizedValue
                }

                rotations[model.id].y = sanitizedValue
                handleDataChange(model.id, 'modelRotation', newValue)
              }}
            />
          </LabelContainer>
          <ReactSlider
            className="default-slider"
            min={0}
            max={10}
            step={0.1}
            value={rotations[model.id]?.y ?? 0}
            onChange={value => {
              const newValue: Position = {
                x: 0,
                y: value < rotations[model.id].y ? -0.1 : 0.1,
                z: 0,
              }

              formattedRotations[model.id].y = value
              rotations[model.id].y = value
              handleDataChange(model.id, 'modelRotation', newValue)
            }}
          />
          <Spacer direction="vertical" amount="10px" display="block" />
          <LabelContainer>
            <Label>Rotation - Z</Label>
            <LabelInput
              type="number"
              value={formattedRotations[model.id]?.z ?? 0}
              step={0.1}
              onChange={textEvent => {
                const rawValue = textEvent.target.value
                let sanitizedValue = Number.parseFloat(rawValue ?? '0')
                sanitizedValue = Number.isNaN(sanitizedValue)
                  ? 0
                  : sanitizedValue

                if (rotations[model.id]?.z?.toString() === rawValue) {
                  if (rawValue === '0') {
                    formattedRotations[model.id].z = '0'
                    forceDigest()
                  }

                  return null
                }

                const newValue: Position = {
                  x: 0,
                  y: 0,
                  z: Number.parseFloat(
                    (sanitizedValue - rotations[model.id].z).toFixed(1),
                  ),
                }

                if (rawValue === '') {
                  formattedRotations[model.id].z = ''
                } else if (rawValue === '0') {
                  formattedRotations[model.id].z = '0'
                } else {
                  formattedRotations[model.id].z = sanitizedValue
                }

                rotations[model.id].z = sanitizedValue
                handleDataChange(model.id, 'modelRotation', newValue)
              }}
            />
          </LabelContainer>
          <ReactSlider
            className="default-slider"
            min={0}
            max={10}
            step={0.1}
            value={rotations[model.id]?.z ?? 0}
            onChange={value => {
              const newValue: Position = {
                x: 0,
                y: 0,
                z: value < rotations[model.id].z ? -0.1 : 0.1,
              }

              formattedRotations[model.id].z = value
              rotations[model.id].z = value
              handleDataChange(model.id, 'modelRotation', newValue)
            }}
          />
          <Spacer direction="vertical" amount="10px" display="block" />
          <LabelContainer>
            <Label>Scale</Label>
            <LabelInput
              type="number"
              value={(model.scale * 100).toFixed(0)}
              onChange={textEvent => {
                const newScale =
                  Number.isNaN(textEvent.target.value) ||
                  textEvent.target.value === ''
                    ? 0
                    : Number.parseFloat(textEvent.target.value) / 100

                handleDataChange(model.id, 'scale', newScale)
              }}
            />
            %
          </LabelContainer>
          <ReactSlider
            className="default-slider"
            min={-1}
            max={2}
            step={0.01}
            value={model.scale}
            onChange={value => {
              handleDataChange(model.id, 'scale', value)
            }}
          />
          <Spacer direction="vertical" amount="10px" display="block" />
          <LabelContainer>
            <Label>Opacity</Label>
            <LabelInput
              type="number"
              value={(model.opacity * 100).toFixed(0)}
              onChange={textEvent => {
                const newOpacity = Number.isNaN(textEvent.target.value)
                  ? 0
                  : Number.parseFloat(textEvent.target.value) / 100

                handleDataChange(model.id, 'opacity', newOpacity)
              }}
            />
            %
          </LabelContainer>
          <ReactSlider
            className="default-slider"
            min={0}
            max={1}
            step={0.1}
            value={model.opacity}
            onChange={value => {
              handleDataChange(model.id, 'opacity', value)
            }}
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <AlignmentContainer align="center" display="block">
            <DeletePopUp onDelete={() => handleDeleteClick(model.id)} />
            <Spacer
              direction="horizontal"
              amount="20px"
              display="inline-block"
            />
            <Button
              text="Save"
              theme="secondary"
              display="inline-block"
              callback={() => handleSaveClick(model.id)}
            />
          </AlignmentContainer>
        </ModelSettings>
      ))}
    </>
  )
}

export default ModelListing
