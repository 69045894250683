import { DatabaseClient } from '../../_types/client'
import { FormError } from '../../_types/globals'

/**
 * Validate the details of a client
 *
 * @param {DatabaseClient} client The client data to validate
 * @returns {FormError[]} The errors found
 *
 * @example
 * ```ts
 * const errors = validate(client)
 * ```
 */
const validate = (client: DatabaseClient): FormError[] => {
  const errors: FormError[] = []

  if (!client.userId || client.userId === '') {
    errors.push({
      message: 'Must select a base user!',
      elementId: 'userId',
    })
  }

  if (!client.entityNoun || client.entityNoun === '') {
    errors.push({
      message: 'Noun cannot be blank!',
      elementId: 'entityNoun',
    })
  }

  return errors
}

export { validate }
