import React, { useContext, useState, useCallback, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router'

import { NotificationContext } from '../../_globals/notifications/notification-context'
import { useAppSelector } from '../../_globals/hooks'
import { deepCopy } from '../../_utilities/utils'
import { DatabaseUser } from '../../_types/user'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import { RootState } from '../../_globals/state-store'
import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import PageContainer from '../../components/page-container/PageContainer'
import PageBackButton from '../../components/page-back-button/PageBackButton'
import TextElement from '../../components/text/Text'
import Spacer from '../../components/spacer/Spacer'
import Button from '../../components/button/Button'
import Loading from '../../components/loading/Loading'
import TextInput from '../../components/text-input/TextInput'
import PrettyFormContainer from '../../components/pretty-form-container/PrettyFormContainer'
import DropdownInput from '../../components/dropdown-input/DropdownInput'
import { DropdownData } from '../../components/dropdown-input/types'
import PageError from '../../components/page-error/PageError'
import { FormError } from '../../_types/globals'
import { scrollElementIntoView } from '../../_utilities/dom'
import { sanitizeUser, validate } from './helpers'
import { UserPageParams } from './types'

const userAppSelector = (state: RootState) => state.user

/**
 * User page
 *
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <UserPage />
 * ```
 */
const UserPage = (): JSX.Element => {
  const { id } = useParams<UserPageParams>()
  const { showNotification } = useContext(NotificationContext)
  const navigation = useNavigate()
  const userSelector = useAppSelector(userAppSelector)
  const [formErrors, setFormErrors] = useState<FormError[]>([])
  const [criticalError, setCriticalError] = useState<string>('')
  const [user, setUser] = useState<DatabaseUser>()
  const [backupUser, setBackupUser] = useState<DatabaseUser>()
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)

  const canModifyRole = useMemo<boolean>(() => {
    if (userSelector.role === 'super-admin') {
      return true
    } else if (
      userSelector.role === 'admin' &&
      backupUser &&
      backupUser.role !== 'super-admin'
    ) {
      return true
    }

    return false
  }, [backupUser, userSelector.role])

  const roleDropdownData = useMemo<DropdownData[]>(
    () => [
      ...(userSelector.role === 'super-admin'
        ? [{ displayValue: 'Super Admin', id: 'super-admin' }]
        : []),
      { displayValue: 'Admin', id: 'admin' },
      { displayValue: 'Designer', id: 'designer' },
      { displayValue: 'User', id: 'user' },
    ],
    [userSelector?.role],
  )

  const handleFormDataChange = useCallback(
    (attribute: string, newValue: unknown) => {
      setUser(previous => ({ ...previous, [attribute]: newValue }))
    },
    [],
  )

  const fetchData = useCallback((fetchedUser: DatabaseUser) => {
    const sanitizedUser = sanitizeUser(fetchedUser)

    setUser(() => deepCopy(sanitizedUser))
    setBackupUser(() => deepCopy(sanitizedUser))
    setIsApiBusy(false)
  }, [])

  const handlePageReady = useCallback(() => {
    doFunctionsCall('HighestPriority', {
      signature: 'User-Get',
      userId: id,
    })
      .then(data => {
        if (data.code === 200) {
          const fetchedUser: DatabaseUser = JSON.parse(data.data)
          fetchData(fetchedUser)
        } else if (data.code === 500) {
          console.error(data)
          setCriticalError('Could not fetch user')

          showNotification({
            title: 'Failed to fetch user',
            type: 'error',
            dismissAfter: 3000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        setCriticalError('Could not fetch user')

        showNotification({
          title: 'Failed to fetch user',
          type: 'error',
          dismissAfter: 3000,
        })
      })
  }, [fetchData, showNotification, id])

  const handleValidateData = useCallback(
    (showNotifications: boolean) => {
      const errors = validate(user)
      setFormErrors(() => [...errors])

      if (errors.length > 0) {
        if (showNotifications === true) {
          errors.forEach(error => {
            showNotification({
              title: error.message,
              type: 'error',
              dismissAfter: 3500,
              onPress: () => scrollElementIntoView(error.elementId),
            })
          })
        }

        return false
      }

      return true
    },
    [showNotification, user],
  )

  const handleSaveClick = useCallback(() => {
    if (isApiBusy === true) {
      showNotification({
        title: 'Working on it!',
        type: 'info',
        dismissAfter: 3000,
      })

      return null
    }

    if (handleValidateData(true) === false) {
      return null
    }

    setIsApiBusy(true)

    doFunctionsCall('Admin', {
      signature: 'User-Update',
      id: user.id,
      displayName: user.displayName,
      email: user.email,
    })
      .then(data => {
        if (data.code === 200) {
          if (backupUser.role !== user.role) {
            doFunctionsCall('Admin', {
              signature: 'User-ModifyUserClaims',
              targetEmail: user.email,
              claims: {
                role: user.role,
                isAppTester: false,
              },
            }).then(rawRoleData => {
              if (rawRoleData.code === 200) {
                setBackupUser(() => deepCopy(user))
                setIsApiBusy(false)

                showNotification({
                  title: 'Successfully updated user!',
                  type: 'success',
                  dismissAfter: 3500,
                })
              } else if (rawRoleData.code === 500) {
                console.error(rawRoleData)
                showNotification({
                  title: data.message,
                  type: 'error',
                  dismissAfter: 5000,
                })
              }
            })
          } else {
            setBackupUser(() => deepCopy(user))
            setIsApiBusy(false)

            showNotification({
              title: 'Successfully updated user!',
              type: 'success',
              dismissAfter: 3500,
            })
          }
        } else if (data.code === 500) {
          console.error(data)
          showNotification({
            title: data.message,
            type: 'error',
            dismissAfter: 5000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        showNotification({
          title: 'Failed to update user',
          type: 'error',
          dismissAfter: 3000,
        })
      })
  }, [isApiBusy, handleValidateData, user, showNotification, backupUser?.role])

  const handleRevertClick = useCallback(() => {
    showNotification({
      title: `All changes ${id === 'new' ? 'cancelled' : 'reverted'}!`,
      type: 'success',
      dismissAfter: 2500,
    })

    if (id === 'new') {
      navigation('/users')
    } else {
      setUser(() => deepCopy(backupUser))
    }
  }, [showNotification, id, navigation, backupUser])

  return (
    <PageContainer
      height="initial"
      width="initial"
      offsetBottom="10px"
      offsetLeft="10px"
      offsetRight="10px"
      offsetTop="10px"
      offsetMode="padding"
      allowedRoles={['super-admin', 'admin']}
      allowUnauthenticated={false}
      pageError={criticalError}
      allowNotifications={true}
      showSidebar={true}
      onPageReady={() => handlePageReady()}>
      <PageBackButton
        text="Back to Users"
        colourMode="light"
        urlOnClick="/users"
      />
      <TextElement
        text={user?.displayName}
        theme="h1"
        alignment="center"
        colour="black"
        display="block"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      {user ? (
        <>
          <PrettyFormContainer
            title="User Full Name"
            description={[]}
            formId="displayName"
            formContent={
              <TextInput
                initialValue={user.displayName}
                onTextChange={newValue =>
                  handleFormDataChange('displayName', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Email"
            description={[]}
            formId="email"
            formContent={
              <TextInput
                initialValue={user.email}
                onTextChange={newValue =>
                  handleFormDataChange('email', newValue)
                }
              />
            }
          />
          {canModifyRole ? (
            <PrettyFormContainer
              title="User Role"
              description={[]}
              formId="role"
              formContent={
                <DropdownInput
                  data={roleDropdownData}
                  width="100%"
                  initialValue={user.role}
                  onChange={newValue => handleFormDataChange('role', newValue)}
                />
              }
            />
          ) : null}
          <Spacer direction="vertical" amount="20px" display="block" />
          <PageError
            data={formErrors}
            revalidateCallback={() => handleValidateData(false)}
          />
          <AlignmentContainer align="center" display="block">
            <Button
              callback={() => handleRevertClick()}
              text={id === 'new' ? 'Cancel' : 'Revert'}
              theme="flair"
              display="inline-block"
              size="small"
              isDisabled={isApiBusy}
            />
            <Spacer
              direction="horizontal"
              amount="20px"
              display="inline-block"
            />
            <Button
              callback={() => handleSaveClick()}
              text="Save"
              theme="flair"
              display="inline-block"
              size="large"
              isDisabled={isApiBusy}
            />
          </AlignmentContainer>
        </>
      ) : (
        <Loading type="large" />
      )}
      <Spacer direction="vertical" amount="20px" display="block" />
    </PageContainer>
  )
}

export default UserPage
