import { DatabaseUser, DisplayUser } from '../_types/user'

const defaultDatabaseUser: DatabaseUser = {
  id: '',
  displayName: '',
  firstName: '',
  lastName: '',
  loginProviderId: 'email',
  accountCreatedTimestamp: Date.now(),
  accountGeneratedBy: '',
  email: '',
  loginProviderName: 'Email',
  role: 'user',
  wasAccountGenerated: true,
}

const defaultDisplayUser: DisplayUser = {
  id: '',
  displayName: '',
  firstName: '',
  lastName: '',
  accountCreatedTimestamp: Date.now(),
  email: '',
  role: 'user',
}

export { defaultDisplayUser, defaultDatabaseUser }
