import React, { useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import { Label, SubLabel } from './styled'
import { InputLabelProps } from './types'

/**
 * A standardized label for inputs
 *
 * @param {InputLabelProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <InputLabel label={'Your Address'} colour="black" />
 * ```
 */
const InputLabel = ({
  label,
  subLabel,
  colour,
}: InputLabelProps): JSX.Element => {
  const labelTheme = useMemo(() => {
    const theme: DefaultTheme = {
      colour: colour || 'black',
    }

    return theme
  }, [colour])

  return (
    <>
      <Label theme={labelTheme}>{label}</Label>
      {subLabel ? <SubLabel theme={labelTheme}>{subLabel}</SubLabel> : null}
    </>
  )
}

export default InputLabel
