/* eslint-disable max-nested-callbacks */
/* eslint-disable camelcase */
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { getStorage, uploadBytes, ref, getDownloadURL } from 'firebase/storage'

import { doFunctionsCall } from '../../../../_globals/custom-firebase/custom-firebase'
import { NotificationContext } from '../../../../_globals/notifications/notification-context'
import { CameraState, DatabaseModel, Position } from '../../../../_types/model'
import { Section } from '../../styled'
import TextElement from '../../../../components/text/Text'
import Spacer from '../../../../components/spacer/Spacer'
import AlignmentContainer from '../../../../components/alignment-container/AlignmentContainer'
import Button from '../../../../components/button/Button'
import { deepCopy, generateId } from '../../../../_utilities/utils'
import {
  defaultDatabaseModel,
  defaultPosition,
} from '../../../../_defaults/model'
import { RootState } from '../../../../_globals/state-store'
import Loading from '../../../../components/loading/Loading'
import {
  CameraControls,
  CameraPositions,
  CameraPositionsContainer,
  CameraPositionsLabel,
  CameraStateControls,
  FileUploadTarget,
  ModelSidebar,
  StlContainer,
  StlViewerTarget,
} from './styled'
import { CaseModelsProps } from './types'
import {
  determineModel,
  getSanitizedCameraPosition,
  getSnappedCameraPosition,
  isCameraPositionValid,
} from './helpers'
import ModelListing from './components/model-listing/ModelListing'

const userAppSelector = (state: RootState) => state.user
let stlViewer: StlViewer = null

/**
 * The CaseModels component of the Case page.
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <CaseModels
 *  caseData={caseData}
 *  isApiBusy={isApiBusy}
 *  onApiBusy={onApiBusy}
 *  onDataChange={onDataChange}
 * />
 * ```
 */
const CaseModels = ({
  caseData,
  isApiBusy,
  onApiBusy,
  onDataChange,
}: CaseModelsProps): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const userSelector = useSelector(userAppSelector)
  const [hasFetched, setHasFetched] = useState<boolean>(false)
  const [models, setModels] = useState<DatabaseModel[]>([])
  const [newModels, setNewModels] = useState<DatabaseModel[]>([])
  const [cameraPosition, setCameraPosition] = useState<CameraState>()
  const [showGrid, setShowGrid] = useState<boolean>(true)
  const [hasCompletedFirstRender, setHasCompletedFirstRender] =
    useState<boolean>(false)
  const [hasPluginInitialized, setHasPluginInitialized] =
    useState<boolean>(false)

  const handleDataChange = useCallback(
    (id: string, attribute: string, newValue: unknown) => {
      const foundModel = determineModel(models, newModels, id)

      if (foundModel.isNew === false) {
        const updatedModel = {
          ...foundModel.model,
          [attribute]: newValue,
        }

        setModels(previous => {
          const newState = [...previous]
          const foundIndex = newState.findIndex(model => model.id === id)

          newState[foundIndex] = updatedModel

          return newState
        })
      } else {
        const updatedModel = {
          ...foundModel.model,
          [attribute]: newValue,
        }

        setNewModels(previous => {
          const newState = [...previous]
          const foundIndex = newState.findIndex(model => model.id === id)

          newState[foundIndex] = updatedModel

          return newState
        })
      }

      if (attribute === 'colour') {
        stlViewer.set_color(foundModel.model.modelId, newValue as string)
      } else if (attribute === 'modelPosition') {
        const newPosition = newValue as Position

        stlViewer.set_position(
          foundModel.model.modelId,
          newPosition.x,
          newPosition.y,
          newPosition.z,
        )
      } else if (attribute === 'scale') {
        const newScale = newValue as number

        stlViewer.set_scale(
          foundModel.model.modelId,
          newScale,
          newScale,
          newScale,
        )
      } else if (attribute === 'opacity') {
        stlViewer.set_opacity(foundModel.model.modelId, newValue as number)
      }
    },
    [models, newModels],
  )

  const handleModelPreview = useCallback(
    (modelId: number, attribute: string, value: string | Position) => {
      if (attribute === 'colour') {
        stlViewer.set_color(modelId, value as string)
      } else if (attribute === 'modelRotation') {
        const newRotation = value as Position

        stlViewer.rotate(modelId, newRotation.x, newRotation.y, newRotation.z)
      }
    },
    [],
  )

  const handleSaveModel = useCallback(
    (id: string) => {
      if (isApiBusy) {
        showNotification({
          title: 'Please wait for the current action to complete',
          type: 'error',
          dismissAfter: 3000,
        })

        return null
      }

      const foundModel = determineModel(models, newModels, id)

      if (foundModel.model.title === '') {
        showNotification({
          title: 'Please enter a title for the model',
          type: 'error',
          dismissAfter: 3000,
        })

        return null
      }

      onApiBusy(true)

      if (foundModel.isNew === false) {
        doFunctionsCall('Admin', {
          signature: 'Model-Update',
          model: foundModel.model,
        })
          .then(data => {
            if (data.code === 200) {
              showNotification({
                title: `${foundModel.model.title} updated`,
                type: 'success',
                dismissAfter: 3000,
              })
            } else if (data.code === 500) {
              console.error(data)
              showNotification({
                title: `${foundModel.model.title} failed to update`,
                type: 'error',
                dismissAfter: 3000,
              })
            }
          })
          .catch((error: Error) => {
            console.error(error)
            showNotification({
              title: `${foundModel.model.title} failed to update!`,
              type: 'error',
              dismissAfter: 3000,
            })
          })
          .finally(() => {
            onApiBusy(false)
          })
      } else {
        const modelFile = stlViewer.models.find(
          stlModel => stlModel.id === foundModel.model.modelId,
        ).local_file

        doFunctionsCall('Admin', {
          signature: 'Model-CreateNewModel',
          model: foundModel.model,
        })
          .then(data => {
            if (data.code === 200) {
              showNotification({
                title: `${foundModel.model.title} added`,
                type: 'success',
                dismissAfter: 3000,
              })

              const newModel: DatabaseModel = JSON.parse(data.data)
              const storage = getStorage()
              const filePath = `models/${newModel.caseId}/${newModel.id}`
              const storageReference = ref(storage, filePath)

              uploadBytes(storageReference, modelFile as File)
                .then(() => {
                  getDownloadURL(storageReference).then(url => {
                    newModel.filePath = url

                    doFunctionsCall('Admin', {
                      signature: 'Model-Update',
                      model: newModel,
                    })
                      .then(() => {
                        setModels(previous => [...previous, newModel])
                        setNewModels(previous =>
                          [...previous].filter(
                            model => model.modelId !== newModel.modelId,
                          ),
                        )
                        onApiBusy(false)
                      })
                      .catch((error: Error) => {
                        console.error(error)
                        showNotification({
                          title: `${newModel.title} failed to update!`,
                          type: 'error',
                          dismissAfter: 3000,
                        })
                        onApiBusy(false)
                      })
                  })
                })
                .catch((error: Error) => {
                  console.error(error)
                  showNotification({
                    title: `${newModel.title} failed to upload!`,
                    type: 'error',
                    dismissAfter: 3000,
                  })
                  onApiBusy(false)
                })
            } else if (data.code === 500) {
              console.error(data)
              showNotification({
                title: `${foundModel.model.title} failed to upload`,
                type: 'error',
                dismissAfter: 3000,
              })
            }
          })
          .catch((error: Error) => {
            console.error(error)
            showNotification({
              title: `${foundModel.model.title} failed to upload!`,
              type: 'error',
              dismissAfter: 3000,
            })
          })
          .finally(() => {
            onApiBusy(false)
          })
      }
    },
    [isApiBusy, models, newModels, onApiBusy, showNotification],
  )

  const handleDeleteModel = useCallback(
    (id: string) => {
      if (isApiBusy) {
        showNotification({
          title: 'Please wait for the current action to complete',
          type: 'error',
          dismissAfter: 3000,
        })

        return null
      }

      const foundModel = determineModel(models, newModels, id)
      onApiBusy(true)

      if (foundModel.isNew === true) {
        setNewModels(previous =>
          [...previous].filter(model => model.id !== foundModel.model.id),
        )
        stlViewer.remove_model(foundModel.model.modelId)

        onApiBusy(false)
      } else {
        doFunctionsCall('Admin', {
          signature: 'Model-Delete',
          modelId: foundModel.model.id,
        })
          .then(data => {
            if (data.code === 200) {
              setModels(previous =>
                [...previous].filter(model => model.id !== foundModel.model.id),
              )
              stlViewer.remove_model(foundModel.model.modelId)

              showNotification({
                title: `${foundModel.model.title} deleted`,
                type: 'success',
                dismissAfter: 3000,
              })
            } else if (data.code === 500) {
              console.error(data)
              showNotification({
                title: `${foundModel.model.title} failed to delete`,
                type: 'error',
                dismissAfter: 3000,
              })
            }
          })
          .catch((error: Error) => {
            console.error(error)
            showNotification({
              title: `${foundModel.model.title} failed to delete!`,
              type: 'error',
              dismissAfter: 3000,
            })
          })
          .finally(() => {
            onApiBusy(false)
          })
      }
    },
    [isApiBusy, models, newModels, onApiBusy, showNotification],
  )

  const handleModelFileUpload = useCallback(
    (uploadEvent: ChangeEvent<HTMLInputElement>) => {
      onApiBusy(true)
      const file = uploadEvent.target.files[0]

      const newModel: DatabaseModel = {
        ...defaultDatabaseModel,
        id: `new-${generateId(20)}`,
        annotationIds: [],
        caseId: caseData.id,
        availableForDownload: false,
        colour: '#000000',
        fileName: file.name,
        filePath: '',
        fileSizeInBytes: file.size,
        fileType: file.type,
        modelId: Number.parseFloat(generateId(20, 'numeric')),
        modelPosition: deepCopy(defaultPosition),
        title: file.name,
        uploadDateTimestamp: Date.now(),
        uploadedBy: userSelector.id,
      }

      stlViewer.add_model({
        id: newModel.modelId,
        local_file: file,
      })

      setNewModels(previous => [...previous, newModel])
      onApiBusy(false)
    },
    [caseData.id, onApiBusy, userSelector?.id],
  )

  const handleAddModelClick = useCallback(() => {
    document.getElementById('file-upload-target').click()
  }, [])

  const handleViewerLoaded = useCallback(() => {
    try {
      stlViewer.set_bg_color('#f5f5f5')

      if (
        hasCompletedFirstRender === false ||
        [...models, ...newModels].length === 1
      ) {
        setHasCompletedFirstRender(true)

        stlViewer.set_grid(caseData.isGridVisible ?? true)
        stlViewer.set_camera_state(
          getSanitizedCameraPosition(caseData.sceneCameraPosition),
        )
        setTimeout(() => {
          stlViewer.set_camera_state(
            getSanitizedCameraPosition(caseData.sceneCameraPosition),
          )
        }, 1000)
      }
    } catch (error) {
      console.error(error)
    }
  }, [
    caseData.isGridVisible,
    caseData.sceneCameraPosition,
    hasCompletedFirstRender,
    models,
    newModels,
  ])

  const handleFetchModels = useCallback(() => {
    if (hasFetched === false) {
      onApiBusy(true)

      doFunctionsCall('HighestPriority', {
        signature: 'Model-GetModelsForCase',
        caseId: caseData.id,
      })
        .then(data => {
          if (data.code === 200) {
            const fetchedModels: DatabaseModel[] = JSON.parse(data.data)

            setModels(fetchedModels)

            stlViewer.add_models(
              fetchedModels.map(model => ({
                id: model.modelId,
                filename: model.filePath,
                rotationx: model.modelRotation.x,
                rotationy: model.modelRotation.y,
                rotationz: model.modelRotation.z,
                scale: model.scale,
                x: model.modelPosition.x,
                y: model.modelPosition.y,
                z: model.modelPosition.z,
                color: model.colour,
              })),
            )

            setShowGrid(caseData.isGridVisible ?? true)
            stlViewer.set_grid(caseData.isGridVisible ?? true)

            stlViewer.set_camera_state(
              getSanitizedCameraPosition(caseData.sceneCameraPosition),
            )
            setCameraPosition(
              getSanitizedCameraPosition(caseData.sceneCameraPosition),
            )

            setTimeout(() => {
              stlViewer.set_grid(caseData.isGridVisible ?? true)
              stlViewer.set_camera_state(
                getSanitizedCameraPosition(caseData.sceneCameraPosition),
              )
            }, 500)

            if (fetchedModels.length === 0) {
              setHasCompletedFirstRender(true)
            }
          } else if (data.code === 500) {
            console.error(data)
            showNotification({
              title: 'Failed to fetch models',
              type: 'error',
              dismissAfter: 3000,
            })
          }
        })
        .catch((error: Error) => {
          console.error(error)
          showNotification({
            title: 'Failed to fetch models',
            type: 'error',
            dismissAfter: 3000,
          })
        })
        .finally(() => {
          setHasFetched(true)
          onApiBusy(false)
        })
    }
  }, [
    caseData.id,
    caseData.isGridVisible,
    caseData.sceneCameraPosition,
    hasFetched,
    onApiBusy,
    showNotification,
  ])

  const handleSaveCameraPosition = useCallback(() => {
    if (hasCompletedFirstRender === false) {
      showNotification({
        title: 'Please wait for the viewer to load',
        type: 'error',
        dismissAfter: 3000,
      })

      return null
    }

    const newCameraPosition = stlViewer.get_camera_state()

    if (isCameraPositionValid(newCameraPosition) === false) {
      showNotification({
        title: 'Invalid camera position, please zoom out slightly',
        type: 'error',
        dismissAfter: 3000,
      })

      return null
    }

    setCameraPosition(newCameraPosition)
    onApiBusy(true)

    doFunctionsCall('Admin', {
      signature: 'Case-UpdateCaseCamera',
      caseId: caseData.id,
      cameraPosition: newCameraPosition,
    })
      .then(data => {
        if (data.code === 200) {
          showNotification({
            title: 'Camera position saved',
            type: 'success',
            dismissAfter: 3000,
          })
        } else if (data.code === 500) {
          console.error(data)
          showNotification({
            title: 'Failed to save camera position',
            type: 'error',
            dismissAfter: 3000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        showNotification({
          title: 'Failed to save camera position!',
          type: 'error',
          dismissAfter: 3000,
        })
      })
      .finally(() => {
        onApiBusy(false)
      })
  }, [caseData?.id, hasCompletedFirstRender, onApiBusy, showNotification])

  const handleResetCameraPosition = useCallback(() => {
    if (hasCompletedFirstRender === true) {
      stlViewer.set_camera_state(cameraPosition)
    }
  }, [cameraPosition, hasCompletedFirstRender])

  const handleToggleGrid = useCallback(() => {
    if (hasCompletedFirstRender === true) {
      const newShowGrid = !showGrid

      setShowGrid(newShowGrid)
      stlViewer.set_grid(newShowGrid)

      doFunctionsCall('Admin', {
        signature: 'Case-UpdateIsGridVisible',
        caseId: caseData.id,
        isGridVisible: newShowGrid,
      })
    }
  }, [caseData?.id, hasCompletedFirstRender, showGrid])

  const snapToCameraPosition = useCallback(
    (position: string) => {
      if (hasCompletedFirstRender === false) {
        showNotification({
          title: 'Please wait for the viewer to load',
          type: 'info',
          dismissAfter: 3000,
        })

        return null
      }

      const newCameraPosition = getSnappedCameraPosition(position)

      stlViewer.set_camera_state(newCameraPosition)
    },
    [hasCompletedFirstRender, showNotification],
  )

  useEffect(() => {
    if (!hasPluginInitialized) {
      setHasPluginInitialized(true)

      try {
        stlViewer.dispose()
      } catch (error) {
        console.error(error)
      }

      stlViewer = new StlViewer(document.getElementById('stl-viewer-target'), {
        auto_resize: true,
        canvas_height: '100%',
        canvas_width: '100%',
        allow_drag_and_drop: false,
        controls: 0,
        ready_callback: () => handleFetchModels(),
        all_loaded_callback: () => handleViewerLoaded(),
      })
    }
  }, [handleFetchModels, handleViewerLoaded, hasPluginInitialized, models])

  return (
    <Section theme={{ display: 'flex', maxWidth: 'unset' }}>
      <ModelSidebar>
        <Spacer direction="vertical" amount="20px" display="block" />
        <TextElement
          text="Case Models"
          theme="h2"
          alignment="center"
          display="block"
        />
        {hasCompletedFirstRender === false ? (
          <Loading type="wide" />
        ) : (
          <>
            <Spacer direction="vertical" amount="20px" display="block" />
            {[...models, ...newModels].length === 0 ? (
              <TextElement
                text="No models added yet"
                theme="paragraph"
                alignment="center"
                display="block"
              />
            ) : null}
            <ModelListing
              existingModels={models}
              newModels={newModels}
              hasCompletedFirstRender={hasCompletedFirstRender}
              onDataChange={(id, attribute, newValue) =>
                handleDataChange(id, attribute, newValue)
              }
              onModelPreview={(modelId, attribute, value) =>
                handleModelPreview(modelId, attribute, value)
              }
              onSaveModel={id => handleSaveModel(id)}
              onDeleteModel={id => handleDeleteModel(id)}
            />
            <Spacer direction="vertical" amount="20px" display="block" />
            <AlignmentContainer align="center" display="block">
              <Button
                text="Add Model"
                theme="secondary"
                callback={() => handleAddModelClick()}
              />
            </AlignmentContainer>
            <Spacer direction="vertical" amount="20px" display="block" />
          </>
        )}
      </ModelSidebar>
      <StlContainer>
        <StlViewerTarget id="stl-viewer-target" />
        <CameraControls>
          <CameraStateControls>
            <Button
              text="Toggle Grid"
              theme="secondary"
              callback={() => handleToggleGrid()}
              display="inline-block"
            />
            <Spacer
              direction="horizontal"
              amount="20px"
              display="inline-block"
            />
            <Button
              text="Reset View"
              theme="secondary"
              callback={() => handleResetCameraPosition()}
              display="inline-block"
            />
            <Button
              text="Save View"
              theme="secondary"
              callback={() => handleSaveCameraPosition()}
              display="inline-block"
            />
          </CameraStateControls>
          <CameraPositionsContainer>
            <CameraPositionsLabel>Snap To View</CameraPositionsLabel>
            <CameraPositions>
              <Button
                text="Front"
                theme="secondary"
                callback={() => snapToCameraPosition('front')}
                display="inline-block"
                size="small"
              />
              <Button
                text="Back"
                theme="secondary"
                callback={() => snapToCameraPosition('back')}
                display="inline-block"
                size="small"
              />
              <Button
                text="Left"
                theme="secondary"
                callback={() => snapToCameraPosition('left')}
                display="inline-block"
                size="small"
              />
              <Button
                text="Right"
                theme="secondary"
                callback={() => snapToCameraPosition('right')}
                display="inline-block"
                size="small"
              />
              <Button
                text="Top"
                theme="secondary"
                callback={() => snapToCameraPosition('top')}
                display="inline-block"
                size="small"
              />
              <Button
                text="Bottom"
                theme="secondary"
                callback={() => snapToCameraPosition('bottom')}
                display="inline-block"
                size="small"
              />
            </CameraPositions>
          </CameraPositionsContainer>
        </CameraControls>
      </StlContainer>
      <FileUploadTarget
        type="file"
        id="file-upload-target"
        accept=".stl,.obj,.3mf"
        onChange={uploadEvent => handleModelFileUpload(uploadEvent)}
      />
    </Section>
  )
}

export default CaseModels
