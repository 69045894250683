import styled from 'styled-components'
import { colours, font } from '../../_globals/theme'

const TabContainer = styled.div`
  position: relative;
`

const Tab = styled.button`
  border: none;
  border-top: 1px solid ${colours.main};
  background: transparent;
  position: relative;
  z-index: 2;
  padding: 10px 30px;
  cursor: pointer;

  &:first-of-type {
    margin-left: 10px;
    border-left: 1px solid ${colours.main};
    border-top-left-radius: 10px;
  }
  &:last-of-type {
    border-right: 1px solid ${colours.main};
    border-top-right-radius: 10px;
  }

  &.active {
    background-color: ${colours.main};
    color: white;
    font-family: ${font.bold};
    border-color: ${colours.main};

    &::before {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      width: 20px;
      height: 20px;
      background-color: ${colours.main};
      z-index: -1;
      transform: rotate(45deg) translateX(-50%);
      border-radius: 3px;
    }
  }
`

const TabRow = styled.div`
  overflow-x: auto;
  overflow-y: visible;
  white-space: nowrap;
  padding-bottom: 10px;
`

const TabContent = styled.div`
  border: none;
  border-top: 2px solid ${colours.main};
  position: relative;
  padding: 10px;
  margin-top: -10px;
  z-index: 1;
  display: none;

  &.active {
    display: block;
  }
`

export { TabContainer, Tab, TabContent, TabRow }
