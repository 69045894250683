import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getAuth, User } from 'firebase/auth'

import TopOfPage from './components/top-of-page/TopOfPage'
import Login from './pages/login/Login'
import NotFound from './pages/not-found/NotFound'
import Profile from './pages/profile/Profile'
import SideBar from './components/side-bar/SideBar'
import Help from './pages/help/Help'
import Cases from './pages/cases/Cases'
import NewCase from './pages/case-new/CaseNew'
import CasePage from './pages/case/Case'
import Clients from './pages/clients/Clients'
import ClientNew from './pages/client-new/ClientNew'
import Client from './pages/client/Client'
import Users from './pages/users/Users'
import UserPage from './pages/user/User'
import NewUser from './pages/user-new/NewUser'

import { useAppDispatch, useAppSelector } from './_globals/hooks'
import { RootState } from './_globals/state-store'
import { themeSlice } from './_globals/theme/theme-slice'
import { getEnvironmentSettings } from './_utilities/config'
import { doesExist, getWindowHeight, getWindowWidth } from './_utilities/utils'
import { getLocalStorageItem, removeLocalStorageItem } from './_data/storage'
import { userSlice } from './_globals/user/user-slice'
import { LocalUserCopy } from './_globals/user/types'
import { NotificationContext } from './_globals/notifications/notification-context'

const themeAppSelector = (state: RootState) => state.theme

function App() {
  const dispatch = useAppDispatch()
  const themeSelector = useAppSelector(themeAppSelector)
  const { showNotification } = useContext(NotificationContext)
  const [isAuthorized, setIsAuthorized] = useState<boolean>(true)

  const handleWindowResize = useCallback(() => {
    dispatch(themeSlice.actions.setWindowWidth(getWindowWidth()))
    dispatch(themeSlice.actions.setWindowHeight(getWindowHeight()))
  }, [dispatch])

  const handleOnUserAuthChange = useCallback(
    (user: User) => {
      if (!doesExist(user)) {
        showNotification({
          title: 'Access Denied',
          type: 'error',
          dismissAfter: 5000,
        })

        removeLocalStorageItem('user')
        getAuth().signOut()
        setIsAuthorized(false)
      } else {
        setIsAuthorized(true)
      }
    },
    [showNotification],
  )

  useEffect(() => {
    const appEnvironment = getEnvironmentSettings()

    // set up firebase emulator, if in local environment
    if (appEnvironment.ENV_CODE === 'local') {
      const firebaseFunctions = getFunctions()
      connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001)
    }

    // check for user in local storage and reinitialize if found
    getLocalStorageItem('user', null).then(rawUser => {
      if (rawUser) {
        const user: LocalUserCopy = JSON.parse(rawUser)

        if (user.id !== '' && user.lastSave && user.lastSave > 0) {
          const twoWeeks = 1_209_600_000

          if (Date.now() - user.lastSave < twoWeeks) {
            dispatch(userSlice.actions.reinitializeUser(user))
          } else {
            dispatch(userSlice.actions.clearUser())
          }
        } else {
          removeLocalStorageItem('user')
          dispatch(userSlice.actions.clearUser())
        }
      }
    })

    // setup firebase auth listener
    const firebaseAuth = getAuth()
    firebaseAuth.onAuthStateChanged(handleOnUserAuthChange)
  }, [dispatch, handleOnUserAuthChange])

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize])

  return (
    <Router>
      <TopOfPage />
      <SideBar hideSidebar={themeSelector.showSidebar === false}>
        {themeSelector.allowNotifications ? <ToastContainer /> : null}
        <Routes>
          <Route path="/login" element={<Login />} />
          {isAuthorized ? (
            <>
              <Route path="/" element={<Cases />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/users" element={<Users />} />
              <Route path="/user/new" element={<NewUser />} />
              <Route path="/user/:id" element={<UserPage />} />
              <Route path="/cases" element={<Cases />} />
              <Route path="/case/:id" element={<CasePage />} />
              <Route path="/case/new" element={<NewCase />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/client/new" element={<ClientNew />} />
              <Route path="/client/:id" element={<Client />} />
              <Route path="/help" element={<Help />} />

              <Route path="/home" element={<Navigate to="/" />} />
              <Route path="/not-found" element={<NotFound />} />
              <Route path="*" element={<Navigate to="/not-found" />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/login" />} />
          )}
        </Routes>
      </SideBar>
    </Router>
  )
}

export default App
