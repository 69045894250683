import styled from 'styled-components'

import { colours, font, fontSize } from '../../_globals/theme'

const ContentContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

const Paragraph = styled.p`
  line-height: 1.2rem;
  margin-bottom: 1rem;

  &.soft-return {
    margin-bottom: 0;
  }
`

const Bold = styled.span`
  font-weight: bold;
`

const Link = styled.a`
  color: ${colours.highlight};
  text-decoration: none;
  cursor: pointer;
  padding: 0 0.3rem;
`

const ImageElement = styled.img`
  max-width: 100%;
  margin: 10px auto;
  display: block;
  border: 1px solid ${colours.background};
  box-shadow: 2px 2px 5px 0 #bdbbbb;
  border-radius: 10px;
`

const Heading = styled.h2`
  font-family: ${font.bold};
  font-size: ${fontSize.title};
  margin-top: 50px;
`

export { ContentContainer, Paragraph, Bold, Link, ImageElement, Heading }
