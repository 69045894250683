import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import Button from '../../components/button/Button'
import Loading from '../../components/loading/Loading'
import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import Table from '../../components/table/Table'
import { TableData } from '../../components/table/types'
import TextElement from '../../components/text/Text'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import { NotificationContext } from '../../_globals/notifications/notification-context'
import { DatabaseClientUser } from '../../_types/client'

/**
 * Clients page
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <Clients />
 * ```
 */
const Clients = (): JSX.Element => {
  const navigation = useNavigate()
  const { showNotification } = useContext(NotificationContext)
  const [criticalError, setCriticalError] = useState<string>('')
  const [clients, setClients] = useState<DatabaseClientUser[]>([])
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)

  const handlePageReady = useCallback(() => {
    setIsApiBusy(true)

    doFunctionsCall('Admin', {
      signature: 'Client-GetAll',
    })
      .then(data => {
        if (data.code === 200) {
          const fetchedClients: DatabaseClientUser[] = JSON.parse(data.data)

          setClients(() => [...fetchedClients])
        } else if (data.code === 500) {
          console.error(data)
          setCriticalError('Failed to fetch clients')

          showNotification({
            title: 'Failed to fetch clients!',
            type: 'error',
            dismissAfter: 3000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        setCriticalError('Failed to fetch clients')

        showNotification({
          title: 'Failed to fetch clients!',
          type: 'error',
          dismissAfter: 3000,
        })
      })
      .finally(() => {
        setIsApiBusy(false)
      })
  }, [showNotification])

  const handleViewClient = useCallback(
    (clientId: string) => {
      navigation(`/client/${clientId}`)
    },
    [navigation],
  )

  const handleAddNewClient = useCallback(() => {
    navigation('/client/new')
  }, [navigation])

  const tableHeaders = useMemo<TableData[]>(
    () => [
      { content: 'Name' },
      { content: 'Email' },
      { content: 'Noun' },
      { content: 'Actions' },
    ],
    [],
  )

  const tableContent = useMemo<TableData[][]>(() => {
    const data: TableData[][] = []

    clients.forEach(clientData => {
      const row: TableData[] = [
        { content: clientData.displayName },
        { content: clientData.email },
        { content: clientData.entityNoun },
        {
          content: (
            <Button
              callback={() => handleViewClient(clientData.id)}
              text="View Client"
              theme="secondary"
              display="inline-block"
              size="small"
            />
          ),
        },
      ]

      data.push(row)
    })

    return data
  }, [clients, handleViewClient])

  return (
    <PageContainer
      height="initial"
      width="initial"
      offsetBottom="10px"
      offsetLeft="10px"
      offsetRight="10px"
      offsetTop="10px"
      offsetMode="padding"
      allowedRoles={['super-admin', 'admin']}
      allowNotifications={true}
      showSidebar={true}
      allowUnauthenticated={false}
      pageError={criticalError}
      onPageReady={() => handlePageReady()}>
      <TextElement
        text="Clients"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      {isApiBusy ? (
        <Loading type="large" />
      ) : (
        <>
          <AlignmentContainer align="center" display="block">
            <Button
              text="Add New Client"
              theme="main"
              callback={() => handleAddNewClient()}
            />
          </AlignmentContainer>
          <Spacer direction="vertical" amount="20px" display="block" />
          <Table
            alignment="center"
            headerDirection="horizontal"
            size="large"
            width="800px"
            headers={tableHeaders}
            content={tableContent}
          />
        </>
      )}
    </PageContainer>
  )
}

export default Clients
