import styled from 'styled-components'

const ButtonContainer = styled.div`
  display: flex;
  z-index: 2;
  position: relative;
  gap: 20px;
`

export { ButtonContainer }
