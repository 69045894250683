import styled from 'styled-components'

const Container = styled.label`
  display: inline-block;
`

const Checkbox = styled.input`
  display: inline;
`

const CheckboxText = styled.p`
  display: inline;
  padding-left: 10px;
  color: ${props => props.theme.colour};
`
CheckboxText.defaultProps = {
  theme: {
    colour: 'black',
  },
}

export { Container, Checkbox, CheckboxText }
