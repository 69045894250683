import { AppEnvironment } from './types'

export const Environment: AppEnvironment = {
  ENV_CODE: 'prod',
  ENV_NAME: 'Production',
  consoleLogLevel: 1,
  FUNCTIONS_REGION: 'northamerica-northeast1',
  config: {
    apiKey: 'AIzaSyCXVR_fDb1LOXegtNwNv_mbL79DxZ_3Jxg',
    authDomain: 'd-viewer-b6081.firebaseapp.com',
    projectId: 'd-viewer-b6081',
    storageBucket: 'd-viewer-b6081.appspot.com',
    messagingSenderId: '253425981755',
    appId: '1:253425981755:web:d6405a10905670e4abff1e',
    measurementId: 'G-RNJ503YHES',
  },
}
