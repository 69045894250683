import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import { doesExist } from '../../_utilities/utils'
import InputContainer from '../input-container/InputContainer'
import InputLabel from '../input-label/InputLabel'
import { Checkbox, CheckboxText, Container } from './styled'
import { CheckboxInputProps } from './types'

/**
 * A checkbox input. You check the box, this will affect the input
 *
 * @param {CheckboxInputProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <CheckboxInput
 *  key={question.id}
 *  colourMode="dark"
 *  checkboxText={question.subLabel}
 *  checkboxIdentifier={`${question.companyId}-${question.id}`}
 *  callback={(newValue: boolean) => handleResponse(newValue)}
 *  />
 * ```
 */
const CheckboxInput = ({
  checkboxIdentifier,
  label,
  value,
  initialValue,
  checkboxText,
  callback,
  elementId,
  isDisabled,
  display,
  offsetBottom,
  offsetLeft,
  offsetRight,
  offsetTop,
  offsetMode,
  colourMode,
}: CheckboxInputProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(initialValue)

  const inputTheme = useMemo(() => {
    const theme: DefaultTheme = {
      colour: colourMode === 'dark' ? 'white' : 'black',
    }

    return theme
  }, [colourMode])

  const handleChange = useCallback(
    (newValue: React.ChangeEvent<HTMLInputElement>) => {
      const sanitizedValue = newValue.target.checked === true

      setIsChecked(sanitizedValue)

      if (callback) {
        // eslint-disable-next-line callback-return
        callback(sanitizedValue)
      }
    },
    [callback],
  )

  useEffect(() => {
    const sanitizedValue = doesExist(value) && value === true
    setIsChecked(sanitizedValue)
  }, [value])

  return (
    <InputContainer
      display={display}
      elementId={elementId}
      offsetBottom={offsetBottom}
      offsetLeft={offsetLeft}
      offsetRight={offsetRight}
      offsetTop={offsetTop}
      offsetMode={offsetMode}>
      {label !== '' ? (
        <InputLabel
          label={label}
          colour={colourMode === 'dark' ? 'white' : 'black'}
        />
      ) : null}
      <Container htmlFor={`relation-cb-${checkboxIdentifier}`}>
        <Checkbox
          type="checkbox"
          id={`relation-cb-${checkboxIdentifier}`}
          onChange={newValue => handleChange(newValue)}
          checked={isChecked}
          disabled={isDisabled}
        />
        {checkboxText !== '' ? (
          <CheckboxText theme={inputTheme}>{checkboxText}</CheckboxText>
        ) : null}
      </Container>
    </InputContainer>
  )
}

export default CheckboxInput
