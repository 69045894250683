import React from 'react'

import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'
import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import {
  Bold,
  ContentContainer,
  Heading,
  ImageElement,
  Link,
  Paragraph,
} from './styled'

const Help = (): JSX.Element => (
  <PageContainer
    offsetLeft="20px"
    offsetRight="20px"
    offsetBottom="20px"
    offsetTop="20px"
    offsetMode="padding"
    width="unset"
    height="unset"
    allowedRoles={['super-admin', 'admin', 'designer']}
    allowUnauthenticated={false}
    allowNotifications={true}
    showSidebar={true}>
    <TextElement
      text="Help"
      theme="h1"
      alignment="center"
      colour="black"
      display="block"
    />
    <AlignmentContainer align="center" display="block">
      <Heading id="top">Hello and welcome to Anatomiz3D Viewer!</Heading>
    </AlignmentContainer>
    <Spacer direction="vertical" amount="20px" display="block" />
    <ContentContainer>
      <Paragraph>
        Please use this section to familiarize yourself with the layout and all
        the actions that can be performed.
      </Paragraph>
      <Paragraph>
        Incase you have any additional questions that were not answered in this
        section, please feel free to reach out to your senior or write to
        <Link href="mailto:info@anatomiz3d.com">info@anatomiz3d.com</Link>
        with your query.
      </Paragraph>
      <Heading id="home">&apos;Home&apos; Page/&apos;Cases&apos; Tab</Heading>
      <Paragraph>
        Upon sign in, you will be directed to the Cases Page, which has the
        following information.
      </Paragraph>
      <Paragraph>
        <Bold>Add New Case</Bold> - This will allow you to proceed to the 3D
        upload and case details section.
      </Paragraph>
      <Spacer direction="vertical" amount="10px" display="block" />
      <Paragraph className="soft-return">
        <Bold>Columns with the following information:</Bold>
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Title</Bold> - Name given to the Case
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Internal ID</Bold> - This is an auto generated ID specific to
        every new case added
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Anatomiz3D Case ID</Bold> - Anatomiz3D reference case ID
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Client Reference ID</Bold> - Any reference ID provided by Client
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Status</Bold> - Open (Case under Process), Approved (Case Approved
        by client)
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Owner</Bold> - User who created the case
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Created</Bold> - Date of Case Upload
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Client</Bold> - Assigned to a user who is a client
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Archived</Bold> - Whether the case has been archived or not
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>View Case</Bold> - Click on View to enter the 3D Viewer for the
        specific case.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fhome.png?alt=media&token=18bba11c-6e2a-46b6-a496-510c3a91e95c" />
      <Heading id="user">&apos;User&apos; Tab - Add a new user</Heading>
      <Paragraph>
        To add a new user, click on &apos;User&apos; tab on the left.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fuser-tab.png?alt=media&token=b05ffa45-afb6-4604-bf7f-07ca8fb3839a" />
      <Paragraph>Click on &apos;Add New User&apos;</Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fadd-user.png?alt=media&token=320e9340-f94a-465a-afe0-6fe32a8d659d" />
      <Paragraph>Fill in the details and click on Generate Password.</Paragraph>
      <Paragraph>
        <Bold>
          Copy the password and share it with the user. This is very important.
        </Bold>
      </Paragraph>
      <Paragraph>Once copied, click on create new user.</Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fnew-user.png?alt=media&token=fa77967c-7b3e-4ee8-92f7-caa75af84b65" />
      <Paragraph>
        At this next step, you will have to assign a role to the user.
      </Paragraph>
      <Paragraph>
        <Bold>User</Bold> - Client, who can only view and not make any changes.
        In case of adding a client, you will have to take an additional step of
        adding them to the client database. Explained in the next step.
      </Paragraph>
      <Paragraph>
        <Bold>Designer</Bold> - Colleague who can also make changes with you.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fuser-role.png?alt=media&token=844442f4-690e-4009-8829-b6ef0ffc39ec" />
      <Heading id="client">
        &apos;Clients&apos; Tab - Assigning Client Role
      </Heading>
      <Paragraph>
        Click on <Bold>&apos;Clients&apos;</Bold> and click on{' '}
        <Bold>&apos;Add New Client&apos;</Bold>
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fclients.png?alt=media&token=f6ed7a9c-b756-47d8-970f-03975644366e" />
      <Paragraph>
        Select the User email address from the drop down list box and assign a
        category
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fnew-client.png?alt=media&token=29dfaf37-e3c8-4ad5-b25a-ab37801d4bf8" />
      <Paragraph>
        The client will now be visible under clients section.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fclient-done.png?alt=media&token=8058bc97-ab7d-41b1-ace5-6570d7617268" />
      <Heading id="new-case">Adding a New Case</Heading>
      <Paragraph>
        Go to the <Bold>&apos;Cases&apos;</Bold> page and click on{' '}
        <Bold>&apos;Add New Case&apos;</Bold>
        Zoom image.png
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fnew-case.png?alt=media&token=4cb8a4f3-bd35-424f-8762-a0f5d38c83a5" />
      <Paragraph>
        Fill in all the required information and click on{' '}
        <Bold>&apos;Create New Case&apos;</Bold>
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fcreate-case.png?alt=media&token=a0bf4487-da64-4548-a0e6-71b837af2c72" />
      <Paragraph>
        You will now be directed to a page where you can upload models, one at a
        time. Add multiple models to the same case by repeating the steps below.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fmodel-upload.png?alt=media&token=01e40e71-f273-4d85-adb4-6edf41625dc4" />
      <Paragraph>
        Choose a model and assign its various properties through the options
        available
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Model Colour</Bold> - Choose from 8 preset colours - Bone, Tumour,
        Artery, Vein, Implant, Screws, Skin, Soft Tissue or choose a colour of
        your preference if there are components outside the ones mentioned
        above.
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Position X, Y, Z</Bold> - Adjust as per coordinate system
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Rotation X, Y, Z</Bold> - Adjust as per coordinate system
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Scale</Bold> - Change the scale of the 3D Model (Please avoid this
        unless absolutely necessary)
      </Paragraph>
      <Paragraph>
        <Bold>Opacity</Bold> - Adjust the opacities as per need.
      </Paragraph>
      <Paragraph>
        Click on <Bold>&apos;Save&apos;</Bold> once the model is appropriate.
        The model will be added. If you make any changes at any point, click on{' '}
        <Bold>&apos;Save&apos;</Bold>
        again and the model will be updated. Click on{' '}
        <Bold>&apos;Delete&apos;</Bold>
        if you want to remove the model
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fmodel-edit.png?alt=media&token=525fb6ac-94ab-46ed-9415-0425529b6bc1" />
      <Paragraph>
        Click on <Bold>&apos;Details&apos;</Bold> Tab to add in the necessary
        information. Also generate and store a shareable link for the client.
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Title</Bold> - A preferred name for the case to easily identify it
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Case ID</Bold> - This is auto generated and is unique to each
        case. You will not be able to change it.
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Status </Bold> - Choose between <Bold>&apos;Open&apos;</Bold>,{' '}
        <Bold>&apos;Approved&apos;</Bold> or <Bold>&apos;Completed&apos;</Bold>.
        <Spacer direction="vertical" amount="5px" display="block" />
        Please note - Client will have a button to <Bold>Approve</Bold> a case.
        You will have to monitor the change of status.
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Anatomiz3D Application Engineer</Bold> - The primary designer for
        the case. This will be auto filled from previous input.
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Assigned Client</Bold> - Choose a Client from the database already
        added
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Assigned Patient</Bold> - This is optional. The choices will be
        from the Client Database.
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Anatomiz3D Case ID</Bold> - This will be auto filled from previous
        input.
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Client Reference ID</Bold> - This will be auto filled from
        previous input.
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Shareable Link</Bold> - This will be auto generated. Copy the link
        and store it in order to share with the client
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Case Details</Bold> - Any information or case description you
        would like to provide to support the findings of the case.
      </Paragraph>
      <Paragraph>
        <Bold>Separate Details</Bold> - Click on this if you would like to add
        different information (without any internal comments) which will be
        visible to the user.
      </Paragraph>
      <Paragraph>
        Click <Bold>&apos;Save&apos;</Bold>
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fcase-details.png?alt=media&token=3e7b0897-721b-4f22-acc6-d6f7e36966c4" />
      <Paragraph>
        Go to <Bold>&apos;Comments&apos;</Bold> Tab to add any comments
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fcase-comments.png?alt=media&token=b1f1b32e-4e54-4036-88f8-a924028078f5" />
      <Paragraph>
        Go to <Bold>&apos;Activity Log&apos;</Bold> Tab to view the history of
        the additions, changes, updates made to the case.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Factivity-log.png?alt=media&token=734ac43e-6640-4683-b234-06e45ab4f8ca" />
      <Paragraph>
        Go to <Bold>&apos;Archive Case&apos;</Bold> Tab in order to fill in the
        details and Archive the case. This is done when a case is considered
        complete, rejected or is no longer needed as part of on-going cases.
        This will not delete the case, but only store it in Archives. It will be
        visible to the Super Admin.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Farchive-case.png?alt=media&token=fafe9ae2-3e15-440d-a034-7ec1d57370ad" />
      <Heading id="edit-case">Editing an existing Case</Heading>
      <Paragraph>
        Go to <Bold>&apos;Cases&apos;</Bold> page and click on{' '}
        <Bold>&apos;View Case&apos;</Bold> for the case you want to edit. Follow
        the usual process as observed above, similar to adding a new case.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fedit-case.png?alt=media&token=34626a91-2b0a-4317-bd24-5e9140d93ad7" />
      <Heading id="profile">&apos;Profile&apos; Tab</Heading>
      <Paragraph>
        Click on the &apos;Profile&apos; Tab to request a password reset and to
        update profile information.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fadmin-portal%2Fprofile.png?alt=media&token=0b895b64-5aea-4b3f-a841-a66d28df2783" />
    </ContentContainer>
  </PageContainer>
)

export default Help
