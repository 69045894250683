import React, { useContext, useState, useCallback } from 'react'
import { useParams } from 'react-router'

import { NotificationContext } from '../../_globals/notifications/notification-context'
import { useAppSelector } from '../../_globals/hooks'
import { deepCopy } from '../../_utilities/utils'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import PageContainer from '../../components/page-container/PageContainer'
import PageBackButton from '../../components/page-back-button/PageBackButton'
import TextElement from '../../components/text/Text'
import Spacer from '../../components/spacer/Spacer'
import Loading from '../../components/loading/Loading'
import { DatabaseCase } from '../../_types/case'
import Tabs from '../../components/tabs/Tabs'
import { RootState } from '../../_globals/state-store'
import { CasePageParams } from './types'
import CaseDetailsSection from './sections/details/CaseDetails'
import { gatherDetails } from './helpers'
import CaseComments from './sections/comments/CaseComments'
import CaseAuditLog from './sections/audit-log/CaseLog'
import CaseModels from './sections/models/CaseModels'
import CaseArchive from './sections/archive/CaseArchive'

const userAppSelector = (state: RootState) => state.user

/**
 * Case page
 *
 * @returns {JSX.Element}
 *
 * ```tsx
 * <CasePage />
 * ```
 */
const CasePage = (): JSX.Element => {
  const { id } = useParams<CasePageParams>()
  const { showNotification } = useContext(NotificationContext)
  const userSelector = useAppSelector(userAppSelector)
  const [criticalError, setCriticalError] = useState<string>('')
  const [caseData, setCaseData] = useState<DatabaseCase>()
  const [backupCase, setBackupCase] = useState<DatabaseCase>()
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)

  const fetchData = useCallback((fetchedCase: DatabaseCase) => {
    setCaseData(() => deepCopy(fetchedCase))
    setBackupCase(() => deepCopy(fetchedCase))

    setIsApiBusy(false)
  }, [])

  const handlePageReady = useCallback(() => {
    doFunctionsCall('HighestPriority', {
      signature: 'Case-Get',
      caseId: id,
    })
      .then(data => {
        if (data.code === 200) {
          const fetchedCase: DatabaseCase = JSON.parse(data.data)
          fetchData(fetchedCase)
        } else if (data.code === 500) {
          console.error(data)
          setCriticalError('Could not fetch case')

          showNotification({
            title: 'Failed to fetch case',
            type: 'error',
            dismissAfter: 3000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        setCriticalError('Could not fetch case')

        showNotification({
          title: 'Failed to fetch case',
          type: 'error',
          dismissAfter: 3000,
        })
      })
  }, [fetchData, showNotification, id])

  const handleSaveDetails = useCallback(() => {
    const currentData = deepCopy(caseData)
    setBackupCase(previous => ({ ...previous, ...gatherDetails(currentData) }))
  }, [caseData])

  const handleUpdateDetails = useCallback((newCaseData: DatabaseCase) => {
    setCaseData(previous => ({
      ...previous,
      ...gatherDetails(newCaseData),
    }))
  }, [])

  const handleRevertDetails = useCallback(() => {
    setCaseData(previous => ({
      ...previous,
      ...gatherDetails(backupCase),
    }))

    showNotification({
      title: 'Case Details have been reverted',
      type: 'success',
      dismissAfter: 2500,
    })
  }, [showNotification, backupCase])

  return (
    <PageContainer
      height="initial"
      width="initial"
      offsetBottom="10px"
      offsetLeft="10px"
      offsetRight="10px"
      offsetTop="10px"
      offsetMode="padding"
      allowNotifications={true}
      showSidebar={true}
      allowUnauthenticated={false}
      allowedRoles={['super-admin', 'admin', 'designer']}
      pageError={criticalError}
      onPageReady={() => handlePageReady()}>
      <PageBackButton
        text="Back to Cases"
        colourMode="light"
        urlOnClick="/cases"
      />
      <TextElement
        text={caseData?.title}
        theme="h1"
        alignment="center"
        colour="black"
        display="block"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      {caseData ? (
        <Tabs
          structure={[
            {
              id: 'models',
              title: `Models (${caseData.modelFiles.length})`,
              content: (
                <CaseModels
                  caseData={caseData}
                  isApiBusy={isApiBusy}
                  onApiBusy={isBusy => setIsApiBusy(isBusy)}
                  onDataChange={newCaseData => {
                    setCaseData(previous => ({
                      ...previous,
                      modelFiles: newCaseData,
                    }))
                  }}
                />
              ),
            },
            {
              id: 'details',
              title: 'Details',
              content: (
                <CaseDetailsSection
                  caseData={caseData}
                  isApiBusy={isApiBusy}
                  onApiBusy={isBusy => setIsApiBusy(isBusy)}
                  onRevertData={() => handleRevertDetails()}
                  onDataChange={newCaseData => handleUpdateDetails(newCaseData)}
                  onSaveData={() => handleSaveDetails()}
                />
              ),
            },
            {
              id: 'comments',
              title: `Comments (${caseData.commentIds.length})`,
              content: <CaseComments caseData={caseData} />,
            },
            {
              id: 'audit-log',
              title: 'Activity Log',
              content: <CaseAuditLog caseData={caseData} />,
            },
            userSelector.role === 'admin' || userSelector.role === 'super-admin'
              ? {
                  id: 'archive',
                  title: 'Archive Case',
                  content: (
                    <CaseArchive
                      caseData={caseData}
                      isApiBusy={isApiBusy}
                      onApiBusy={isBusy => setIsApiBusy(isBusy)}
                    />
                  ),
                }
              : null,
          ]}
        />
      ) : (
        <Loading type="large" />
      )}
      <Spacer direction="vertical" amount="20px" display="block" />
    </PageContainer>
  )
}

export default CasePage
