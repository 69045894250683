import React, { useMemo, useState } from 'react'

import { useAppSelector } from '../../_globals/hooks'
import { RootState } from '../../_globals/state-store'
import { getEnvironmentSettings } from '../../_utilities/config'
import { Content, DeveloperContainerContainer, Header, Hider } from './styled'
import { DeveloperContainerProps } from './types'

const environment = getEnvironmentSettings()
const userAppSelector = (state: RootState) => state.user

const DeveloperContainer = ({
  mode,
  children,
}: DeveloperContainerProps): JSX.Element => {
  const userSelector = useAppSelector(userAppSelector)
  const [forceHide, setForceHide] = useState<boolean>(false)

  const shouldDisplay = useMemo(() => {
    if (forceHide) {
      return false
    }

    const allowedRoles = ['super-admin', 'admin']

    if (mode === 'user' || mode === 'either') {
      return allowedRoles.includes(userSelector.role)
    } else if (mode === 'environment' || mode === 'either') {
      return environment.ENV_CODE !== 'prod'
    }

    return false
  }, [mode, userSelector?.role, forceHide])

  return shouldDisplay ? (
    <DeveloperContainerContainer>
      <Header>
        Anatomiz3D Only <Hider onClick={() => setForceHide(true)}>hide</Hider>
      </Header>
      <Content>{children}</Content>
    </DeveloperContainerContainer>
  ) : null
}

export default DeveloperContainer
