import { defaultCameraPosition } from '../../../../_defaults/model'
import { CameraState, DatabaseModel } from '../../../../_types/model'
import { deepCopy } from '../../../../_utilities/utils'

/**
 * Determine whether a model is new or existing, and return the model data
 *
 * @param {DatabaseModel[]} existingModels The existing models
 * @param {DatabaseModel[]} newModels The new models
 * @param {string} modelId The id of the model to determine
 * @returns {{isNew: boolean, model: DatabaseModel}} Whether the model is new, and the model data
 *
 * @example
 * ```ts
 * const { isNew, model } = determineModel(existingModels, newModels, modelId)
 * ```
 */
const determineModel = (
  existingModels: DatabaseModel[],
  newModels: DatabaseModel[],
  modelId: string,
) => {
  const existingModel = existingModels.find(
    modelData => modelData.id === modelId,
  )
  const newModel = newModels.find(modelData => modelData.id === modelId)

  return {
    isNew: !existingModel,
    model: existingModel || newModel,
  }
}

/**
 * Check whether a camera position is valid
 *
 * @param {CameraState} cameraPosition The camera position to check
 * @returns {boolean} Whether the camera position is valid
 *
 * @example
 * ```ts
 * const isValid = isCameraPositionValid(cameraPosition)
 * ```
 */
const isCameraPositionValid = (cameraPosition: CameraState) =>
  !(
    Math.abs(cameraPosition.position.x) < 10 &&
    Math.abs(cameraPosition.position.y) < 10 &&
    Math.abs(cameraPosition.position.z) < 10
  )

/**
 * Sanitize a camera position
 *
 * @param {CameraState} cameraPosition The camera position to sanitize
 * @returns {CameraState} The sanitized camera position
 *
 * @example
 * ```ts
 * const sanitizedCameraPosition = getSanitizedCameraPosition(cameraPosition)
 * ```
 */
const getSanitizedCameraPosition = (cameraPosition: CameraState): CameraState =>
  isCameraPositionValid(cameraPosition)
    ? cameraPosition
    : {
        ...cameraPosition,
        position: { x: 50, y: 50, z: 50 },
        target: { x: 0, y: 0, z: 0 },
        up: { x: 0, y: 1, z: 0 },
      }

/**
 * Get a camera position snapped to a specific position
 *
 * @param {string} position The position to snap to
 * @returns {CameraState} The snapped camera position
 *
 * @example
 * ```ts
 * const snappedCameraPosition = getSnappedCameraPosition('front')
 * ```
 */
const getSnappedCameraPosition = (position: string): CameraState => {
  const newCameraPosition: CameraState = deepCopy(defaultCameraPosition)

  newCameraPosition.target = {
    x: 0,
    y: 0,
    z: 0,
  }
  newCameraPosition.up = {
    x: 0,
    y: 1,
    z: 0,
  }

  if (position === 'front') {
    newCameraPosition.position = {
      x: 200,
      y: 0,
      z: 0,
    }
  } else if (position === 'back') {
    newCameraPosition.position = {
      x: -200,
      y: 0,
      z: 0,
    }
  } else if (position === 'left') {
    newCameraPosition.position = {
      x: 0,
      y: 0,
      z: 200,
    }
  } else if (position === 'right') {
    newCameraPosition.position = {
      x: 0,
      y: 0,
      z: -200,
    }
  } else if (position === 'top') {
    newCameraPosition.position = {
      x: 0,
      y: 200,
      z: 0,
    }
  } else if (position === 'bottom') {
    newCameraPosition.position = {
      x: 0,
      y: -200,
      z: 0,
    }
  }

  return newCameraPosition
}

export {
  determineModel,
  isCameraPositionValid,
  getSanitizedCameraPosition,
  getSnappedCameraPosition,
}
