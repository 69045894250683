import React, { Fragment, useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import Spacer from '../spacer/Spacer'
import TextElement from '../text/Text'
import { generateId } from '../../_utilities/utils'
import { PrettyFormContainerProps } from './types'
import {
  Container,
  InfoContainer,
  ContentContainer,
  TagContainer,
  Tag,
} from './styled'

/**
 * A not-totally-ugly looking container to stuff form input fields into
 *
 * @param {PrettyFormContainerProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <PrettyFormContainer
 *  title="Is Archived"
 *  tags={['private', 'optional']}
 *  description={['Hidden, but more permanent.']}
 *  formId="isArchived"
 *  formContent={
 *    <p>Oh my goodness, I'm an example!</p>
 *  }
 * />
 * ```
 */
const PrettyFormContainer = ({
  title,
  description,
  formId,
  formContent,
  direction,
  tags,
}: PrettyFormContainerProps): JSX.Element => {
  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      display: direction === 'horizontal' ? 'block' : 'flex',
    }

    return theme
  }, [direction])

  const contentTheme = useMemo(() => {
    const theme: DefaultTheme = {
      display: direction === 'horizontal' ? 'block' : 'inline-block',
      maxWidth: direction === 'horizontal' ? 'calc(100% - 20px)' : '300px',
    }

    return theme
  }, [direction])

  const formattedTags = useMemo(
    () =>
      tags && tags.length > 0
        ? tags
            .sort((a, b) => {
              if (a > b) {
                return 1
              } else if (a < b) {
                return -1
              }

              return 0
            })
            .map(tag => {
              switch (tag) {
                case 'optional':
                  return (
                    <Tag key={tag} className="optional">
                      Optional
                    </Tag>
                  )
                case 'required':
                  return (
                    <Tag key={tag} className="required">
                      Required
                    </Tag>
                  )
                case 'private':
                  return (
                    <Tag key={tag} className="private">
                      Internal Only
                    </Tag>
                  )
                case 'public':
                  return (
                    <Tag key={tag} className="public">
                      Publicly Visible
                    </Tag>
                  )
                default:
                  return <Tag key={tag}>{tag}</Tag>
              }
            })
        : [],
    [tags],
  )

  return (
    <Container id={formId} theme={containerTheme}>
      <InfoContainer theme={contentTheme}>
        <TextElement text={title} theme="h3" colour="black" display="block" />
        {tags && tags.length > 0 ? (
          <TagContainer>{formattedTags}</TagContainer>
        ) : null}
        <Spacer direction="vertical" amount="10px" display="block" />
        {description.map(line => (
          <Fragment key={line === '' ? generateId(5) : line.toLowerCase()}>
            <Spacer direction="vertical" amount="10px" display="block" />
            <TextElement
              text={line}
              theme="paragraph"
              colour="black"
              display="block"
            />
          </Fragment>
        ))}
      </InfoContainer>
      <ContentContainer theme={contentTheme}>{formContent}</ContentContainer>
    </Container>
  )
}

export default PrettyFormContainer
