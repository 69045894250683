import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactModal from 'react-modal'
import Spacer from '../spacer/Spacer'

import TextElement from '../text/Text'
import { CloseButton, ContentContainer } from './styled'
import { ModalProps } from './types'

/**
 * The thing that pops up!
 *
 * @param {ModalProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <Modal
 *  id="location"
 *  title="Your Location"
 *  isOpen={themeSelector.isLocationModalOpen === true}
 *  allowBackgroundClick={true}
 *  showCloseButton={true}
 *  onClose={() => handleModalClose()}
 *  content={
 *    <p>I'm trapped in a modal! Help!</p>
 *  }
 * />
 * ```
 */
const Modal = ({
  id,
  content,
  isOpen,
  title,
  maxWidth,
  allowBackgroundClick,
  showCloseButton,
  onClose,
}: ModalProps): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const sanitizedId = useMemo(() => `modal-${id}`, [id])

  const handleOnClose = useCallback(() => {
    if (onClose) {
      onClose()
    }

    setShowModal(false)
  }, [onClose])

  useEffect(() => {
    ReactModal.setAppElement('#root')
  }, [])

  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen])

  useEffect(() => {
    const modalElement = document.getElementById(sanitizedId)

    if (modalElement) {
      modalElement.style.maxWidth = maxWidth ?? '500px'
    }
  }, [maxWidth, sanitizedId])

  return (
    <ReactModal
      id={sanitizedId}
      isOpen={showModal}
      style={{ content: { maxWidth: maxWidth ?? '500px' } }}
      onRequestClose={() => handleOnClose()}
      shouldCloseOnOverlayClick={allowBackgroundClick !== false}>
      <ContentContainer>
        {showCloseButton === true ? (
          <CloseButton onClick={() => handleOnClose()}>Cancel</CloseButton>
        ) : null}
        {title && title !== '' ? (
          <>
            <TextElement
              text={title}
              theme="h1"
              alignment="left"
              colour="black"
              display="block"
              width="100%"
            />
            <Spacer direction="vertical" amount="20px" display="block" />
          </>
        ) : null}
        {content}
      </ContentContainer>
    </ReactModal>
  )
}

export default Modal
