import styled from 'styled-components'

import { colours, sidebarSize } from '../../_globals/theme'

const SideBarContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
`

const ContentContainer = styled.div`
  flex: 1;
  width: ${props => props.theme.width};
  transition: width 0.3s;
  max-height: 100vh;
  overflow-y: auto;
`
ContentContainer.defaultProps = {
  theme: {
    width: `calc(100% - ${sidebarSize.collapsed})`,
  },
}

const NavigationContainer = styled.nav`
  display: block;
  width: ${props => props.theme.width};
  transition: width 0.3s;
  border-right: 2px solid ${colours.highlight};
  border-bottom: 2px solid ${colours.highlight};
  border-bottom-right-radius: 25px;
  overflow: hidden;
  position: relative;
`
NavigationContainer.defaultProps = {
  theme: {
    width: sidebarSize.collapsed,
  },
}

const NavigationScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 2px);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  background-color: rgb(217, 217, 255);
`

const ToggleSidebarButton = styled.button`
  cursor: pointer;
  display: block;
  background: none;
  border: none;
  width: 100%;
  height: 40px;
`

const LogoContainer = styled.div`
  width: ${props => props.theme.width};
`
LogoContainer.defaultProps = {
  theme: {
    width: '100%',
  },
}

const SiteLogo = styled.img`
  display: block;
  max-width: calc(100% - 10px);
  padding: 5px;
  cursor: pointer;
  margin: 20px auto;
  height: 35px;
`

const NavigationItemContainer = styled.div`
  height: calc(100% - 115px);
`

export {
  ContentContainer,
  SideBarContainer,
  NavigationContainer,
  ToggleSidebarButton,
  SiteLogo,
  NavigationScrollContainer,
  LogoContainer,
  NavigationItemContainer,
}
