import styled from 'styled-components'

const Section = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: auto;
  display: ${props => props.theme.display};
  flex-wrap: wrap;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`
Section.defaultProps = {
  theme: {
    display: 'block',
    maxWidth: '1000px',
  },
}

export { Section }
