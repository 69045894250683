import styled from 'styled-components'

import { colours } from '../../_globals/theme'

const DeveloperContainerContainer = styled.div`
  border-radius: 5px;
  max-width: 600px;
  margin: 20px auto;
`

const Header = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${colours.main};
  color: white;
  padding: 10px;
  text-align: center;
  position: relative;
`

const Hider = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  color: white;
  padding: 12px;
  cursor: pointer;
`

const Content = styled.div`
  padding: 20px;
  border: 2px solid ${colours.main};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`

export { DeveloperContainerContainer, Header, Content, Hider }
