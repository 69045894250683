import React from 'react'

import { Top } from './styled'

/**
 * A standalone version of <PageAnchor id="top-of-page" />
 *
 * @returns {JSX.Element}
 *
 * ```tsx
 * <TopOfPage />
 * ```
 */
const TopOfPage = (): JSX.Element => <Top id="top-of-page" />

export default TopOfPage
