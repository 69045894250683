import styled from 'styled-components'

const Container = styled.div`
  display: ${props => props.theme.display};
  text-align: ${props => props.theme.justify};
  width: ${props => props.theme.width};
`
Container.defaultProps = {
  theme: {
    display: 'block',
    justify: 'center',
    width: '100%',
  },
}

export { Container }
