import React, { useContext, useState, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { NotificationContext } from '../../_globals/notifications/notification-context'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import Button from '../../components/button/Button'
import { TableData } from '../../components/table/types'
import PageContainer from '../../components/page-container/PageContainer'
import TextElement from '../../components/text/Text'
import Spacer from '../../components/spacer/Spacer'
import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import Table from '../../components/table/Table'
import { DatabaseUser } from '../../_types/user'
import { convertRoleToTitle } from '../../_utilities/utils'
import Loading from '../../components/loading/Loading'

/**
 * Users page
 *
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <Users />
 * ```
 */
const Users = (): JSX.Element => {
  const navigation = useNavigate()
  const { showNotification } = useContext(NotificationContext)
  const [criticalError, setCriticalError] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)
  const [users, setUsers] = useState<DatabaseUser[]>([])

  const handlePageReady = useCallback(() => {
    setIsApiBusy(true)

    doFunctionsCall('Admin', {
      signature: 'User-GetAll',
    })
      .then(data => {
        if (data.code === 200) {
          const fetchedUsers: DatabaseUser[] = JSON.parse(data.data)

          setUsers(() => [...fetchedUsers])
        } else if (data.code === 500) {
          console.error(data)
          setCriticalError('Failed to fetch users')

          showNotification({
            title: 'Failed to fetch users!',
            type: 'error',
            dismissAfter: 3000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        setCriticalError('Failed to fetch users')

        showNotification({
          title: 'Failed to fetch users!',
          type: 'error',
          dismissAfter: 3000,
        })
      })
      .finally(() => {
        setIsApiBusy(false)
      })
  }, [showNotification])

  const handleViewUser = useCallback(
    (userId: string) => {
      navigation(`/user/${userId}`)
    },
    [navigation],
  )

  const handleAddNewUser = useCallback(() => {
    navigation('/user/new')
  }, [navigation])

  const tableHeaders = useMemo<TableData[]>(
    () => [
      { content: 'Name' },
      { content: 'Email' },
      { content: 'Role' },
      { content: 'Actions' },
    ],
    [],
  )

  const tableContent = useMemo<TableData[][]>(() => {
    const data: TableData[][] = []

    users.forEach(user => {
      const row: TableData[] = [
        { content: user.displayName },
        { content: user.email },
        { content: convertRoleToTitle(user.role) },
        {
          content: (
            <Button
              callback={() => handleViewUser(user.id)}
              text="View User"
              theme="secondary"
              display="inline-block"
              size="small"
            />
          ),
        },
      ]

      data.push(row)
    })

    return data
  }, [handleViewUser, users])

  return (
    <PageContainer
      height="initial"
      width="initial"
      offsetBottom="10px"
      offsetLeft="10px"
      offsetRight="10px"
      offsetTop="10px"
      offsetMode="padding"
      allowedRoles={['admin', 'super-admin']}
      allowUnauthenticated={false}
      allowNotifications={true}
      showSidebar={true}
      pageError={criticalError}
      onPageReady={() => handlePageReady()}>
      <TextElement text="Users" theme="h1" alignment="center" display="block" />
      <Spacer direction="vertical" amount="10px" display="block" />
      {isApiBusy ? (
        <Loading type="large" />
      ) : (
        <>
          <AlignmentContainer align="center" display="block">
            <Button
              text="Add New User"
              theme="main"
              callback={() => handleAddNewUser()}
            />
          </AlignmentContainer>
          <Spacer direction="vertical" amount="20px" display="block" />
          <Table
            alignment="center"
            headerDirection="horizontal"
            size="large"
            width="800px"
            headers={tableHeaders}
            content={tableContent}
          />
        </>
      )}
    </PageContainer>
  )
}

export default Users
