import React, { useCallback, useContext, useEffect, useState } from 'react'
import XDate from 'xdate'

import AlignmentContainer from '../../../../components/alignment-container/AlignmentContainer'
import Button from '../../../../components/button/Button'
import Spacer from '../../../../components/spacer/Spacer'
import TextElement from '../../../../components/text/Text'
import { doFunctionsCall } from '../../../../_globals/custom-firebase/custom-firebase'
import { useAppSelector } from '../../../../_globals/hooks'
import { NotificationContext } from '../../../../_globals/notifications/notification-context'
import { RootState } from '../../../../_globals/state-store'
import { DatabaseAuditLog, DisplayAuditLog } from '../../../../_types/audit-log'
import { Section } from '../../styled'
import { LogContainer, LogEntry, LogTimestamp, LogTriggeredBy } from './styled'
import { CaseAuditLogProps } from './types'

const userAppSelector = (state: RootState) => state.user

/**
 * The CaseAuditLog component of the Case page.
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <CaseAuditLog
 *  caseData={caseData}
 * />
 * ```
 */
const CaseAuditLog = ({ caseData }: CaseAuditLogProps): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const userSelector = useAppSelector(userAppSelector)
  const [hasFetched, setHasFetched] = useState<boolean>(false)
  const [log, setLog] = useState<DisplayAuditLog[]>([])

  const fetchData = useCallback(() => {
    doFunctionsCall('Admin', {
      signature: 'AuditLog-GetLogsForObject',
      objectId: caseData.id,
    })
      .then(data => {
        if (data.code === 200) {
          const parsedData: DatabaseAuditLog[] = JSON.parse(data.data)

          const formattedData: DisplayAuditLog[] = parsedData
            .map((logData: DatabaseAuditLog) => ({
              id: logData.id,
              triggeredBy: logData.triggeredBy,
              triggeredByDisplayName: logData.triggeredByDisplayName,
              triggeredOnTimestamp: logData.triggeredOnTimestamp,
              triggeredOnFormattedTimestamp: new XDate(
                logData.triggeredOnTimestamp,
              ).toString("dddd MMMM dS, yyyy, 'at' h:mm tt"),
              isVisible: logData.isVisible,
              difference: logData.formattedDifference,
            }))
            .filter((logData: DisplayAuditLog) =>
              userSelector.role === 'super-admin' ||
              userSelector.role === 'admin'
                ? logData
                : logData.isVisible === true,
            )
            .sort((a: DisplayAuditLog, b: DisplayAuditLog) => {
              if (a.triggeredOnTimestamp < b.triggeredOnTimestamp) return 1
              if (a.triggeredOnTimestamp > b.triggeredOnTimestamp) return -1
              return 0
            })

          setLog(() => [...formattedData])
          setHasFetched(true)
        } else if (data.code === 500) {
          console.error(data)
          showNotification({
            title: 'Failed to fetch audit log',
            type: 'error',
            dismissAfter: 3000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        showNotification({
          title: 'Failed to fetch audit log',
          type: 'error',
          dismissAfter: 3000,
        })
      })
  }, [caseData.id, showNotification, userSelector.role])

  const handleRefreshClick = useCallback(() => {
    fetchData()

    showNotification({
      title: 'Activity Log Refreshed',
      type: 'success',
      dismissAfter: 1000,
    })
  }, [fetchData, showNotification])

  useEffect(() => {
    if (hasFetched === false) {
      fetchData()
    }
  }, [caseData.id, fetchData, hasFetched, showNotification, userSelector.role])

  return (
    <Section>
      <TextElement
        text="Activity Log"
        theme="h2"
        alignment="center"
        display="block"
      />
      <AlignmentContainer align="center" display="block">
        <Spacer direction="vertical" amount="10px" display="block" />
        <Button
          text="Refresh Log"
          callback={() => handleRefreshClick()}
          theme="secondary"
        />
        <Spacer direction="vertical" amount="30px" display="block" />
      </AlignmentContainer>
      <LogContainer>
        {log.map((logData: DisplayAuditLog) => (
          <LogEntry key={logData.id}>
            <LogTimestamp>{logData.triggeredOnFormattedTimestamp}</LogTimestamp>
            <TextElement
              text={logData.difference}
              theme="paragraph"
              display="block"
            />
            <LogTriggeredBy>{`Change made by ${logData.triggeredByDisplayName}`}</LogTriggeredBy>
          </LogEntry>
        ))}
      </LogContainer>
    </Section>
  )
}

export default CaseAuditLog
