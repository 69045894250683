/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { deepCopy } from '../../_utilities/utils'
import { setLocalStorageItem } from '../../_data/storage'
import { viewportBreakpoints } from '../theme'
import { ThemeState } from './types'

const initialState: ThemeState = {
  allowNotifications: true,
  showSidebar: true,
  isNavSticky: true,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  isDesktopView: false,
  isLaptopView: false,
  isPhoneView: false,
  isTabletView: false,
  isTvView: false,
  isLocationModalOpen: false,
}

/**
 * Save theme data locally
 *
 * @param {ThemeState} state - The current state
 * @returns {void}
 *
 * ```ts
 * saveThemeLocally(state)
 * ```
 */
const saveThemeLocally = (state: ThemeState) => {
  const localCopy = deepCopy(state)

  setLocalStorageItem('theme', JSON.stringify(localCopy))
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    getTheme: state => deepCopy(state),
    setShowSidebar: (state, action: PayloadAction<boolean>) => {
      state.showSidebar = action.payload
      saveThemeLocally(state)
    },
    setWindowWidth: (state, action: PayloadAction<number>) => {
      const width: number = action.payload

      state.isDesktopView = false
      state.isLaptopView = false
      state.isPhoneView = false
      state.isTabletView = false
      state.isTvView = false

      if (
        width >= viewportBreakpoints.phone[0] &&
        width <= viewportBreakpoints.phone[1]
      ) {
        state.isPhoneView = true
      } else if (
        width >= viewportBreakpoints.tablet[0] &&
        width <= viewportBreakpoints.tablet[1]
      ) {
        state.isTabletView = true
      } else if (
        width >= viewportBreakpoints.laptop[0] &&
        width <= viewportBreakpoints.laptop[1]
      ) {
        state.isLaptopView = true
      } else if (
        width >= viewportBreakpoints.desktop[0] &&
        width <= viewportBreakpoints.desktop[1]
      ) {
        state.isDesktopView = true
      } else if (
        width >= viewportBreakpoints.tv[0] &&
        width <= viewportBreakpoints.tv[1]
      ) {
        state.isTvView = true
      }

      state.windowWidth = width
    },
    setWindowHeight: (state, action: PayloadAction<number>) => {
      state.windowHeight = action.payload
    },
    setAllowNotifications: (state, action: PayloadAction<boolean>) => {
      state.allowNotifications = action.payload
      saveThemeLocally(state)
    },
    reinitializeTheme: (state, action: PayloadAction<ThemeState>) => {
      const sanitizedTheme: ThemeState = { ...initialState, ...action.payload }

      state = { ...sanitizedTheme }
      saveThemeLocally(sanitizedTheme)
    },
    resetTheme: state => {
      state = { ...initialState }
      saveThemeLocally(initialState)
    },
  },
})

export { themeSlice }

export default themeSlice.reducer
