import { DatabaseCase, DisplayCase } from '../_types/case'
import { defaultCameraPosition } from './model'

const defaultDatabaseCase: DatabaseCase = {
  anatomiz3dEngineer: '',
  anatomiz3dId: '',
  archivedAtTimestamp: Date.now(),
  archivedBy: '',
  archiveReason: '',
  clientId: '',
  commentIds: [],
  displayCaseDetails: '',
  forPatient: '',
  friendlyId: '',
  id: '',
  institutionId: '',
  internalCaseDetails: '',
  isArchived: false,
  modelFiles: [],
  shareableLink: '',
  shareableLinkCreatedBy: '',
  shareableLinkCreateTimestamp: Date.now(),
  status: 'open',
  title: '',
  sceneCameraPosition: { ...defaultCameraPosition },
  isGridVisible: true,
  createdBy: '',
  createdAtTimestamp: Date.now(),
}

const defaultDisplayCase: DisplayCase = {
  anatomiz3dId: '',
  clientId: '',
  commentIds: [],
  displayCaseDetails: '',
  forPatient: '',
  friendlyId: '',
  id: '',
  institutionId: '',
  modelFiles: [],
  shareableLink: '',
  status: 'open',
  title: '',
  sceneCameraPosition: { ...defaultCameraPosition },
  isGridVisible: true,
  createdBy: '',
  anatomiz3dEngineerId: '',
  anatomiz3dEngineerName: '',
  clientName: '',
  createdAtTimestamp: Date.now(),
  isArchived: false,
}

export { defaultDatabaseCase, defaultDisplayCase }
