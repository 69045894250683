/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable valid-jsdoc */
/* eslint-disable no-underscore-dangle */
import { initializeApp, getApps, getApp } from 'firebase/app'
import {
  getFunctions,
  Functions,
  connectFunctionsEmulator,
  httpsCallable,
  HttpsCallableResult,
} from 'firebase/functions'
import { getAuth, Auth } from 'firebase/auth'

import { log } from '../../_utilities/logging'
import { getEnvironmentSettings } from '../../_utilities/config'
import { ApiResponse, ApiData } from '../../_types/api'
import { doesExist, deepCopy } from '../../_utilities/utils'
import { EndpointPriority } from './types'

let loggedFunctions = false
let loggedAuth = false

interface CustomFirebase {
  functions: Functions
  auth: Auth
}

/**
 * Console log when a firebase service is loaded
 *
 * @param {string} service - The service that was loaded
 * @param {string} environment - The environment the service was loaded in
 * @returns {void}
 *
 * ```ts
 * logLoadedServices('functions', 'prod')
 * ```
 */
const logLoadedServices = (service: string, environment: string): void => {
  if (!loggedFunctions && service === 'functions') {
    log(
      'regional-firebase-functions.ts',
      'logLoadedServices',
      'info',
      `Loaded ${environment} firebase functions`,
    )
    loggedFunctions = true
  }
  if (!loggedAuth && service === 'auth') {
    log(
      'regional-firebase-functions.ts',
      'logLoadedServices',
      'info',
      `Loaded ${environment} firebase authentication`,
    )
    loggedAuth = true
  }
}

/**
 * Set the globally-available instance of Firebase
 *
 * @param {CustomFirebase} newFirebase - The firebase instance
 * @returns {void}
 *
 * ```ts
 * setFirebase(firebaseInstance)
 * ```
 */
const setFirebase = (newFirebase: CustomFirebase): void => {
  // eslint-disable-next-line @typescript-eslint/no-extra-semi
  ;(global as any).firebase = { ...newFirebase }
}

/**
 * Get the globally-available firebase instance
 *
 * @returns {CustomFirebase}
 *
 * ```ts
 * const firebaseInstance = getFirebase()
 * ```
 */
const getFirebase = (): CustomFirebase => (global as any).firebase ?? {}

/**
 * Create a custom version of firebase, used during development
 *
 * @returns {boolean}
 *
 * ```ts
 * initializeCustomFirebase().then((wasSuccessful) => {
 *  if(wasSuccessful){
 *    // getFirebaseFunctions() and getFirebaseAuth() now available
 *  }
 * })
 * ```
 */
const initializeCustomFirebase = (): boolean => {
  const websiteEnvironment = getEnvironmentSettings()

  const app = initializeApp(websiteEnvironment.config, 'custom')
  const firebaseFunctions = getFunctions(
    app,
    websiteEnvironment.FUNCTIONS_REGION,
  )
  const firebaseAuth = getAuth(app)

  setFirebase({ functions: firebaseFunctions, auth: firebaseAuth })

  return true
}

/**
 * Get the Firebase Functions instance
 *
 * @returns {Functions}
 *
 * ```ts
 * getFirebaseFunctions().then((firebaseFunctions) => {})
 * ```
 */
const getFirebaseFunctions = (): Functions => {
  const environmentSettings = getEnvironmentSettings()
  const firebaseApp = getApp()

  const customFirebaseFunctions = getFunctions(
    firebaseApp,
    environmentSettings.FUNCTIONS_REGION,
  )

  if (environmentSettings.ENV_CODE === 'local') {
    connectFunctionsEmulator(customFirebaseFunctions, 'localhost', 5001)
  }

  return customFirebaseFunctions
}
// const getFirebaseFunctions = (): Functions => {
//   const config = getEnvironmentSettings()
//   const appNames: string[] = getApps().map(app => app.name)

//   if (!appNames.includes('custom')) {
//     initializeCustomFirebase()
//     console.log('HIT1', appNames)
//     window.location.reload()
//     return null
//   }

//   if (config.ENV_CODE === 'prod') {
//     logLoadedServices('functions', 'production')
//     return getFunctions(getApp('custom'), config.FUNCTIONS_REGION)
//   }

//   logLoadedServices('functions', 'development')
//   return getFunctions(getApp('custom'), config.FUNCTIONS_REGION)
// }

/**
 * Get the Firebase Authentication instance
 *
 * @returns {Auth}
 *
 * ```ts
 * getFirebaseAuth().then((firebaseAuth) => {})
 * ```
 */
const getFirebaseAuth = (): Auth => {
  const config = getEnvironmentSettings()
  const appNames: string[] = getApps().map(app => app.name)

  if (!appNames.includes('custom')) {
    initializeCustomFirebase()
    window.location.reload()

    return null
  }

  if (config.ENV_CODE === 'prod') {
    logLoadedServices('authentication', 'production')
    return getAuth(getApp('custom'))
  }

  logLoadedServices('authentication', 'development')
  return getAuth(getApp('custom'))
}

/**
 * Preform a Firebase Functions call
 *
 * @param {EndpointPriority} category - Call category
 * @param {ApiData} apiData - The data to send
 * @returns {Promise<ApiResponse>}
 *
 * ```ts
 * doFunctionsCall('HighestPriority', {
 *  signature: 'Product-Get',
 *  productId: product.id,
 * }).then(apiResponse)
 * ```
 */
const doFunctionsCall = (
  category: EndpointPriority,
  apiData: ApiData,
): Promise<ApiResponse> =>
  new Promise((resolve, _) => {
    const firebaseFunctions = getFirebaseFunctions()
    const routeDuringCall = deepCopy(window.location.pathname)
    const baseEndpoint = `${category}-${category}`

    httpsCallable(
      firebaseFunctions,
      baseEndpoint,
    )(apiData)
      .then((apiResponse: HttpsCallableResult<unknown>) => {
        if (!doesExist(apiResponse) || !doesExist(apiResponse.data)) {
          return resolve({
            code: 500,
            message: 'Malformed data',
            data: JSON.stringify(apiResponse),
          })
        }

        const data = apiResponse.data as ApiResponse

        if (routeDuringCall !== window.location.pathname) {
          return resolve({
            code: 501,
            message: 'Route change',
            data: data.data,
          })
        }

        return resolve(data)
      })
      .catch((error: Error) => {
        console.error(error)
        return resolve({
          code: 500,
          message: error.message,
        })
      })
  })

export {
  getFirebaseFunctions,
  getFirebaseAuth,
  initializeCustomFirebase,
  getFirebase,
  doFunctionsCall,
}
